@use "sass:math";

$heading-base-size: 1.6rem;
$text-base-size: 1.4rem;

$heading-scale-ratio: 1.12;
$text-scale-ratio: 1.1;

/* for use with heading typography - e.g. h1, h2 etc */
$heading-xs: math.div(math.div($heading-base-size, $heading-scale-ratio), $heading-scale-ratio);
$heading-sm: $heading-xs * $heading-scale-ratio;
$heading-md: $heading-sm * $heading-scale-ratio * $heading-scale-ratio;
$heading-lg: $heading-md * $heading-scale-ratio;
$heading-xl: $heading-lg * $heading-scale-ratio;

/* for use with non heading typography - e.g. p, ul, table etc */
$text-xs: math.div(math.div($text-base-size, $text-scale-ratio), $text-scale-ratio);
$text-sm: $text-xs * $text-scale-ratio;
$text-md: $text-sm * $text-scale-ratio * $text-scale-ratio;
$text-lg: $text-md * $text-scale-ratio;
$text-xl: $text-lg * $text-scale-ratio;

/* font-weights */
$light-font-weight: 100;
$normal-font-weight: 400;
$heavy-font-weight: 600;
$xheavy-font-weight: 700;

$fa-icon-weight: 700;

/* line-heights */
$default-line-height: 1.4;
