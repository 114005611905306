/*
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(../fonts/OpenSans/OpenSans.woff2) format('woff2');
}
*/

// @import url(https://fonts.googleapis.com/css?family=Open+Sans);
$font-Gotham: 'Gotham', sans-serif;

@mixin giacom-font($font-weight: 400, $font-style: normal) {
    font-weight: $font-weight;
    font-style: $font-style;
}

@mixin Gotham-300 {
    @include giacom-font(300, normal);
}

@mixin Gotham-400 {
    @include giacom-font(400, normal);
}

@mixin Gotham-600 {
    @include giacom-font(600, normal);
}

@mixin Gotham-700 {
    @include giacom-font(700, normal);
}

@mixin Gotham-Bold {
    font-weight: 600;
    font-style: normal;
}

@mixin Gotham {
    font-weight: 400;
    font-style: normal;
}
