.giacom-setting-hosted-exchange {
    .general {
        background-color: $giacom-blue3;
        margin-top: 20px;

        .panel-heading {
            width: 737px;
            display: inline-flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            border-bottom: 1px solid $giacom-grey3;
        }

        .title {
            @include Gotham-600;
            color: $giacom-grey2;
            order: 1;
            margin-right: auto;
        }

        .action {
            -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
            order: 2;
            margin-left: auto;
        }

        .form-body {
            margin-top: 20px;

            .row {
                padding-top: 10px;
            }

            .text {
                text-align: right;
            }
        }

        .distribution {
            margin-bottom: 30px;
        }
    }
}

.btn.giacom-btn.giacom-btn-primary.chevron {
    padding-top: 0;

    span.fa.fa-chevron-right.chevron {
        font-size: 42px;
    }

    span.fa.fa-chevron-left.chevron {
        font-size: 42px;
    }

}
