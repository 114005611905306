.form-control.giacom-input {
    color: $giacom-grey2;
    border-radius: 0;
    border: 1px solid $giacom-grey3;
    height: 38px;
    @include Gotham-400;
    font-size: 16px;
    transition: border 0.3s ease-in-out;

    @supports (-ms-ime-align: auto) {
        .form-control.giacom-input,
        input[type='text']::-ms-clear {
            display: none;
        }
    }

    &::placeholder {
        color: $giacom-grey5;
    }

    &:focus {
        outline: none;
        border: 1px solid $giacom-grey5;
        box-shadow: none;
    }

    &.errorInput {
        border: 1px solid $giacom-pink1;
    }
}

//input[type='text'],
//input[type='password'],
//input[type='number'] {
//    padding-left: 10px;
//    padding-right: 10px;
//    width: 100%;
//}

label.giacom-label {
    text-align: right;
    font-size: 16px;
    @include Gotham-600;
    color: $giacom-grey2;
}

@media screen and (max-width: 767px) {
    label,
    .add-ons-message {
        &.giacom-label {
            text-align: left;
        }
    }
}

.rep-sel-popover .popover-content {
    min-width: unset !important;
}

.white-placeholder::placeholder {
    color: #fff !important;
}
