.giacom-dropdown-header {
    ul.dropdown-menu {
        font-size: 16px;
        @include Gotham-400;
        border-radius: 0;
        border-left: 1px solid $giacom-grey2;
        border-right: 1px solid $giacom-grey2;
        border-bottom: 1px solid $giacom-grey2;
        padding: 0;

        li {
            padding: 10px 15px 10px 10px;

            &:hover {
                background-color: $giacom-grey4;
            }

            a {
                // color: $giacom-blue1;
                padding: 0;

                &:hover {
                    background-color: $giacom-grey4;
                }
            }
        }
    }

    button {
        background-color: $giacom-grey2;

        span.fas {
            float: right;
            font-size: 16px;
            margin-top: 3px;
        }
    }

    &.giacom-dropdown-white {
        button {
            background-color: $giacom-white;
            border: 1px solid $giacom-border-input;
            text-align: left;

            span {
                color: $giacom-grey2;
            }

            &.giacom-btn.btn:focus {
                border-color: $giacom-grey5;
            }
        }

        .dropdown-menu {
            width: 100%;
            max-height: 250px;
            overflow-y: auto;
            border: 1px solid $giacom-border-input;
            margin-top: 0;
            min-width: initial;

            li {
                &:hover {
                    background-color: $giacom-grey4;
                }

                a {
                    word-break: break-all;
                    color: $giacom-grey2;
                    @include Gotham-400;
                }
            }
        }
    }
}

.giacom-dropdown-grid {
    float: right;

    ul.dropdown-menu {
        margin-top: 0;
        border-radius: 0;
        // border-left: 1px solid $giacom-blue1;
        // border-right: 1px solid $giacom-blue1;
        // border-bottom: 1px solid $giacom-blue1;
        z-index: 80;
        padding: 0;
        min-width: initial;

        li {
            min-height: 40px;
            padding: 10px 15px 10px 10px;

            &:hover {
                background-color: $giacom-grey4;

                a:hover {
                    background-color: $giacom-grey4;
                }
            }
        }

        a {
            padding: 0;
            @include Gotham-300;
            font-size: 16px;
            word-break: break-word;
            white-space: pre-line;
        }
    }

    button {
        min-width: 250px;
        // background-color: $giacom-blue1;
        text-align: left;

        span.fas {
            font-size: 16px;
        }
    }
}

.giacom-dropdown {
    float: left;
    width: 100%;
    max-width: 300px;

    ul.dropdown-menu {
        margin-top: 0;
        border-radius: 0;
        border: 1px solid $giacom-grey4;
        border-top: none;
        z-index: 80;
        padding: 0;
        margin-bottom: 15px;

        li {
            height: 40px;
            padding: 10px 15px 10px 10px;

            &:hover {
                background-color: $giacom-grey4;

                a:hover {
                    background-color: $giacom-grey4;
                }
            }
        }

        a {
            padding: 0;
            @include Gotham-400;
            font-size: 16px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    button {
        min-width: 160px;
        text-align: left;

        span.fas {
            font-size: 16px;
        }
    }

    @supports (-ms-ime-align:auto) {
        .dropdown-toggle {
            background-color: rgb(228, 228, 228);
            width: 100%;
            max-width: 300px;
        }
    }

    .dropdown-toggle,
    .dropdown-menu {

        width: 100%;
        max-width: 300px;
    }
}

@media screen and (min-width: 768px) {
    .giacom-dropdown-grid {
        ul.dropdown-menu {
            min-width: 250px;
        }
    }
}

.dropdown-menu li a {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.dropdown-toggle {
    .selected-name, .name {
        display: inline-block;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

    }

    .selected-name {
        vertical-align: middle;
    }
}

@media screen and (min-width: 768px) {
    .giacom-dropdown-grid {
        ul.dropdown-menu {
            max-width: 250px;
        }
    }
    .giacom-dropdown {
        float: left;
        width: 100%;
        max-width: 200px;
    }
}

@media screen and (max-width: 443px) {
    .giacom-dropdown-grid {
        ul.dropdown-menu {
            min-width: 100px;
            font-size: 12px;
        }
    }
    .giacom-dropdown {
        float: left;
        width: 100%;
        max-width: 100px;
        padding-right: 0;
        font-size: 12px !important;
    }
    .dropdown-menu li a {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 12px;
    }

}

