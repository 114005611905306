@media screen and (max-width: 767px) {
    .button-group-customized {
        display: flex;
        flex-wrap: wrap;

        button {
            flex: 1 0 100%;

            &.giacom-btn-primary {
                order: 1;
                margin-bottom: 10px;
            }

            &.giacom-btn-secondary {
                order: 2;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .giacom-form {
    }
}

.giacom-form {
    @include Gotham-400;
    margin-bottom: 10px;

    .form-group {
        margin-bottom: 25px;

        textarea {
            height: 100px;
            resize: none;
        }
    }

    label {
        font-size: 16px;
        @include Gotham-400;
        color: $giacom-grey2;
    }

    .checkbox {
        margin-bottom: 30px;
    }

    .error {
        font-size: 16px;
        @include Gotham-400;
        color: $giacom-pink1;
    }

    input[type="file"] {
        font-size: 16px;
        margin-bottom: 30px;
        width: 100%;
        border: 1px solid $giacom-grey3;
    }

    .input-group.upload-file {
        width: 78%;
        margin-bottom: 30px;
    }

    .csv-error-messsage {
        font-size: 16px;
        margin-top: 30px;
        @include Gotham-400;
        border-top: 3px solid $giacom-blue2;

        ul {
            height: 150px;
            overflow: auto;
        }
    }

    .giacom-error-message {
        border-radius: 0px;
        font-size: 16px;
        @include Gotham-400;
        background-color: transparent;
        border-color: $giacom-pink2;
        color: $giacom-pink2;
        margin-bottom: 5px;
        display: flex;

        .fas {
            font-size: 18px;
            color: $giacom-pink1;
        }

        .error-title {
            font-size: 18px;
            @include Gotham-600;
        }
    }

    .button-group,
    .button-group-customized {
        margin-left: 0px;
        margin-right: 0px;

        button {
            margin-right: 6px;

            &.giacom-btn-primary {
                &:focus {
                    background-color: $giacom-pink2;
                }
            }

            &.giacom-btn-no-background {
                &:focus {
                    background-color: $giacom-grey4;
                }
            }

            &:last-child {
                margin-right: 0px;
            }

            &.giacom-btn-sign-now {
                float: left;
            }

            &.giacom-btn-countinue {
                float: left;
                margin-left: 4px;
            }
        }
    }

    .dropdown {
    }

    .giacom-hint {
        border-radius: 100%;
        width: 21px;
        height: 21px;
        color: $giacom-grey5;
        cursor: pointer;

        .fas {
            font-size: 21px;
        }
    }
}

.giacom-popup-form {
    label.text-top-pad {
        margin-top: 10px;
    }

    label.text-bold {
        @include Gotham-600;
        color: $giacom-grey2;
        margin-top: 10px;
    }

    .button-group,
    .button-group-customized {
        margin-top: 30px;
        margin-bottom: 45px;

        button {
            &:first-child {
                margin-right: 5px;
            }
        }
    }

    @media screen and (max-width: 767px) {
        .text-right {
            text-align: left;
        }
    }

    &.change-password-successfully {
        p {
            margin-left: 10px;
        }
    }

    .customized-end-user {
        margin-bottom: 10px;
    }
}
