.appinteract {
    .form-content {
        .form-title {
            .step-title {
                margin-bottom: 20px;
            }
        }
    }
    
    .form-body {
        .giacom-form {
            .row {
                .appinteract__message {
                    margin-bottom: 40px;
                }
            }
            .appinteract__admin {
                .appinteract__licence {
                    margin-bottom: 20px;
            
                    .appinteract__licence__key {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}