@import 'assets/css/variables/colors';

.settings-separator {
    border-bottom: 1px solid $gray-border;
}

.status {
    margin-left: 15px;
    color: $secondary;
    font-size: 16px;
}
