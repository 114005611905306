.bullet{
    border-radius: 50%;
    width: 7px;
    height: 7px;
    display: block;
    margin: 20px auto 20px;    

    &-dark{
        background-color: #31323d;
    }        
    &-light{
        background-color: #fff;
    }
}