ul.service-options {
    list-style: none;
    display: inline-flex;
    margin: 0;
    padding: 0;
    margin-bottom: 30px;

    li {
        &.title {
            font-size: 16px;
            @include Gotham-600;
            color: $giacom-grey2;
            width: 235px;
            text-align: right;
            margin-right: 15px;
        }

        &.yes,
        &.no {
            width: 196px;
            height: 132px;
            border: 1px solid $giacom-grey5;
            border-radius: 6px;
            background-color: $giacom-white;
            padding: 8px;
            /*display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;*/
            text-align: center;
            cursor: pointer;

            &:hover {
                background-color: $giacom-grey4;
            }

            > div {
                align-self: center;

                &:first-child {
                    order: 1;
                    height: 24px;
                    flex: 1 2;
                }

                &:last-child {
                    order: 2;
                    flex: 3 3;
                    text-align: center;
                    word-wrap: normal;
                    word-break: break-word;
                }
            }

            .description {
                font-size: 15px;
            }
        }

        &.no {
            margin-right: 20px;
        }

        &.selected {
            border: 5px solid $giacom-grey5;
            background-color: $giacom-grey4;
        }

        &:last-child {
            align-self: center;
        }
    }

    .confirm-title {
        font-size: 18px;
        @include Gotham-600;
        color: $giacom-grey2;
    }
}

@media screen and (min-width: 768px) and (max-width: 798px) {
    ul.service-options {
        li {
            &.title {
                width: initial;
                width: 200px;
                max-width: 237px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    ul.service-options {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 15px;

        li {
            &.title {
                width: 100%;
                flex: 0 1 100%;
                text-align: left;
                padding-bottom: 15px;
            }

            &.yes,
            &.no {
            }
        }
    }
}
