.giacom-configue-quick-links {

    .no-padding-left {
        padding-left: 0;
    }

    .no-padding-right {
        padding-right: 0;
    }

    .giacom-sub-navigation {
        padding: 15px;

        .title {
            font-size: 25px;
            @include Gotham-600;
        }
    }

    .giacom-page {
        padding-bottom: 30px;
    }

    .form-title {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .btn-cancel {
        float: right;
        border: 1px solid;
    }

    .assign-link {
        padding-bottom: 15px;

        &:first-child {
            border-top: 1px solid $giacom-grey3;
            padding-top: 15px;
        }

        &.assign-link, &:last-child {
            border-bottom: 1px solid $giacom-grey3;
        }

        .number-order {
            font-weight: 600;
            float: left;
            margin-right: 10px;
            padding-top: 10px;
            width: 30px;
        }

        .btn {
            float: right;
        }
    }

    @media screen and (max-width: 475px) {
        .giacom-dropdown {
            width: auto;
        }
    }
}
