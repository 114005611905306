.giacom-manage-hosted-exchange-dns {
    margin: 20px 0 40px 0;

    p {
        @include Gotham-400;
        color: $giacom-grey2;
        font-size: 16px;
        margin-bottom: 20px;

        a.link {
            color: $giacom-blue1;

            &:hover,
            &:focus {
                color: $giacom-blue1;
            }
        }
    }

    // .list-two-columns {
    //     li {
    //         display: table-cell;
    //         float: left;
    //         margin-bottom: 10px;
    //         &:nth-child(odd) {
    //             clear: left;
    //             width: 30%;
    //             margin-left: 15px;
    //         }
    //     }
    // }
    // .list-more-than-two-columns {
    //     li {
    //         display: table-cell;
    //         float: left;
    //         &:nth-child(1) {
    //             width: 30%;
    //             margin-left: 15px;
    //         }
    //         &:nth-child(3),
    //         &:nth-child(4) {
    //             margin-left: 30px;
    //         }
    //     }
    // }
    // ul {
    //     list-style: none;
    //     padding: 0;
    //     margin: 0;
    //     &.mx-records,
    //     &.spf-record {
    //         @extend .list-two-columns;
    //     }
    //     &.autodiscover-record,
    //     &.owa-whitelabel {
    //         @extend .list-more-than-two-columns;
    //     }
    // }
    // .mx-records-block {
    //     height: 185px;
    // }
    // .autodiscover-record-block,
    // .owa-whitelabel-block,
    // .spf-record-block {
    //     height: 90px;
    // }
    ul {
        list-style: none;
        width: 100%;

        li {
            margin-bottom: 10px;
        }

        &.mx-records {
            display: flex;
            flex-wrap: wrap;

            li {
                word-break: break-all;
                white-space: nowrap;

                &:nth-child(odd) {
                    flex: 1 0 50%;
                }

                &:nth-child(even) {
                    flex: 1 0 50%;
                }
            }
        }
    }

    .title {
        @include Gotham-600;
        font-size: 18px;
        color: $giacom-grey2;
        padding-bottom: 15px;
        border-bottom: 1px solid $giacom-grey3;
        margin: 30px 0 25px 0;
    }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
}

@media screen and (min-width: 1024px) {
    ul {
        &.autodiscover-record,
        &.owa-whitelabel {
            display: inline-flex;

            li {
                &:nth-child(1) {
                    width: 30%;
                }

                &:nth-child(2) {
                    width: 10%;
                }

                &:nth-child(3) {
                    width: 30%;
                }

                &:nth-child(4) {
                    width: 30%;
                }
            }
        }

        &.owa-whitelabel-customize {
            display: inline-flex;

            li {
                &:nth-child(1) {
                    width: 20%;
                }

                &:nth-child(2) {
                    width: 30%;
                }

                &:nth-child(3) {
                    width: 50%;
                }
            }
        }

        &.spf-record {
            display: inline-flex;

            li {
                &:nth-child(1) {
                    width: 25%;
                }

                &:nth-child(2) {
                    width: 75%
                }
            }
        }
    }
}
