@media screen and (min-width: 768px) and (max-width: 1023px) {
    .giacom-login {
        .reset-password {
            margin-top: 30px;

            ul {
                padding-left: 0;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .giacom-login {
        ul {
            padding-left: 0;
        }

        .giacom-btn {
            margin-bottom: 30px;
        }
    }
}

.giacom-login-expired {
    position: fixed;
    width: 100%;
    //height:40px;
    left: 0;
    top: 0;
    margin: 0;
    border: 0;
    padding: 10px;
    background-color: $giacom-yellow2;
    color: $giacom-black;

    div {
        float: right;
        color: $giacom-pink1;
        font-weight: 600;
        cursor: pointer;

        &:hover {
            color: $giacom-pink2;
        }
    }
}

.giacom-login {
    &.giacom-page {
        background-color: $giacom-blue1;
    }

    .container-fluid {
        display: flex;
        justify-content: center;
        align-content: center;
    }

    .panel {
        width: 100%;
        max-width: 600px;
        align-self: center;
        margin-bottom: 0;
        max-height: 100%;
        overflow-x: auto;
    }

    .panel-body {
        .row {
            margin-bottom: 0;
        }
    }

    #isHumanArea {
        padding: 10px;
        background-color: #f2f2f2;
    }

    .reset-password {
        font-size: 16px;

        .title {
            @include Gotham-600;
            font-size: inherit;
            margin: 0;
            padding-top: 10px;
        }

        @include Gotham-400;

        ul {
            list-style-type: none;
            line-height: 2;

            li {
                &:first-child {
                    border-top: 4px solid $giacom-grey4;
                    width: 100%;
                }

                > a {
                    color: $giacom-blue1;
                }
            }
        }
    }

    .reset-password-link {
        float: right;
        margin-top: 8px;
    }
}

@media screen and (max-width: $giacom-small) {
    .giacom-btn-primary {
        width: initial;
    }
    .giacom-login-expired {
        div {
            padding: 5px;
        }
    }
}

@media screen and (max-width: 443px) {
    .panel {
        padding-left: 20px;
        padding-right: 20px;
    }


}

.switch-user-popup {
    .modal-sm {
        width: 320px;
    }

    .control-panel {
        display: inline-flex;
        margin-bottom: 15px;

        .name {
            margin-left: 10px;
        }
    }
}
