.hosted-exchange {
    .select-product {
        .add-ons-message {
            .col-md-12 {
                //display: inline-flex;
            }

            .hint {
                position: absolute;
                right: 10px;
                margin-left: 10px;
            }
        }

        .add-ons {
            .add-ons-name {
                justify-content: initial;
                align-items: initial;
                align-content: initial;

                .giacom-checkbox,
                .giacom-radio {
                    order: 1;
                }

                .name {
                    //width: auto;
                    order: 2;
                }

                .dropdown {
                    margin-left: 15px;
                    order: 3;
                }

                .reason {
                    order: 4;
                    width: auto;
                }

                &.upgrade-recommended-from {
                    display: flex;
                    flex-wrap: wrap;
                    width: 60%;

                    .giacom-text-primary {
                        order: 3;
                        width: 100%;
                        margin-top: 10px;
                        margin-left: 35px;
                    }
                }
            }

            .amount-period {
                margin-right: 0;

                &.upgrade-recommended-from {
                    align-self: flex-start;
                    width: 40%;
                }
            }

            .item button {
                width: 105px;
                text-align: left;
            }
        }

        .giacom-radio,
        .giacom-checkbox {
            margin-right: 15px;
        }

        .giacom-form {
            .reason {
                @include Gotham-400;
            }
        }

        .mailbox-type {
            .popover.right {
                left: 143px !important;
            }
        }

        .recommended {
            .popover.right {
                left: 225px !important;
            }
        }

        .has-dropdown {
            .giacom-checkbox {
                margin-top: 10px;
            }
        }

        .giacom-dropdown-header {
            button {
                background-color: $giacom-white;
                color: $giacom-grey2;
                border: 1px solid $giacom-border-input;
                @include Gotham-400;

                span.fas {
                    margin-top: 4px;
                    margin-left: 5px;
                }
            }

            ul.dropdown-menu {
                border: 1px solid $giacom-border-input;
                margin-top: 0;
                min-width: initial;
                width: 105px;

                li a {
                    color: $giacom-grey2;
                    @include Gotham-400;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .hosted-exchange {
        .select-product {
            .item {
                flex-wrap: initial;

                &.dropdown {
                    .amount-period {
                        width: 40%;
                    }
                }
            }

            .add-ons .add-ons-name {
                .giacom-radio,
                .giacom-checkbox {
                    width: 20px;
                }
            }

            .giacom-radio,
            .giacom-checkbox {
                margin-right: 10px;
            }

            .add-ons .add-ons-name.has-dropdown {
                .name {
                    width: 120px;
                }
            }
        }
    }
}
