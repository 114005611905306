@import "../../../../../assets/css/variables/colors";

$cm-color-variant-darker: 90;
$cm-color-variant-regular: 100;
$cm-color-variant-lighter: 110;
$cm-color-variant-lightest: 125;
$cm-color-variant-near-white: 150;
$cm-color-variant-variable-alpha: vAlpha;
$cm-color-variant-variable-text: vText;

$cm-white-label-color-primary-keys: (
  $cm-color-variant-variable-alpha: color-1-1-alpha0_1,
  $cm-color-variant-variable-text: color-1-t,
  $cm-color-variant-darker: color-1-2,
  $cm-color-variant-regular: color-1-1,
  $cm-color-variant-lighter: color-5-1,
  $cm-color-variant-lightest: color-5-2,
  $cm-color-variant-near-white: color-1-3
);
$cm-white-label-color-secondary-keys: (
  $cm-color-variant-variable-text: color-2-t,
  $cm-color-variant-darker: color-2-2,
  $cm-color-variant-regular: color-2-1,
  $cm-color-variant-lighter: color-6-1,
  $cm-color-variant-lightest: color-6-2
);
// theme colors
$cm-color-primary: (
  $cm-color-variant-variable-text: var(--white),
  $cm-color-variant-variable-alpha: #00B8C500,
  $cm-color-variant-darker: var(--primary-darker),
  $cm-color-variant-regular: var(--primary),
  $cm-color-variant-lighter: var(--primary-lighter),
  $cm-color-variant-lightest: var(--primary-lighter),
  $cm-color-variant-near-white: #E6F8F9
);
$cm-color-secondary: (
  $cm-color-variant-variable-text: #FFFFFF,
  $cm-color-variant-darker: #C71253,
  $cm-color-variant-regular: $secondary-pink,
  $cm-color-variant-lighter: #EF4481,
  $cm-color-variant-lightest: #F05089,
  $cm-color-variant-near-white: #FFFFFF
);
// additional colors
$cm-color-white: (
  $cm-color-variant-regular: #FFFFFF,
);
$cm-color-black: (
  $cm-color-variant-regular: #000000,
);
$cm-color-grey: (
  $cm-color-variant-darker: #1B1B22,
  $cm-color-variant-regular: #32323E,
  $cm-color-variant-lighter: #49495A,
  $cm-color-variant-lightest: #6B6B85,
  $cm-color-variant-near-white: #B0B0BF,
  160: #CCCCD6,
  170: #E8E8ED
);
$cm-color-green: (
  $cm-color-variant-darker: #008406,
  $cm-color-variant-regular: #00B709,
);
$cm-color-orange: (
  70: #664D00,
  $cm-color-variant-darker: #CC9A00,
  $cm-color-variant-regular: #FFC000,
);
$cm-color-red: (
  $cm-color-variant-darker: #AC0505,
  $cm-color-variant-regular: #DE0606,
);

$cm-white-label-default-colors: (
  map-get($cm-white-label-color-primary-keys, $cm-color-variant-variable-alpha): map-get($cm-color-primary, $cm-color-variant-variable-alpha),
  map-get($cm-white-label-color-primary-keys, $cm-color-variant-darker): map-get($cm-color-primary, $cm-color-variant-darker),
  map-get($cm-white-label-color-primary-keys, $cm-color-variant-regular): map-get($cm-color-primary, $cm-color-variant-regular),
  map-get($cm-white-label-color-primary-keys, $cm-color-variant-lighter): map-get($cm-color-primary, $cm-color-variant-lighter),
  map-get($cm-white-label-color-primary-keys, $cm-color-variant-lightest): map-get($cm-color-primary, $cm-color-variant-lightest),
  map-get($cm-white-label-color-primary-keys, $cm-color-variant-near-white): map-get($cm-color-primary, $cm-color-variant-near-white),
  map-get($cm-white-label-color-primary-keys, $cm-color-variant-variable-text): map-get($cm-color-primary, $cm-color-variant-variable-text),
  map-get($cm-white-label-color-secondary-keys, $cm-color-variant-darker): map-get($cm-color-secondary, $cm-color-variant-darker),
  map-get($cm-white-label-color-secondary-keys, $cm-color-variant-regular): map-get($cm-color-secondary, $cm-color-variant-regular),
  map-get($cm-white-label-color-secondary-keys, $cm-color-variant-lighter): map-get($cm-color-secondary, $cm-color-variant-lighter),
  map-get($cm-white-label-color-secondary-keys, $cm-color-variant-lightest): map-get($cm-color-secondary, $cm-color-variant-lightest),
  map-get($cm-white-label-color-secondary-keys, $cm-color-variant-near-white): map-get($cm-color-secondary, $cm-color-variant-near-white),
  map-get($cm-white-label-color-secondary-keys, $cm-color-variant-variable-text): map-get($cm-color-secondary, $cm-color-variant-variable-text),
);
$cm-color-brown: (
  100: #880000,
);

/* UI */
$cm-ui-color-success: map-get($cm-color-green, $cm-color-variant-regular);
$cm-ui-color-warning: map-get($cm-color-orange, $cm-color-variant-regular);
$cm-ui-color-error: map-get($cm-color-red, $cm-color-variant-regular);
$cm-ui-color-info: map-get($cm-color-primary, $cm-color-variant-lightest);

/* font */
$cm-font-color-text: map-get($cm-color-grey, $cm-color-variant-regular);
$cm-font-color-text-light: map-get($cm-color-grey, $cm-color-variant-lightest);
$cm-font-color-success-text: map-get($cm-color-green, $cm-color-variant-regular);
$cm-font-color-warning-text: map-get($cm-color-orange, 70);
$cm-font-color-error-text: map-get($cm-color-red, $cm-color-variant-regular);

@mixin setWhitelabelColorWithFallback($property-name, $variable-name, $important: true, $fallbackValue: null) {
  @if $fallbackValue == null {
    #{$property-name}: map-get($cm-white-label-default-colors, $variable-name);
  }
  @if $fallbackValue != null {
    #{$property-name}: $fallbackValue;
  }
  @if $important {
    #{$property-name}: var(--#{$variable-name}) !important;
  }
  @if $important == false {
    #{$property-name}: var(--#{$variable-name})
  }
}

/* PSA */
$cm-psa-color-date-row-border: #f3f3f3;
$cm-psa-color-date-row-background: #f6fff6;
$cm-psa-color-submit-failed-background: #ffaec9;
