.giacom-support-ticket-detail {
    &__body {
        margin-top: 40px;
    }

    &__content {
        color: $giacom-grey2;

        &__title {
            @include Gotham-600;
            font-size: 25px;
            margin-bottom: 10px;
        }

        &__datetime {
            color: $giacom-grey5;
        }

        &__reference {
        }

        &__description {
            margin-top: 45px;
            width: 670px;

            &__title {
                @include Gotham-400;
                color: $giacom-grey5;
                margin-bottom: 10px;
            }
        }
    }

    &__messages {
        width: 670px;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        &__item {
            margin-bottom: 20px;
        }

        &__header {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 10px;

            &__author {
                order: 1;
                @include Gotham-600;
                color: $giacom-grey2;
                flex-basis: 520px;
            }

            &__datetime {
                order: 2;
                flex-basis: 150px;
                margin-left: 10px;
            }
        }

        &__body {
            padding: 20px 15px 25px 15px;
            background-color: $giacom-blue3;
            margin-left: 15px;
        }
    }

    &__actions {
        border-top: 6px solid $giacom-grey4;

        .row {
            padding-top: 15px;

            .col-xs-12,
            .col-sm-12,
            .col-md-12,
            .col-lg-12 {
                padding: 0;
            }
        }

        button {
            width: 100%;

            &:first-child {
                margin-bottom: 10px;
            }
        }
    }
}

.giacom-support-tick-detail-respond {
    .giacom-form {
        label {
            @include Gotham-600;
            color: $giacom-grey2;
            margin-bottom: 10px;
        }
    }
}
