.review-outcome {
    .message {
        font-size: 16px;
        @include Gotham-400;
        color: $giacom-grey2;
    }

    .user-name,
    .service-journey-message {
        @extend .message;
        @include Gotham-600;
        color: red;
    }

    .form-title {
        h4 {
            font-size: 20px;
            font-weight: 700;
        }
    }

    .row {
        margin-bottom: 30px;

        &.section {
            margin-bottom: 25px;

            .section-title {
                font-weight: 600;
                border-bottom: solid 1px $giacom-grey3;
                margin-bottom: 20px;
            }
        }

        .title {
            margin-bottom: 15px;
        }

        i.fas.fa-trash-alt {
            color: $giacom-pink1;
            margin-right: 10px;
        }

        .item {
            margin-bottom: 10px;
        }
    }

    .group-button {
        display: flex;
        margin-bottom: 30px;

        .btn {
            margin-right: 5px;
        }
    }

    .group-button.confirmation-box {
        display: block;
        font-size: 18px;
        font-weight: 700;
    }
}
