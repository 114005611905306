.giacom-service-complete {
    max-width: 658px;

    .text {
        padding-top: 30px;
        font-size: 16px;
        line-height: 1.83em;
        padding-bottom: 20px;
    }

    .title {
        padding-bottom: 10px;

        .title-heading {
            margin-top: 25px;
            margin-bottom: 15px;
        }

        .title-heading-next {
            font-size: 16px;
            color: $giacom-grey2;
            padding-bottom: 10px;
        }
    }

    a {

        font-size: 16px;
    }

    ul {
        padding: 0;
        margin-top: 15px;

        li {
            list-style: none;
            padding-bottom: 15px;
        }
    }

    div {
        &.number {
            border-radius: 100px;
            width: 25px;
            height: 25px;
            background-color: var(--primary);
            color: var(--white);
            font-size: 13px;
            display: inline-block;
            text-align: center;
            padding-top: 4px;
        }

        span {
            &.text-number {
                margin-left: 15px;
            }
        }
    }

    .line-bottom {
        color: $giacom-grey2;
        border-bottom: 1px solid $giacom-grey3;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .group-button {
        float: right;
        margin-bottom: 30px;

        button {
            &.giacom-btn-no-background {
                margin-right: 5px;
            }

            .customer-name {
                text-overflow: ellipsis;
                overflow: hidden;
                width: 150px;
            }
        }
    }
}

@media (max-width: 766px) {
    .group-button {
        display: flex;
        flex-direction: column;
        width: 100%;

        .giacom-btn-secondary {
            order: 2;
            width: 100%;
        }

        .giacom-btn-primary {
            order: 1;
            width: 100%;
            margin-bottom: 15px;
        }
    }
}
