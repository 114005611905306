// HEADERS
.fluent-h1 {
    font-size: 32px;
    line-height: 39px;
    font-weight: 700;
}

.fluent-h2 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
}

.fluent-h3 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
}

.fluent-h4 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
}

.fluent-h5 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
}

.fluent-h6 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

// DETAILS - READONLY LABEL-VALUE LISTS BUT NOT IN TABLE. EXAMPLE: https://www.figma.com/file/bYKBRt8qVBrMLUZ1Vdq27o/Screens?node-id=2731-307219&t=lxXGCauNHExxi2U9-0
.fluent-details-label {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
}

.fluent-details-value {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

.fluent-details-bottom-gap {
    margin-bottom: 20px;
}
