.giacom-support {
    .giacom-support-knowledgebase {
        &__description {
            margin-bottom: 10px;
        }

        &__search {
            margin-top: 5px;
            margin-bottom: 20px;

            &__button {
                button.giacom-btn-primary {
                    width: 100%;
                }
            }
        }

        &__common-enquiries {
            &__title {
                @extend .giacom-support-title;
            }

            &__content {
                &__view-all {
                    a {
                        @include Gotham-600;
                        color: $giacom-blue1;

                        &:hover,
                        &:focus {
                            color: $giacom-blue1;
                        }
                    }
                }
            }
        }

        .giacom-support__panel__body__link-list.customized-list {
            max-height: 554px;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}

.giacom-support-knowledgebase-result {
    margin-top: 30px;

    &__title {
        @include Gotham-600;
        color: $giacom-grey2;
        margin-bottom: 20px;
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin-bottom: 10px;

            a {
                color: $giacom-blue1;
                font-size: 16px;
                @include Gotham-400;

                &:hover,
                &:active,
                &:focus {
                    color: $giacom-blue1;
                }
            }
        }
    }

    &__outcome {
        margin-top: 25px;

        &__return {
            @include Gotham-600;
            color: $giacom-grey2;
            margin-bottom: 10px;
        }
    }

    &__input {
        display: flex;

        &.giacom-form {
            .form-control {
                width: 60%;
                order: 1;
            }
        }

        &--button {
            order: 2;
            width: 130px;
            margin-left: 10px;
        }
    }
}

@media screen and (max-width: 767px) {
    .giacom-support-knowledgebase-result {
        &__input {
            display: flex;
            flex-direction: column;

            &.giacom-form {
                .form-control {
                    width: 100%;
                    order: 1;
                }
            }

            &--button {
                order: 2;
                width: 100%;
                margin-top: 10px;
                margin-left: 0;
            }
        }

        &__outcome {
            margin-bottom: 30px;
        }
    }
}
