.giacom-end-user .giacom-end-user__activity__view-email .giacom-sub-title {
    margin-top: 20px;
}

.giacom-end-user {
    &__activity {
        .giacom-sub-title {
            display: flex;
            flex-direction: row;
            align-items: center;

            span:not(.fa) {
                order: 1;
                flex-grow: 2;
            }

            button {
                order: 2;
                flex-basis: 147px;
            }
        }

        &__view-email {
            .giacom-sub-title {
                display: flex;
                flex-direction: row;
                align-items: center;

                span:not(.fa) {
                    order: 1;
                    margin-right: 20px;
                }

                button {
                    &.re-deliver {
                        order: 2;
                        margin-right: 10px;
                    }

                    &.download {
                        order: 3;
                    }
                }
            }

            &__info {
                padding-top: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid $giacom-grey3;

                &__title,
                &__content {
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                &__title {
                    label {
                        @include Gotham-400;
                    }
                }

                &__content {
                    label {
                        @include Gotham-400;
                    }
                }
            }

            &__content {
                margin-top: 25px;

                p {
                    color: $giacom-grey2;
                    @include Gotham-400;
                }
            }

            .row.giacom-end-user__activity__view-email__info {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}

.giacom-end-user {
    &__activity {
        &__re-deliver-email {
            .giacom-form .form-group {
                margin-bottom: 10px;
            }

            .giacom-popup-form label {
                &.text-bold {
                    margin-top: 0;
                }

                &.content {
                    text-align: left;
                    @include Gotham-400;
                }
            }

            &__note {
                &.form-group {
                    margin-bottom: 15px;
                }

                @include Gotham-600;
                color: $giacom-grey2;

                &__important {
                    color: $giacom-pink1;
                }
            }

            .giacom-dropdown-header {
                button.dropdown-toggle {
                    width: 100%;
                }
            }

            &__guideline {
                label:not(.text-bold) {
                    @include Gotham-400;
                }

                &__text-bold {
                    @include Gotham-600;
                }
            }
        }
    }
}

.content-download {
    margin-bottom: 10px;

    &__button {
        margin-right: 5px;
    }
}
