@import "./unity-bootstrap/unity-typography";
@import "./unity-bootstrap/unity-header";
@import "./unity-bootstrap/unity-popover";
@import "./unity-bootstrap/unity-columns";
@import "./unity-bootstrap/unity-spacing";
@import "./unity-bootstrap/unity-footer";
@import "./unity-bootstrap/unity-gap";
@import '../css/variables/colors';

.fade:not(.show) {
    opacity: 1;
}

.modal.fade .modal-dialog {
    transform: initial;
}

.giacom-btn.btn.giacom-btn-primary {
    font-family: Montserrat, Arial, Helvetica, sans-serif !important;
    background-color: $brand-primary-light !important;
    border-radius: 4px;
    border: 1px solid $brand-primary !important;

    &:hover {
        background-color: $brand-primary !important;
    }
}

.giacom-btn.btn.giacom-btn-secondary-alt {
    font-family: Montserrat, Arial, Helvetica, sans-serif !important;
    background-color: $white !important;
    border-radius: 4px;
    border: 1px solid $brand-primary-light !important;
    color: $brand-primary-light !important;

    &:hover {
        background-color: lighten($brand-primary, 65%) !important;
        border-color: $brand-primary !important;
        color: $brand-primary !important;
    }
}

.giacom-btn.btn {
    font-weight: 500;
}

.modal-header button:first-child {
    order:1
}

.modal-backdrop {
    background: $black-with-alpha;
    z-index: 1019;
}

/** TODO: Remove after modals are updated */
.modal:not(.cm-modal), .modal:not(.cm-modal) .modal-dialog {
    min-width: 500px
}

