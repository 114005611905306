// New Bootstrap Colors
// Colors
$purple: #67236A;
$dark-purple: #351436;
$very-dark-purple: #100235;
$light-purple: #99338A;
$secondary-light-purple: #F0E9F0;
$white: #FFFFFF;
$secondary-pink: #E6007E;

$text-default-light: $white;
$text-disabled: #adb5bd;
$text-grey: #757575;
$text-black: black;
$light-background: #E8E8E8;

$background-default-light: $white;
$black-with-alpha: rgba(0, 0, 0, 0.5);
$black-with-alpha-0-1: rgba(0, 0, 0, .1);

// Color system
$light-gray: #F6F6F6;
$gray-100: #f8f9fa;
$gray-600: #868e96;
$gray-800: #343a40;
$yellow: #FED670;
$green: #9DD074;
$cyan: #17a2b8;
$primary: $purple;
$secondary: $light-purple;
$orange: #FBBA6A;
$gray-border: #00000020;

// Body
$body-color: #222222;
$body-bg: #F5F5F5;

// Border
$color-border-gray: #00000020;

//Old Colors
$aqua: rgb(24, 182, 195);
$grey: $gray-600;
$light-grey: $gray-100;
$pink: rgb(235, 22, 98);

/* variations */
$aqua-semi-transparent: rgba(24, 182, 195, 0.6);
$aqua-light: #a0d5da;

/* UI */
$brand-primary: $primary;
$brand-primary-light: $secondary;
$brand-secondary: $gray-800;

$cta-primary: $primary;
$cta-secondary: $secondary;

$success: $green;
$warning: $yellow;
$error: #EE6767;
$info: lighten($primary, 25%);
$error-background: #f5cccc;

/* font */
$default-text-color: $gray-800;
$light-text-color: lighten($gray-800, 30%);
$default-link-color: $brand-primary;
$default-link-hover-color: darken($default-link-color, 10%);

$success-text: darken($success, 30%);
$warning-text: darken($warning, 30%);
$error-text: darken($error, 30%);
$info-text: darken($info, 30%);

/* background */
$default-hover-bg-color: #fafafa;

/* status */
// --disabled-status: color-mod(var(--grey) blend(white 80%));
$disabled-status: lighten($grey, 80%);
$control-disabled-grey: #f8f8f8;

// Tables
$thead-light-bg: #e8e8e8;
$thead-light-border-color: #dee2e6;
$thead-light-color: #495057;

$footer-404-bg-color: #AFAFAF;

$login-image-background: #F0E9F0;

$warning-alert-background: #fef1e1;
