ul.giacom-select-service {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: initial;
    width: 100%;
    height: 18.375em;
    border: 1px solid $giacom-grey3;
    overflow-y: auto;

    li {
        height: 2.8em;
        padding: 0.7em;
        border-bottom: 1px solid $giacom-grey3;
        display: flex;
        width: 100%;
        justify-content: space-around;

        &:last-child {
            border-bottom: 0;
        }

        &:hover {
            cursor: pointer;
            background-color: $giacom-grey4;
        }

        span {
            &.logo {
                width: 10%;

                img {
                    position: relative;
                    top: -2px;
                    right: 1px;
                    width: 26px;
                    height: 26px;
                }
            }

            &.service-name {
                font-size: 1em;
                width: 80%;

                &.opacity-service {
                    opacity: 0.5;
                }
            }

            &.fas {
                font-size: 0.875em;
                color: $giacom-blue1;
                margin-left: auto;
                margin-top: 4px;

                &.amber {
                    color: #e59412 !important;
                }

                &.red {
                    color: #e01d1d !important;
                }
            }

            &.fa-arrow-right {
            }

            &.status {
                width: 51px;
                color: $giacom-pink1;
                @include Gotham-600;
                font-size: 0.875em;
            }

            &.fa-check {
                color: $giacom-pink1;
            }
        }
    }
}

.giacom-microsoft-partner {
    &.giacom-popup .modal-dialog.customise {
        width: 750px;
        height: 100%;
    }

    .description {
        &.form-group {
            margin-top: 15px;
            margin-bottom: 10px;
        }

        label {
            font-weight: 300;
        }
    }

    .microsoft-partner-info {
        border: 1px solid $giacom-grey5;
        padding-top: 10px;

        .form-group {
            margin-bottom: 15px;
            margin-right: 10px;

            label {
                font-weight: 400;

                &.text-bold {
                    font-weight: 600;
                }
            }
        }

        .text-bold {
            margin-top: 0;
        }
    }

    .microsoft-partner-id {
        label {
            margin-top: 10px;
        }

        &__input {
            display: inline-flex;
            align-items: center;

            input {
                width: 95%;
            }
        }
    }

    .form-horizontal .form-group {
        margin-left: 0;
    }

    .giacom-service-form .form-content {
        max-width: initial;
        margin: 0;
    }

    .giacom-popup-form .button-group-customized {
        margin-top: 40px;
        margin-bottom: 25px;
    }
}

@media screen and (max-width: 767px) {
    .giacom-popup {
        .modal-dialog.customise.user {
            &.add-new-service {
                width: 65%;
            }
        }

        &.giacom-microsoft-partner {
            .modal-dialog.customise.user {
                &.add-new-service {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

}


@media screen and (max-width: 443px) {
    .giacom-popup {
        .modal-dialog.customise.user {
            &.add-new-service {
                width: 90%;
            }
        }

        &.giacom-microsoft-partner {
            .modal-dialog.customise.user {
                &.add-new-service {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    ul.giacom-select-service {
        li {
            span {
                &.logo {
                    width: 30px;
                }

                &.service-name {
                    padding-left: 10px;
                    font-size: 15px;
                }

                &.fas {
                    font-size: 16px;
                    color: $giacom-blue1;
                    //margin-left: auto;
                }

                &.status {
                    width: 60px;
                    color: $giacom-pink1;
                    //@include OpenSans-400;
                    font-size: 14px;
                }
            }
        }
    }

}
