.myservices-custom{
    font-size: 14px !important;
    .headline{
        font-size: 16px !important;
    }
    .margin-bottom-standard {
        margin-bottom: 5px !important;
    }
    .giacom-btn.btn {
        height: 30px;
        border-radius: 0;
        border: none;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        color: #FFFFFF;
    }
    .exchange-setting{
        width:90%;
        margin: 0 5%;
    }

    .giacom-manage-hosted-exchange-dns {
        width: 90%;
        margin: 5%;
        p {
            font-size: 14px !important;
            margin-bottom: 5px !important;
        }

        .title {
            font-size: 16px !important;
            color: #253036 !important;
            padding-bottom: 5px !important;
            margin: 5px 0 5px 0 !important;
        }
    }
}
