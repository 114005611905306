.azure-resource-groups {
    margin-top: 30px;
    .fa-info-circle {
        font-size: 16px;
        color: $giacom-blue1;
        &:hover {
            cursor: pointer;
        }
    }
    .giacom-popup-form label.text-bold {
        margin-top: 0;
    }

    .select-resource {
        label.text-bold {
            margin-top: 10px;
        }
        .dropdown-toggle {
            width: 100%;
        }
    }
    .giacom-end-user {
        &__grid {
            &__header {
                &__datetime{

                }
                &__action {
                    width: 50px;
                }
            }
        }
    }
}
