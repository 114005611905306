typeahead-container.dropdown.dropdown-menu.open {
    z-index: 10000 !important;
    height: auto !important;
    max-height: 200px !important;

    .dropdown-item.active, .dropdown-item:active {
        color: var(--primary);
        background-color: var(--secondary);
    }
}
