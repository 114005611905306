@import "support_network";
@import "support_new_ticket";
@import "support_ticket_detail";
@import "support_tickets_list";
@import "support_tickets_panel";
@import "support-knowledgebase";

$giacom-panel-margin-bottom: 20px;
.giacom-support-title {
    font-size: 16px;
    @include Gotham-600;
    color: $giacom-grey2;
}

.giacom-support {
    .giacom-support-dashboard {
        margin-top: 30px;
    }

    &__panel {
        background-color: $giacom-blue3;
        padding: 20px 15px 20px 15px;
        margin-bottom: $giacom-panel-margin-bottom;

        &__header {
            padding-bottom: 20px;

            .title {
                @extend .giacom-support-title;
            }
        }

        &__body {
            a {
                color: $giacom-blue1;
                @include Gotham-400;

                &:hover,
                &:focus,
                &:active {
                    color: $giacom-blue1;
                }
            }

            &__link-list {
                list-style: none;
                padding: 0;
                margin: 0;
                margin-top: 10px;

                li {
                    padding-bottom: 15px;

                    &:last-child {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }

    .giacom-support-others {
        height: 145px;

        .giacom-support__panel__body__link-list {
            margin-top: 0;
        }
    }
}
