.giacom-add-admin-user-popup {
    .modal-dialog {
        width: 400px;
    }

    &__button-group {
        margin-top: 20px;
        margin-left: 15px;
        margin-bottom: 30px;

        button {
            width: 165px;

            &:first-child {
                margin-right: 10px;
            }
        }

        &__engineer {
            margin-right: 10px;
        }
    }

    &__first-engineer {
        .modal-dialog {
            width: 600px;
        }

        .giacom-popup-form label.text-bold {
            margin-top: 0;
        }

        button.dropdown-toggle {
            width: 100%;
        }

        &__important {
            color: $giacom-pink1;
            @include Gotham-600;
            margin-bottom: 15px;
        }

        &__note {
            margin-top: 15px;
            color: $giacom-grey2;
            @include Gotham-300;
        }
    }
}
