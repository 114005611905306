@media screen and (max-width: 768px) {
}

@media screen and (min-width: 768px) {
}

@mixin panel-common-setting {
    border-radius: 0;
    // box-shadow: 3px 3px 5px $giacom-blue2;
    border: none;
}

.giacom-panel-form {
    &.panel {
        @include panel-common-setting;
    }

    .title {
        font-size: 21px;
        color: $giacom-grey2;
        @include Gotham-600;
        margin: 0 0 30px 0;
    }

    .panel-body {
        padding-bottom: 30px;
    }
}

.giacom-panel {
    &.panel {
        @include panel-common-setting;
        height: 325px;
        margin-bottom: 17px;
        position: relative;
    }

    &.common-panel {
        border: 1px solid $giacom-grey3;
        box-shadow: 3px 3px 5px $giacom-grey3;
        position: relative;
        padding-top: 10px;
        min-height: 293px;
    }

    .panel-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 0;
        padding-right: 0;
    }

    .button-group {
        height: 35px;

        .btn:not(:first-child) {
            position: relative;
            left: -4px;
        }
    }

    .button-bottom {
        width: 100%;
        position: absolute;
        text-align: center;
        bottom: 20px;
    }

    .title {
        margin: 0;
        padding: 15px 15px 15px 0;
        font-size: 18px;
        @include Gotham-400;
    }

    .dashpromotion {
        min-height: 230px;
    }

    .information {
        order: 1;

        .arrow-left {
            position: absolute;
            top: 48%;
            left: 10px;
        }

        .arrow-right {
            position: absolute;
            top: 48%;
            right: 10px;
        }

        span.fas {
            font-size: 20px;
            // background-color: $giacom-blue1;
            border-radius: 100px;
            color: $giacom-white;
            width: 30px;
            height: 30px;
            cursor: pointer;

            &.fa-chevron-left {
                padding: 6px 0;
                padding-right: 5px;
                float: left;
                margin-top: 15px;
            }

            &.fa-chevron-right {
                padding: 6px;
                padding-right: 5px;
                float: right;
                margin-top: 15px;
            }
        }

        span.icon {
            display: inline-block;
            border-radius: 100px;
            padding: 12px 0;
            width: 50px;
            height: 50px;
            background-color: $giacom-grey4;
            margin-bottom: 15px;

            &.fas {
                font-size: 25px;
                color: $giacom-grey2;
            }
        }

        .main-content {
            font-size: 40px;
            @include Gotham-600;
            color: $giacom-grey2;
            padding: 0 15px;

            &.service-name {
                font-size: 30px;
            }

            &.sub {
                font-size: 30px;
            }

            .addition {
                font-size: 20px;
            }
        }

        .note {
            font-size: inherit;
            @include Gotham-400;
            color: $giacom-grey2;
            padding-left: 10px;
            padding-right: 10px;
        }

        ul.support {
            @include giacom-panel-list;
            margin-bottom: 0;

            li {
                padding: 15px 10px;
            }

            span {
                display: block;
            }

            .notify {
                position: relative;
                top: 5px;
                font-size: 14px;

                span {
                    display: inline-block;
                }
            }
        }
    }
}
