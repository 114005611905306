.giacom-menu-vertical {
    padding: 0.9375em;
    width: 18.75em;
    height: 100%;
    top: 0;
    z-index: 1000;
    right: 0;
    background-color: $giacom-grey2;
    position: fixed;
    float: right;

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        border-bottom: 0.0625em solid $giacom-grey1;

        a {
            display: block;
            font-size: inherit;
            @include Gotham-400;
            color: $giacom-white;
            padding: 0.9375em 0;
        }

        &.active,
        &:hover,
        &:focus {
            a {
                color: $giacom-blue1;
                text-decoration: none;
            }
        }
    }

    ul.message {
        color: $giacom-white;
        padding-bottom: 10px;
        border-bottom: 3px solid $giacom-blue1;

        li {
            display: inline;
            border-bottom: none;
        }

        .user-name {
            display: inline-block;
            width: calc(100% - 80px);
        }

        .sign-out {
            .fas {
                margin-left: 4px;
            }

            .fa-caret-down {
                font-size: 16px;
                padding-left: 3px;
            }
        }
    }

    .icon {
        height: 50px;

        .fa-times {
            float: right;
            font-size: 20px;
            color: $giacom-white;
        }
    }

    .secondary-menu-vertical {
        border-top: 1px solid $giacom-blue1;
        margin-top: 30px;

        ul {
            font-size: 1.4rem;
        }
    }

    .vertical-menu-scroll {
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 30px;
    }
}
