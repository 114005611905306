.giacom-modify-distribution-group {
    .form-content {
        max-width: initial;
    }

    .giacom-service-form {
        .button-group {
            justify-content: initial;
            border-top: none;

            button {
                margin-top: 10px;

            }
        }
    }

    .giacom-form {
        .form-group {
            margin-bottom: 10px;
        }

        .form-grid {
            margin: 0 0 10px 15px;
        }

        .form-subtitle {
            margin: 40px 0 0 22px;
            font-weight: 600;
        }
    }

    .spam-setting {
        .popover {
            width: 480px;
        }
    }

    .spam-level {
        .popover {
            max-width: 600px;
            width: 100%;
        }
    }

    .archive-rule {
        .popover {
            width: 480px;
        }

        .popover-content {
            margin-top: 60px;
        }
    }

    .content-remove-email {
        color: $giacom-pink1;

        .content-information {
            margin-bottom: 30px;
        }

        .check-delete {
            display: inline-flex;
            margin-bottom: 30px;

            .giacom-checkbox {
                margin-left: 5px;
            }
        }
    }

    .please-note {
        @include Gotham-400;
        color: $giacom-pink1;
    }

    .add-new-domain {
        font-size: 22px;
        @include Gotham-600;
    }

    .manage-available-group {
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 260px;

        .manage-available-content {
            display: inline-block;
            width: 100%;
            margin-bottom: 5px;

            .giacom-checkbox {
                display: inline-flex;
            }
        }

        span.name {
            margin-left: 5px;
        }
    }

    .title {
        margin-bottom: 10px;
        @include Gotham-400;
    }

    .align-item {
        margin-top: 30px;
    }

    &.giacom-popup .modal-dialog.customise {
        width: 800px;
        max-width: 1000px;
        @media screen and (max-width: 767px) {
            width: 100%;
            max-width: initial;
        }
    }

    .email-address {
        display: inline-flex;
        align-items: center;

        .sign {
            margin: 0 5px;
        }

        input {
            width: calc(100% - 250px);
        }

        .giacom-dropdown-white {
            width: calc(100% - 250px);
        }
    }

    .email-address-customize {
        display: inline-flex;
        align-items: center;

        input {
            width: 100%;
        }
    }

    .enable-reporting {
        margin-top: 10px;
    }

    button.dropdown-toggle {
        width: 100%;
    }

    .internal-only {
        margin-top: 11px;
        display: inline-flex;
    }

    .member-list {
        display: inline-flex;
        align-items: center;

        .giacom-dropdown-white {
            width: 100%;
        }
    }

    span {
        &.fa-trash-o {
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 767px) {
    .giacom-modify-distribution-group {
        .giacom-service-form .button-group button:first-child {
            order: 1;
            margin-right: 0;
        }
    }
}

.giacom-manage-distribution-group-members {
    &.giacom-popup .modal-dialog.customise {
        width: 100%; // max-width: 1000px;
    }

    .headline {
        @include Gotham-600;
        font-size: 18px;
        color: $giacom-grey2;
        margin-bottom: 20px;
        padding-bottom: 15px;
        border-bottom: 1px solid $giacom-grey5;
        text-align: center;
    }

    .manage-distribution-group {
        width: 100%;

        &__title {
            margin-bottom: 25px;
            @include Gotham-400;
            color: $giacom-grey2;
        }

        &__content {
            max-height: 290px;
            overflow-x: hidden;
            overflow-y: auto;

            &__item {
                margin-bottom: 15px;

                span.name {
                    width: 270px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        &__email__address {
            max-height: 250px;
            overflow-x: hidden;
            overflow-y: auto;

            &__item {
                margin-bottom: 15px;

                span.name {
                    width: 270px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        &__title,
        &__content {
            margin-left: 10px;
        }

        &__title,
        &__content__item {
            width: 100%;
            display: inline-flex;

            span.name {
                margin-left: 5px;
            }
        }
    }

    .selection-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 375px;

        button {
            &.giacom-btn-primary {
                background-color: transparent;
            }

            span {
                &.fa-chevron-left,
                &.fa-chevron-right {
                    color: $giacom-pink1;
                    font-size: 45px;
                }
            }

            &.right {
                order: 1;
            }

            &.left {
                order: 2;
                margin-top: 25px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .giacom-manage-distribution-group-members {
        &.giacom-popup .modal-dialog.customise.user {
            width: 100%;
            max-width: initial;
            min-width: initial;
        }
    }
}
