@import "../css/variables/colors";

.giacom-grid {
    margin: 0 -15px;

    .extra-info {
        margin-left: 5px;
        font-weight: 400;
        color: $purple;
    }

    .table-responsive {
        overflow-x: initial;
        border: none;
    }

    .table {
        > tbody > tr > td {
            vertical-align: middle;
            border-top: 1px solid $giacom-grey3;
            padding-right: 0;
        }

        font-size: inherit;
        // color: $purple;
        a {
            padding: 15px 0;
            font-size: inherit;
            color: inherit;
            cursor: pointer;
            @include Gotham-600;
            word-wrap: normal;
            white-space: initial;

            &:hover {
                text-decoration: none;
            }
        }

        tr {
            &:hover {
                background-color: $giacom-grey4;
            }

            td {
                &:first-child {
                    padding-left: 15px;
                }

                &:last-child {
                    padding-right: 15px;
                }
            }
        }

        .icons {
            position: relative;
            top: -14px;
            padding-right: 10px;

            .fas {
                padding-right: 2px;
                font-size: 15px;
                color: $giacom-pink1;

                &:nth-last-child(1) {
                    padding-right: 0;
                }
            }
        }

        .row {
            display: table;
            margin: 0;
            float: right;

            div {
                display: table-cell;
            }
        }

        td {
            cursor: pointer;
        }

        .action {
            width: 120px;
        }
    }

    table {
        tr:last-child {
            border-bottom: 1px solid $giacom-grey3;
        }
    }
}

.giacom-pagination {
    display: block;
    text-align: center;
    float: left;
    margin-left: 15px;

    button {
        background-color: transparent;
        padding-left: 10px;
        color: $purple;
        font-size: inherit;

        &:hover {
            color: $purple;
        }

        &.load-50 {
            border: 1px solid $purple;
            color: $purple;

            &:hover {
                color: $purple;
                background-color: $giacom-grey4;
            }

            &:active,
            &:focus {
                border-color: $purple;
                color: $purple;
            }
        }

        &.load-all {
            border: none;
            color: $purple;

            &:hover {
                color: $purple;
                border: none;
                text-decoration: underline;
            }
        }
    }
}

.service-select {
    padding: 0 0 16px 0;
}

.grid-loading {
    margin-left: 20px;
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
    .giacom-grid {
        margin-left: -15px;
        margin-right: -15px;

        .table-responsive {
            &.user-list {
                td:first-child {
                    padding-left: 15px;
                }

                span {
                    margin-left: 0;
                }
            }

            span {
                margin-left: 15px;

                &.selected-name,
                &.fas {
                    margin-left: 0;
                }
            }

            .giacom-dropdown-grid {
                margin-right: 15px;
            }

            tr {
                td {
                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .dropdown-full-width {
        width: 100%;

        button {
            min-width: initial;
            width: 100%;
        }

        > .dropdown-menu {
            display: block;
            min-width: initial;
            width: 100%;
            position: relative;

            &.mobile-view {
                position: absolute;
            }
        }
    }
    .giacom-grid {
        div.table-responsive {
            overflow-y: hidden;
            overflow-x: hidden;
            border: none;
        }

        table {
            tr {
                td.full-row {
                    padding-right: 15px;

                    .dropdown-full-width {
                        margin-right: 0; //padding-left: 15px;
                    }
                }
            }
        }
    }
    .giacom-dropdown-grid {
        button {
            &.small {
                min-width: 40px;
                text-align: center;
            }
        }
    }
}
