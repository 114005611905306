.giacom-alert {
    font-size: 16px;
    border: 1px solid $giacom-pink1;
    margin-bottom: 10px;
    padding: 10px;
    @include Gotham-400;
    color: $giacom-pink1;

    .error-title {
        @include Gotham-600;
        font-size: 18px;
    }

    .fas {
        font-size: 25px;
        color: $giacom-pink2;
    }

    ul.error-list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            margin: 10px 0 10px 20px;
        }

        .error {
            display: inline;
        }
    }
}
