distribution-group-email-security-archive .giacom-breadcrumb.no-background.move-to-top {
    position: absolute;
    top: 0;
    left: 190px;
}

.giacom-breadcrumb {
    background-color: $giacom-blue1;
    list-style: none;
    color: $giacom-white;
    @include Gotham-400;
    height: 40px;
    padding: 10px 0;
    margin-bottom: 0;
    display: inline-flex;

    li:not(:first-child) {
        margin-left: 10px;
    }

    a {
        font-size: inherit;
        color: inherit;

        &:hover,
        &:focus,
        &:active {
            cursor: pointer;
            color: inherit;
        }
    }

    &.no-background {
        background-color: transparent !important;
        color: $giacom-blue1;
    }
}
