.giacom-promotion {
    padding: 20px 0;

    .promo-content {
        padding-bottom: 20px;
    }

    .headline {
        width: 100%;

        .context {
            float: left;

            .text {
                text-decoration: underline;
                color: $giacom-blue1;
            }
        }

    }

    .related-links {
        ul {
            padding: 0;
            list-style: none;

            li {
                font-size: 16px;

                &:first-child {
                    border-top: 6px solid $giacom-grey4;
                    padding: 15px 0;
                    color: $giacom-grey2;
                    @include Gotham-600;
                }

                padding-bottom: 10px;

                a {
                    color: $giacom-blue1;
                    @include Gotham-400;
                }
            }
        }
    }

    iframe {
        width: 100%;
        height: 377px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .title {
        color: #253036;
        border-bottom: 1px solid #E4E4E4;
        margin-bottom: 30px;
    }

    .step-title {
        font-size: 18px;
        @include Gotham-600;
        color: inherit;
        margin-bottom: 10px;
        margin-top: 20px;
    }
}
