/*

Bootstrap 4.x doesn't support utility classes for gap. 
The classes are compatible with Bootstrap 5.x so once project update Bootstrap library, the classes can be removed

*/

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}