a.menu-link{
    color: #314049 !important;
    &:hover{
        color: #000 !important;
    }
}

.giacom-header {
    .navbar-header.light-header {
        background-color: #fff;
        .giacom-dropdown-header{
            button {
                background-color: #fff;
                color: $giacom-grey2;
            }
        }
        .harmburger-menu a {
            color: $giacom-grey2;
        }
        .fa-bell{
            color: $giacom-grey2;
        }
    }

    .navbar-header.dark-header {
        background-color: $giacom-grey2;
        .giacom-dropdown-header{
            button {
                background-color: $giacom-grey2;
                color: #fff;
            }
        }
        .harmburger-menu a {
            color: #fff
        }
    }

    &.customize {
        background-color: $giacom-grey2;
    }

    .giacom-logo {
        vertical-align: middle;
        width: calc(100% - 310px);
    }

    .notification {
        background-color:transparent;
        color: white;
        text-decoration: none;
        padding: 15px 16px;
        position: relative;
        display: inline-block;
        border-radius: 2px;

      }
      .notification:hover {
        opacity: 0.5;
      }

      .notification .badge {
        position: absolute;
        top: 2.5px;
        right: 1px;
        padding: 5px 10px;
        border-radius: 50%;
        background-color: red;
        color: white;
        transform: scale(0.7);

      }
    .current-user {
        display: inline-block;
        text-align: right;
        float: none;
        .giacom-dropdown-header {
            margin: 0;
        }
    }
    .giacom-navbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .nav.navbar-right {
            margin-right: 0;
        }
    }
    .navbar {
        border: none;
        margin-bottom: 0;
        &.navbar-default {
            background-color: transparent;
            border-color: transparent;
            padding: 0;
        }
        .navbar-header {
            width: 100%;

            .giacom-logo {
                .navbar-right {
                    margin-top: -5px;
                }
            }
        }
        ul.navbar-right {
            padding-right: 0;
            margin-right: 0;
        }

        &-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .connect-notification {
            float: right;
            margin-right: 6px;
            margin-left: auto;
        }

        .navbar-brand {
            height: 100%;
            max-height: 80px;
            padding: 15px 0;
            img {
                //max-width: 193px;
                max-height: 50px;

                //margin-top: 5px;
            }
        }
        .navbar-collapse {
            float: left;
            width: 100%;
            clear: both;
            background-color: $giacom-grey1;
            border-color: transparent;
            height: 70px;
            padding: 0;
            &.collapse {
                height: 70px !important;
            }
        }
    }
    ul.giacom-menu {
        list-style: none;
        padding: 0;
        margin: auto 0;
        height: 70px;
        li {
            display: flex;
            align-items: center;
            list-style: none;
            text-align: center;
            padding: 0 20px 0 0;
            a {
                padding-right: 0;
                padding-left: 0;
                padding-bottom: 5px;
                line-height: 24px;
                font-size: inherit;
                font-weight: 400;
                color: $giacom-white;
                &:hover,
                &:focus {
                    // border-bottom: 4px solid $giacom-blue2;
                    color: $giacom-white;
                    background-color: transparent;
                }
            }
            &.active {
                a {
                    background-color: transparent;
                    // border-bottom: 4px solid $giacom-blue2;
                    color: $giacom-white;
                    &:hover,
                    &:focus {
                        color: $giacom-white;
                        background-color: transparent;
                    }
                }
            }
            .notify {
                // support
                position: absolute;
                left: 65px;
                top: 17px;
                z-index: 10;
                display: table;
                .number {
                    display: table-cell;
                    width: 100%;
                }
            }
        }
    }
    .giacom-dropdown-header {
        width: auto;
        margin: 17.5px 7px 0;

        button {
            &.left {
                text-align: left;
            }
            &.right {
                text-align: right;
            }
        }
        a {
            word-wrap: normal;
            white-space: inherit;
        }
        &.user-name {
            button {
                .selected-item {
                    display: inline-block;
                    margin-right: 10px;
                }
                .dropdown-arrow {
                    position: absolute;
                }
            }
            .dropdown-menu {
                width: 100%;
                min-width: initial; // left: 0;
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    .giacom-header {
        .giacom-dropdown-header {
            margin-right: 0;
        }

        ul.giacom-menu {
            margin-left: 0;
        }
    }
}

@media screen and (max-width: 767px) {
    .giacom-header {
        .navbar {
            .navbar-header {
                .navbar-brand {
                    max-height: 70px;
                    img {
                        //max-width: 140px;
                        margin-top: 0;
                        max-height: 40px;
                    }
                }
            }
            .navbar-collapse {
                height: 60px;
            }
        }

        .harmburger-menu {
            //position: absolute;
            //width: 45px;
            height: inherit;
            //right: 0;
            //top: 10px;
            display: inline-block;
            vertical-align: middle;
            .fas {
                padding: 3px;
                font-size: 20px;
            }
        }

        .navbar-nav.navbar-right {
            margin: 0;
            button {
                width: 100%;
                max-width: initial;
            }
        }
        .giacom-dropdown-header {
            margin-top: 9px;
            padding-right: 0;
            &.open {
                ul.dropdown-menu {
                    border: 1px solid $giacom-grey2;
                    position: relative;
                    background-color: $giacom-white;
                    width: 100%;
                    li {
                        padding: 10px 15px 10px 10px;
                        a {
                            font-size: 16px;
                            // color: $giacom-blue1;
                            padding: 0;
                            &:hover {
                                color: $giacom-blue1;
                            }
                        }
                        &:hover {
                            background-color: $giacom-grey4;
                        }
                    }
                }
            }
        }
        .header-notify {
            display: inline-block;
            vertical-align: middle;
            margin-top: -4px;
            max-width: 40px;
            width: 30px;
            .notify {
                display: table;
                .number {
                    display: table-cell;
                    width: 100%;
                }
            }
        }

        .giacom-logo {
            width: calc(100% - 30px);
        }
    }
}

.giacom-mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 100;
}

.isActive {
    opacity: 0.8;
    height: 100%;
    background-color: $giacom-grey2;
}
