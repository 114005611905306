.giacom-customer-service-suggestion {
    border-top: 1px solid $giacom-grey3;
    font-size: 16px;
    margin-bottom: 0.625em;
    margin-left: -15px;
    margin-right: 0px;

    .header {
        height: 2.5em;
        font-size: 16px;
        margin-right: 15px;
        margin-left: 15px;
        padding-top: 15px;
        margin-bottom: 15px;
        @include Gotham-400;

        span {
            @include Gotham-600;
        }
    }

    ul.content {
        list-style: none;
        padding: 0;
        margin: 0;

        .item {
            height: 3.75em;
            width: 100%;
            display: inline-flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            border-top: 1px solid $giacom-grey3;

            &:hover {
                background-color: $giacom-grey4;
            }

            &:last-child {
                border-bottom: 1px solid $giacom-grey3;
            }

            .service-name {
                order: 1;
                @include Gotham-600;
                flex: 2 0 0;
                margin-left: 15px;
            }

            .link {
                order: 2;
                width: 9.5625em;
                @include Gotham-400;

                a {
                    cursor: pointer;
                    color: $giacom-blue1;
                }
            }

            .action {
                order: 3;
                margin-right: 15px;
            }
        }

        .list-activity {
            min-height: 3.75em;
            width: 100%;
            display: inline-flex;
            align-content: center;
            align-items: center;
            border-top: 1px solid $giacom-grey3;

            span {
                width: 83%;

                &:first-child {
                    width: 17%;
                }

                &.item-one {
                    margin-left: 15px;
                }

                a {
                    cursor: pointer;
                    color: $giacom-blue1;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .giacom-customer-service-suggestion {
        ul.content {
            .list-activity {
                display: flex;
                flex-direction: column;
                padding-top: 15px;
                padding-bottom: 15px;

                span {
                    width: 100%;

                    &:first-child {
                        order: 1;
                        width: inherit;
                    }

                    &:last-child {
                        order: 2;
                    }
                }
            }
        }
    }
}
