.giacom-support-tickets {
    &__grid {
        &__header {
            &__issue {
            }

            &__date {
            }

            &__status {
            }

            &__reminder {
            }

            &__action {
            }
        }

        &__item {
            &__issue {
                &__name {
                    max-width: 470px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    a {
                        @include Gotham-400;

                        &:hover,
                        &:active,
                        &:focus {
                            color: $giacom-blue1;
                        }
                    }
                }
            }

            &__date {
            }

            &__status {
                min-width: 150px;
            }

            &__reminder {
                color: $giacom-pink1;
            }

            &__action {
            }
        }

        .reminder {
        }
    }
}
