.giacom-reset-password {
  &.giacom-page {
    background-color: $giacom-blue1;
  }
  .container-fluid {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .panel {
    width: 100%;
    max-width: 600px;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    margin-bottom: 0;
    max-height: 100%;
    overflow-x: auto;
  }
  .giacom-panel-form {
    .form-group {
      input {
        //width: 322px;
        width: 95%;
      }
    }
    button {
      &.giacom-btn-primary {
        &.sms{
            margin-left: 10px;
        }
      }
    }
    a{
      color: $giacom-blue1;
    }
  }
}
