.notify {
    color: $giacom-grey2;

    span {
        font-size: 11px;
        color: $giacom-grey2;
        display: inline-block;
        border-radius: 100px;
        padding: 2px 7px;
        width: 20px;
        height: 20px;
        background-color: $giacom-yellow1;

        &.number {
            @include Gotham;
        }
    }
}
