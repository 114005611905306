.giacom-service-header {
    display: inline-flex;
    width: 100%;
    list-style: none;
    background-color: $giacom-pink1;
    padding: 10px 15px;
    margin-bottom: 0;
    margin-top: 1px;
    align-items: center;

    .primary-group {
        width: 60%;
    }

    .task-title {
        display: inline-block;
        font-size: 21px;
        @include Gotham-600;
        color: $giacom-white;
    }

    .task-complete {
        font-size: 21px;
        @include Gotham-600;
        color: $giacom-white;
    }

    .steps {
        display: inline-block;

        ul {
            display: inline-flex;
            list-style: none;

            li {
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    //  cursor: pointer;
                    .step:hover {
                        //  background-color: $giacom-white;
                        //  color: $giacom-pink1;
                    }
                }
            }

            .step {
                font-size: 16px;
                @include Gotham-400;
                color: $giacom-white;
                width: 30px;
                height: 30px;
                border: 2px solid $giacom-white;
                border-radius: 100%;
                background-color: transparent;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: default;

                &.active {
                    background-color: $giacom-white;
                    color: $giacom-pink1;
                    @include Gotham-600;
                }
            }
        }
    }

    .action {
        width: 40%;
        text-align: right;

        button {
            border: 2px solid $giacom-white;

            &:hover,
            &:active,
            &:focus {
                border: 2px solid $giacom-white;
            }
        }
    }
}

.giacom-service-header-wrapper {
    background-color: $giacom-pink1;

    .giacom-page-wrapper {
        padding: 0;
    }
}

@media screen and (max-width: 767px) {
    .giacom-service-header {
        padding-top: 15px;
        padding-bottom: 15px;

        .steps {
            margin-top: 15px;

            ul {
                margin: 0;
                padding: 0;
            }

            margin-right: auto;
        }

        .action {
            button {
                margin-left: auto;
            }
        }

        .primary-group {
            display: flex;
            flex-direction: column;
        }
    }
}
