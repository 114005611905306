@import 'assets/css/variables/colors';

.giacom-your-account {
    .giacom-sub-navigation {
        background: $background-default-light;
        color: $body-color;
        position: relative;
        margin: -24px -15px 16px;
        padding-top: 48px;
        min-width: 100%;
        padding-left: 30px;
    }

    .card-title {
        padding-bottom: 46px;
    }

    .table {
        margin-top: 20px;

        caption {
            padding-bottom: 20px;
            color: $giacom-grey2;
        }

        thead tr {
            th {
                border-bottom: 0;
                padding: 15px 10px;

                &:last-child {
                    text-align: right;
                }
            }
        }

        tbody tr {
            td:first-child a {

                &:hover {
                    text-decoration: none;
                }

            }
        ;

            td {
                vertical-align: middle;
            }

            &:first-child {
                td {
                    border-top: 0;
                }
            }
        }
    }

    .btn-view {
        float: right;
    }

}

.terms-conditions {
    padding-bottom: 40px;

    .giacom-sub-navigation {
        padding: 20px 10px;
    }

    .title {
        font-size: 25px;
    }

    .detail {
        margin-top: 20px;

        a {
            margin-left: 15px;
            color: $giacom-white;
            font-weight: normal;
        }
    }
}

.pdf-banner {
    width: 100%;
    background-color: $giacom-grey5;
    padding: 10px 0;
}

.btn-function {
    margin: 0 auto;
    max-width: 300px;
    color: $giacom-white;

    .fas {
        font-size: 25px;
        margin-right: 5px;

        &:hover {
            cursor: pointer;
        }
    }

    input {
        width: 40px;
        margin-left: 15px;
        background-color: $giacom-black;
        text-align: right;
    }

    .pdf-number-page {
        font-weight: normal;
        margin-right: 15px;
    }

    .fa-angle-double-down {
        margin-left: 20px;
    }

}


.pdf-frame {
    height: 100%;
    width: 100%;
    max-height: 600px;
    overflow-x: hidden;
    border: 1px solid $giacom-grey3;
    margin: 0 auto;

    svg:first-of-type {
        max-width: 110%;
    }
}
