.carousel-points {
    list-style-type: none;
    text-align: center;
    padding: 12px;
    margin: 0;
    white-space: nowrap;
    overflow: auto;
    position: absolute;
    width: 100%;
    left: 0;
    box-sizing: border-box;

    li {
        display: inline-block;
        border-radius: 999px;
        background: rgba(255, 255, 255, 0.55);
        padding: 5px;
        margin: 0 3px;
        transition: 0.4s ease all;

        &.active {
            background: white;
        }
    }
}
