.domain {
    input,
    textarea {
        border: 1px solid $giacom-grey3;
    }

    padding-top: 20px;

    ul.giacom-title {
        justify-content: flex-start;
    }

    .text-right {
        padding-right: 0;
    }

    .leftpadded {
        margin-left: 14px;
    }

    .table {
        thead {
            background-color: $giacom-blue3;

            tr th {
                border: none;
                height: 60px;
                vertical-align: middle;
            }
        }

        tr {
            &:first-child {
                td {
                    border-top: none;
                }
            }

            td {
                vertical-align: middle;
            }
        }

        .customizeName {
            max-width: 250px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .missed {
        color: $giacom-pink1;
    }

    .fa-credit-card {
        margin-right: 5px;
    }

    .col-dropdown {
        display: none;
    }

    @media screen and (max-width: 767px) {
        .col-name {
            width: 60%;
        }
        .col-toggle {
            display: none;
            text-align: right;

            &.let-show-this {
                display: inline-block;
                width: 100%;
                max-width: 100%;
            }
        }
        .col-dropdown {
            display: inline-flex;
            padding-right: 0;
            width: 100%;
            vertical-align: middle;
            justify-content: flex-end;
        }
    }
}

.mailbox-popup {
    .mat-slider-horizontal {
        width: 100%;
        margin-top: -10px;
    }

    &.giacom-popup.modal.in .distribution-group {
        width: 100%;
        max-width: 730px;

        .headline {
            text-align: left;
        }
    }

    .mailbox-label {
        text-align: right;
        @media screen and (max-width: 767px) {
            text-align: left;
        }
    }

    .manage-distribution-group {
        max-height: 200px;
        overflow-y: auto;
        padding: 10px;
        margin-bottom: 15px;
    }
}

.domain-popup {
    &.small-modal {
        .modal-dialog {
            max-width: 500px;
        }
    }

    .main-text {
        height: 400px;
        overflow: auto;
        margin-bottom: 15px;
    }

    .statement {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .btn-group {
        width: 100%;

        .btn + .btn {
            margin-left: 5px;
        }
    }

    .chk-disabled {
        label {
            background-color: $giacom-grey3;
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    .layout-content {
        height: 360px;
    }

    .nav {
        padding-bottom: 10px;
    }

    .btn-wrapper {
        margin-top: 20px;
        display: inline-flex;
        width: 100%;
    }

    .label-control {
        margin-top: 15px;
        margin-bottom: 15px;
        width: 100%;

        > div {
            display: inline-block;
        }

        .glabel {
            font-weight: 600;
            color: $giacom-grey2;
            margin-right: 20px;
            width: 48%;
            text-align: right;
            vertical-align: middle;
        }

        .gcontrol {
            width: 45%;

            input[type='text'] {
                height: 40px;
            }

            .giacom-dropdown {
                float: none;
                max-width: 300px;
                width: 100%;
            }
        }
    }

    .section-title {
        font-weight: 600;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .giacom-dropdown {
        max-width: 100%;

        .dropdown-toggle {
            max-width: 100%;

            .selected-name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                vertical-align: middle;
                color: $giacom-grey2;
            }
        }

        .dropdown-menu {
            max-width: 100%;
            overflow-y: auto;
            max-height: 330px;
        }

        button {
            min-width: 100%;

            span.fas,
            i.fas {
                color: $giacom-white;
                vertical-align: middle;
            }
        }
    }

    .giacom-form input[type='file'] {
        margin-bottom: 0px;
    }

    .side-section-wrapper {
        max-height: 400px;
        overflow-y: auto;
    }

    .side-section {
        width: 48%;
        display: inline-block;
        vertical-align: top;

        + .side-section {
            margin-left: 15px;
        }
    }

    .nav-pills {
        > li {
            & + li {
                margin-left: 0;
            }

            > a {
                background-color: $giacom-grey4;
                color: $giacom-grey2;
                border-radius: 0;

                &:hover {
                    border-radius: 0;
                }
            }

            &.active {
                > a {
                    background-color: $giacom-blue1;

                    &:hover,
                    &:active,
                    &:focus {
                        background-color: $giacom-blue1;
                    }
                }
            }
        }
    }

    &.giacom-popup {
        .modal-dialog.customise {
            width: auto;
        }
    }

    .toggle-data {
        padding-left: 15px;
    }

    .name,
    .check-status {
        display: inline-block;
    }

    .name {
        width: calc(100% - 25px);
    }

    .toggle-data {
        display: none;
    }

    .fa-chevron-right {
        display: inline-block;
    }

    .fa-chevron-down {
        display: none;
    }

    .setting-entry {
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .toggle-header {
        padding-top: 7px;
        padding-bottom: 7px;

        &:hover {
            cursor: pointer;
        }
    }

    .open {
        > .toggle-header {
            .fa-chevron-right {
                display: none;
            }

            .fa-chevron-down {
                display: inline-block;
            }
        }

        > .toggle-data {
            display: block;
        }
    }

    .file-extension,
    .is-banned {
        display: inline-block;
        vertical-align: top;
    }

    .file-extension {
        width: calc(100% - 100px);
    }

    .setting-row {
        margin-top: 10px;
    }

    .input-extension {
        height: 40px;
    }

    .attributes {
        .dropdown-menu {
            max-height: 305px;
        }
    }

    .add-attribute {
        padding-left: 0;
    }

    @media screen and (max-width: 767px) {
        &.small-modal {
            .modal-dialog {
                max-width: 100%;
            }
        }
        button:not(.close) {
            width: 100%;
        }
        .side-section {
            width: 100%;

            + .side-section {
                margin-left: 0;
            }
        }
        .label-control {
            .glabel {
                text-align: left;
                margin-bottom: 5px;
            }

            .glabel,
            .gcontrol {
                width: 100%;
            }
        }
        .btn-group {
            flex-direction: column;

            .btn {
                width: 100%;
            }

            .btn + .btn {
                margin-left: 0;
                margin-top: 10px;
            }
        }
    }
}

// .can-scroll-popup {
//     .modal-content {
//         max-height: calc(100vh - 30px);
//         overflow-y: auto;
//     }
// }

.exchange-setting {
    .red {
        color: $giacom-pink1;
    }

    .green {
        color: green;
    }

    .blue {
        color: $giacom-blue1;
    }

    .fa.fa-caret-down {
        color: $giacom-white;
    }

    .headline {
        font-weight: 600;
        font-size: 21px;
        color: $giacom-grey2;
    }

    .name {
        min-width: 180px;
        display: inline-block;
    }

    .value {
        font-weight: 600;
    }

    .setting-panel {
        border: 1px solid $giacom-border-input;
        padding: 10px;
    }

    .giacom-textarea {
        max-width: 600px;
        width: 100%;
        height: 250px;
        border: 1px solid $giacom-border-input;
        padding: 10px;
    }

    .select-list {
        max-width: 600px;
        width: 100%;
        height: 250px;
        border: 1px solid $giacom-border-input;
        padding-left: 0;
        list-style-type: none;
        overflow-y: auto;

        li {
            padding: 10px;
            cursor: pointer;

            &:hover,
            &.selected {
                background-color: $giacom-grey3;
            }
        }
    }

    .selected-value {
        margin-left: 5px;
    }

    .item-option {
        > div {
            display: inline-block;
        }
    }

    @media screen and (max-width: 767px) {
        padding-left: 10px;
        .name {
            width: 100%;
            display: inline;
        }
        .value {
            margin-left: 10px;
        }
        .giacom-dropdown {
            max-width: 100%;

            .dropdown-toggle {
                max-width: 100%;
            }
        }
    }
}
