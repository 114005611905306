.dropdown-menu.hosted-voice-hardware {
    width:100%;    
}

.dropdown.giacom-dropdown-header.hosted-voice-hardware{
    button{
    width:100%;
        span.option{
            overflow-x: hidden; 
            width: 90%;
            display: inline-block;           
        } 
    }

    .detail{
        margin-top: 10px;
    }
}

.delivery-charge{
    margin-top: 10px;
}


