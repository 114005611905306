.giacom-grid .table {
    .action {
        width: 55px;
    }

    td {
        cursor: initial;
        height: 75px;
    }

    tr td {
        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }
}

.input-limiter {
    max-width: 440px;
}
