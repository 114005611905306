.add-credit {
    .form-body {
        margin-top: 20px;
    }

    .form-group {
        input {
            height: 45px;
        }

        .form-control {
            border-radius: 5px;
        }
    }

    .button-group {
        margin-bottom: 35px;
    }

    .btn-continue {
        float: right;
        margin-bottom: 30px;
        margin-top: 20px;
    }

    .radio-group {
        margin-bottom: 15px;

        label {
            display: block;

            input {
                margin-right: 10px;
            }
        }
    }

    .price {
        margin-bottom: 20px;
    }

    .item {
        display: inline-flex;
        width: 100%;
        margin-bottom: 10px;

        .item-option {
            @include Gotham-400;

            .giacom-radio,
            .giacom-checkbox {
                display: inline-block;

                input {
                    margin-right: 5px;
                }
            }

            .name {
                margin-left: 5px;
                color: $giacom-grey2;
            }

            .text-credit {
                color: $giacom-pink1;
            }
        }
    }

    .btn-group {
        margin-bottom: 20px;
        float: right;

        .btn + .btn {
            margin-left: 10px;
        }
    }

    .option {
        margin-bottom: 15px;
    }

    &.popup {
        .add-ons-message {
            text-align: left;
        }
    }

    iframe {
        border: none;
        min-height: 375px;
        height: 100%;
        width: 100%;
    }

    @media screen and (max-width: 767px) {
        &.popup {
            .item {
                display: inline-block;

                .item-option,
                .amount-period {
                    width: 100%;
                }

                .amount-period {
                    text-align: left;
                    margin-left: 30px;
                }
            }
        }
        .add-ons-message {
            &.giacom-label {
                text-align: left;
            }
        }
        .giacom-btn {
            width: 100%;
        }
        .btn-group {
            display: flex;
            width: 100%;
            flex-direction: column;

            .btn + .btn {
                margin-left: 0;
            }

            .btn {
                margin-bottom: 10px;
            }

            .giacom-btn {
                width: 100%;
            }

            .back {
                order: 3;
            }
        }
    }
}
