.uil-spin-css {
  background: none;
  position: relative;
  width: 40px;
  height: 40px;
  >div {
    width: 24px;
    height: 24px;
    margin-left: 4px;
    margin-top: 4px;
    position: absolute;
    >div {
      width: 100%;
      height: 100%;
      border-radius: 100px;
      background: $giacom-blue1;
    }
    &:nth-of-type(1) {
      >div {
        -webkit-animation: uil-spin-css 1s linear infinite;
        animation: uil-spin-css 1s linear infinite;
        -webkit-animation-delay: -0.87s;
        animation-delay: -0.87s;
      }
      -webkit-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
      transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
    }
    &:nth-of-type(2) {
      >div {
        -webkit-animation: uil-spin-css 1s linear infinite;
        animation: uil-spin-css 1s linear infinite;
        -webkit-animation-delay: -0.75s;
        animation-delay: -0.75s;
      }
      -webkit-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
      transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
    }
    &:nth-of-type(3) {
      >div {
        -webkit-animation: uil-spin-css 1s linear infinite;
        animation: uil-spin-css 1s linear infinite;
        -webkit-animation-delay: -0.62s;
        animation-delay: -0.62s;
      }
      -webkit-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
      transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
    }
    &:nth-of-type(4) {
      >div {
        -webkit-animation: uil-spin-css 1s linear infinite;
        animation: uil-spin-css 1s linear infinite;
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s;
      }
      -webkit-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
      transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
    }
    &:nth-of-type(5) {
      >div {
        -webkit-animation: uil-spin-css 1s linear infinite;
        animation: uil-spin-css 1s linear infinite;
        -webkit-animation-delay: -0.37s;
        animation-delay: -0.37s;
      }
      -webkit-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
      transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
    }
    &:nth-of-type(6) {
      >div {
        -webkit-animation: uil-spin-css 1s linear infinite;
        animation: uil-spin-css 1s linear infinite;
        -webkit-animation-delay: -0.25s;
        animation-delay: -0.25s;
      }
      -webkit-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
      transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
    }
    &:nth-of-type(7) {
      >div {
        -webkit-animation: uil-spin-css 1s linear infinite;
        animation: uil-spin-css 1s linear infinite;
        -webkit-animation-delay: -0.12s;
        animation-delay: -0.12s;
      }
      -webkit-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
      transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
    }
    &:nth-of-type(8) {
      >div {
        -webkit-animation: uil-spin-css 1s linear infinite;
        animation: uil-spin-css 1s linear infinite;
        -webkit-animation-delay: -0s;
        animation-delay: -0s;
      }
      -webkit-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
      transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
    }
  }
}

@-webkit-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-o-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes uil-spin-css {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
