.delete-service {
    .user-info {
        .item {
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
            }
        }
    }

    &.confirmation {
        .confirm {
            font-size: 21px;
            @include Gotham-600;
            color: $giacom-grey2;
        }

        .password {
            @include Gotham-600;
            text-align: right;
            margin-top: 8px;
        }

        .giacom-form {
            padding-left: 0;

            input.form-control {
                width: 350px;
            }
        }

        .group-button {
            .customer-name {
                text-overflow: ellipsis;
                overflow: hidden;
                width: 150px;
            }
        }
    }
}
