@import 'assets/css/variables/colors';

.giacom-end-user {
    .giacom-sub-navigation {
        background: $background-default-light;
        color: $body-color;
        position: relative;
        margin: -16px -15px 16px;
        padding-top: 46px;
        min-width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }

    .giacom-sub-navigation .card-title {
        padding-bottom: 46px;
    }
}
