.giacom-customer-user {
    background-color: $giacom-blue1;

    .user-list {
        padding-bottom: 62px;
    }

    .giacom-page-wrapper {
        .giacom-breadcrumb {
            .customer-name {
                width: 100px;
            }
            .customer {
                max-width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 23px;
                height: 23px;
                white-space: nowrap;
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .giacom-customer-user {
        .container-fluid {
            padding: 0;
        }
    }
}
