$service-tenant: 390px;
ul.service-tenant {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 30px;

    .title {
        order: 1;
        text-align: right;
        width: 237px;
        font-size: inherit;
        @include Gotham-600;
        color: $giacom-grey2;
        font-size: inherit;
        margin: 8px 15px 0 0;
    }

    .term-and-condition {
        display: flex;
        order: 2;
        flex-direction: column;

        .action {
            order: 1;
            margin-bottom: 15px;

            a.btn {
                padding-top: 7px;
            }
        }

        .content {
            order: 2;
            width: $service-tenant;
            padding-bottom: 20px;

            span {
                font-size: inherit;
                @include Gotham-300;
                color: $giacom-grey2;
                line-height: 2;
            }
        }

        .confirm {
            order: 3;
            width: $service-tenant;
            display: inline-flex;
            justify-content: center;

            .giacom-checkbox {
                order: 1;
                align-self: flex-start;
                margin: 0;
                margin-top: 2px;

                label:after {
                    left: 0;
                }
            }

            span {
                order: 2;
                font-size: 16px;
                @include Gotham-400;
                color: $giacom-grey2;
                margin-left: 10px;
            }
        }

        .giacom-checkbox {
            width: 25px;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 798px) {
    ul.service-tenant {
        li {
            &.title {
                width: initial;
                width: 200px;
                max-width: 237px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    ul.service-tenant {
        display: flex;
        flex-wrap: wrap;

        li {
            &.title {
                width: 100%;
                text-align: left;
                padding-bottom: 15px;
            }

            &.term-and-condition {
                margin-right: auto;
                width: 100%;

                button,
                .content,
                .confirm,
                .confirm span {
                    width: 100%;
                }
            }
        }
    }
}
