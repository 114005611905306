.giacom-azure {
    &__agreement {
        .form-content {
            div.form-title {
                h5.step-title {
                    margin-bottom: 25px;
                }
            }
        }

        p {
            @include Gotham-400;
            line-height: 2;
            color: $giacom-grey2;
            margin-bottom: 15px;

            &::last-child {
                margin-bottom: 0;
            }
        }

        &--tick {
            display: inline-flex;
            margin-bottom: 30px;

            .message {
                margin-left: 10px;
            }
        }
    }
}
