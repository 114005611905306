@import 'variables';

.selected-subnavigation-item {
    font-size: 21px;
    font-weight: 600;
}

.giacom-sub-navigation {
    color: $giacom-white;
    // background-color: $giacom-blue1;
    ul {
        &:not(.dropdown-menu) {
            list-style: none;
            padding: 0 0 5px 0;;
            margin-bottom: 0;
            display: inline-flex;
            justify-content: space-between;
        }
    }

    .nav-title {
        width: 100%;

        .title {
            font-size: 25px;
            @include Gotham-600;
            order: 1;
            white-space: nowrap;
            overflow: hidden;
            max-width: 700px;
            text-overflow: ellipsis;
            cursor: pointer;
        }

        .actions {
            order: 2;
            flex-grow: 2;
            margin-left: auto;

            .btn-group {
                float: right;
            }
        }

        .giacom-dropdown-grid {
            .giacom-btn {
                background-color: $giacom-blue2;
                min-width: 260px;
            }

            ul.dropdown-menu {
                min-width: initial;
                font-size: initial;
                width: 100%;

                li:hover {
                    background-color: $giacom-grey4;
                }

                a {
                    // color: $giacom-blue1;
                    font-size: 16px;
                }
            }
        }
    }

    ul.nav-menu {
        padding-top: 0;
        padding-bottom: 24px;
        line-height: 32px;

        li {
            padding-right: 25px;

            @media screen and (max-width: $giacom-medium) {
                font-size: 14px;
            }

            &:last-child {
                padding-right: 0;
            }

            @include Gotham-400;

            a {
                color: $giacom-white;

                &:hover {
                    text-decoration: none;
                    border-bottom: 4px solid $giacom-white;
                }

                &:focus {
                    outline: none;
                    text-decoration: none;
                }
            }

            &.active {
                a {
                    border-bottom: 4px solid $giacom-white;
                    padding-bottom: 5px;
                }
            }

            &:hover,
            &:focus {
                a {
                    border-bottom: 4px solid $giacom-white;
                    padding-bottom: 5px;
                }
            }
        }

        .dropdown-menu li {
            &:hover,
            &:focus,
            &:active {
                border-bottom: none;

                a {
                    border-bottom: none;
                }
            }
        }
    }

    &__breadcrumb {
        margin-top: -10px;
        padding-bottom: 10px;
        background-color: $giacom-blue1;
    }
}

@media screen and (max-width: $giacom-small) {
    .giacom-sub-navigation {
        ul {
            &:not(.dropdown-menu) {
                padding: 15px 0;;
            }
        }

        .nav-title {
            .title {
                font-size: 21px;
            }

            .fullDropdown.giacom-dropdown-grid {
                width: 100%;

                button.giacom-btn {
                    width: 100%;

                    span.fas {
                        &:before {
                            content: "\f00d";
                        }
                    }
                }

                .dropdown-menu {
                    display: block;
                }
            }
        }

        .actions {
            .giacom-dropdown-grid {
                padding-right: 0;

                button.giacom-btn {
                    min-width: 0;
                    width: 40px;
                    height: 40px;
                    text-align: center;

                    span.fas {
                        //margin: 0 3px 0 0;
                    }
                }
            }
        }

        .nav-menu {
            width: 100%;

            .giacom-dropdown-grid {
                font-size: 16px;
                width: 100%;
                padding-right: 0;
                @include Gotham-400;
                color: $giacom-blue1;

                button {
                    min-width: initial;
                    width: 100%;
                    background-color: $giacom-white;
                    color: $giacom-blue1;
                    border: 1px solid $giacom-blue2;
                }

                ul {
                    width: 100%;
                    min-width: initial;

                    li {
                        padding: 0;
                        background-color: $giacom-white;

                        &:hover {
                            background-color: $giacom-grey4;
                        }

                        a {
                            padding: 10px 15px 10px 10px;
                            font-size: 16px;
                            color: $giacom-blue1;
                            @include Gotham-400;

                            &:hover {
                            }
                        }

                        &.active {
                            a {
                                @include Gotham-600;
                                background-color: transparent;
                                border-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.giacom-subnavigation {
    color: $giacom-white;

    .title {
        font-size: 25px;
        @include Gotham-600;
        margin-left: 15px;
    }

    .giacom-dropdown-navigation {
        float: right;

        button {
            min-width: 250px;
            background-color: $giacom-blue2;
            text-align: left;
            margin-right: 15px;

            span.fas {
                font-size: 16px;
            }
        }
    }

    ul.giacom-menu {
        margin-left: 15px;

        li {
            background-color: transparent;
            list-style: none;
            height: 50px;
            text-align: center;
            padding: 4px 20px 0 0;

            a {
                padding-bottom: 5px;
                line-height: 24px;
                color: $giacom-white;
                background-color: transparent;
                @include Gotham-400;

                &:hover {
                    background-color: transparent;
                    border-bottom: 4px solid $giacom-white;
                }
            }
        }
    }
}

@media(max-width: 768px) {
    .giacom-subnavigation {
        .title {
            font-size: 21px;
            @include Gotham-600;
            margin-left: 28px;
        }

        .giacom-menu {
            display: none;
        }

        .giacom-dropdown-grid {
            display: block;
            float: left;
            margin-left: 30px;

            button {
                background-color: $giacom-white;
                color: $giacom-blue1;
                border-radius: 0;
            }
        }
    }
}

@media(min-width: 1023px) {
    .giacom-subnavigation {
        .giacom-menu {
            display: block
        }

        .giacom-dropdown-grid {
            display: none;
        }
    }
}

@media only screen and (min-width: 960px) {
    /* styles for browsers larger than 960px; */
}
