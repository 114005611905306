.giacom-change-password {
  &.giacom-page {
    background-color: $giacom-blue1;
  }
  .container-fluid {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .panel {
    width: 50%;
    align-self: center;
    margin-bottom: 0;
  }
  .giacom-panel-form {
    button {
      &.giacom-btn-primary {
        &.sms{
            margin-left: 10px;
        }
      }
    }
  }
}
