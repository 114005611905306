.mat-elevation-z0 { box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12) }

.mat-elevation-z1 { box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12) }

.mat-elevation-z2 { box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12) }

.mat-elevation-z3 { box-shadow: 0 3px 3px -2px rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 1px 8px 0 rgba(0, 0, 0, .12) }

.mat-elevation-z4 { box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12) }

.mat-elevation-z5 { box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 5px 8px 0 rgba(0, 0, 0, .14), 0 1px 14px 0 rgba(0, 0, 0, .12) }

.mat-elevation-z6 { box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12) }

.mat-elevation-z7 { box-shadow: 0 4px 5px -2px rgba(0, 0, 0, .2), 0 7px 10px 1px rgba(0, 0, 0, .14), 0 2px 16px 1px rgba(0, 0, 0, .12) }

.mat-elevation-z8 { box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12) }

.mat-elevation-z9 { box-shadow: 0 5px 6px -3px rgba(0, 0, 0, .2), 0 9px 12px 1px rgba(0, 0, 0, .14), 0 3px 16px 2px rgba(0, 0, 0, .12) }

.mat-elevation-z10 { box-shadow: 0 6px 6px -3px rgba(0, 0, 0, .2), 0 10px 14px 1px rgba(0, 0, 0, .14), 0 4px 18px 3px rgba(0, 0, 0, .12) }

.mat-elevation-z11 { box-shadow: 0 6px 7px -4px rgba(0, 0, 0, .2), 0 11px 15px 1px rgba(0, 0, 0, .14), 0 4px 20px 3px rgba(0, 0, 0, .12) }

.mat-elevation-z12 { box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .2), 0 12px 17px 2px rgba(0, 0, 0, .14), 0 5px 22px 4px rgba(0, 0, 0, .12) }

.mat-elevation-z13 { box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .2), 0 13px 19px 2px rgba(0, 0, 0, .14), 0 5px 24px 4px rgba(0, 0, 0, .12) }

.mat-elevation-z14 { box-shadow: 0 7px 9px -4px rgba(0, 0, 0, .2), 0 14px 21px 2px rgba(0, 0, 0, .14), 0 5px 26px 4px rgba(0, 0, 0, .12) }

.mat-elevation-z15 { box-shadow: 0 8px 9px -5px rgba(0, 0, 0, .2), 0 15px 22px 2px rgba(0, 0, 0, .14), 0 6px 28px 5px rgba(0, 0, 0, .12) }

.mat-elevation-z16 { box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12) }

.mat-elevation-z17 { box-shadow: 0 8px 11px -5px rgba(0, 0, 0, .2), 0 17px 26px 2px rgba(0, 0, 0, .14), 0 6px 32px 5px rgba(0, 0, 0, .12) }

.mat-elevation-z18 { box-shadow: 0 9px 11px -5px rgba(0, 0, 0, .2), 0 18px 28px 2px rgba(0, 0, 0, .14), 0 7px 34px 6px rgba(0, 0, 0, .12) }

.mat-elevation-z19 { box-shadow: 0 9px 12px -6px rgba(0, 0, 0, .2), 0 19px 29px 2px rgba(0, 0, 0, .14), 0 7px 36px 6px rgba(0, 0, 0, .12) }

.mat-elevation-z20 { box-shadow: 0 10px 13px -6px rgba(0, 0, 0, .2), 0 20px 31px 3px rgba(0, 0, 0, .14), 0 8px 38px 7px rgba(0, 0, 0, .12) }

.mat-elevation-z21 { box-shadow: 0 10px 13px -6px rgba(0, 0, 0, .2), 0 21px 33px 3px rgba(0, 0, 0, .14), 0 8px 40px 7px rgba(0, 0, 0, .12) }

.mat-elevation-z22 { box-shadow: 0 10px 14px -6px rgba(0, 0, 0, .2), 0 22px 35px 3px rgba(0, 0, 0, .14), 0 8px 42px 7px rgba(0, 0, 0, .12) }

.mat-elevation-z23 { box-shadow: 0 11px 14px -7px rgba(0, 0, 0, .2), 0 23px 36px 3px rgba(0, 0, 0, .14), 0 9px 44px 8px rgba(0, 0, 0, .12) }

.mat-elevation-z24 { box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12) }

.mat-h1, .mat-headline, .mat-typography h1 { font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif; margin: 0 0 16px }

.mat-h2, .mat-title, .mat-typography h2 { font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif; margin: 0 0 16px }

.mat-h3, .mat-subheading-2, .mat-typography h3 { font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif; margin: 0 0 16px }

.mat-h4, .mat-subheading-1, .mat-typography h4 { font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif; margin: 0 0 16px }

.mat-h5, .mat-typography h5 { font-size: 11.62px; font-weight: 400; font-family: Roboto, "Helvetica Neue", sans-serif; line-height: 20px; margin: 0 0 12px }

.mat-h6, .mat-typography h6 { font-size: 9.38px; font-weight: 400; font-family: Roboto, "Helvetica Neue", sans-serif; line-height: 20px; margin: 0 0 12px }

.mat-body-2, .mat-body-strong { font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif }

.mat-body, .mat-body-1, .mat-typography { font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif }

.mat-body p, .mat-body-1 p, .mat-typography p { margin: 0 0 12px }

.mat-caption, .mat-small { font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif }

.mat-display-4, .mat-typography .mat-display-4 { font: 300 112px/112px Roboto, "Helvetica Neue", sans-serif; margin: 0 0 56px; letter-spacing: -.05em }

.mat-display-3, .mat-typography .mat-display-3 { font: 400 56px/56px Roboto, "Helvetica Neue", sans-serif; margin: 0 0 64px; letter-spacing: -.02em }

.mat-display-2, .mat-typography .mat-display-2 { font: 400 45px/48px Roboto, "Helvetica Neue", sans-serif; margin: 0 0 64px; letter-spacing: -.005em }

.mat-display-1, .mat-typography .mat-display-1 { font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif; margin: 0 0 64px }

.mat-button, .mat-icon-button, .mat-raised-button { font-family: Roboto, "Helvetica Neue", sans-serif; font-size: 14px; font-weight: 500 }

.mat-button-toggle { font-family: Roboto, "Helvetica Neue", sans-serif }

.mat-card { font-family: Roboto, "Helvetica Neue", sans-serif }

.mat-card-title { font-size: 24px; font-weight: 400 }

.mat-card-content, .mat-card-header .mat-card-title, .mat-card-subtitle { font-size: 14px }

.mat-checkbox { font-family: Roboto, "Helvetica Neue", sans-serif }

.mat-checkbox-layout .mat-checkbox-label { line-height: 24px }

.mat-chip:not(.mat-basic-chip) { font-size: 13px; line-height: 16px }

.mat-header-cell { font-size: 12px; font-weight: 500 }

.mat-cell { font-size: 14px }

.mat-calendar { font-family: Roboto, "Helvetica Neue", sans-serif }

.mat-calendar-body { font-size: 13px }

.mat-calendar-body-label, .mat-calendar-period-button { font-size: 14px; font-weight: 500 }

.mat-calendar-table-header th { font-size: 11px; font-weight: 400 }

.mat-dialog-title { font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif }

.mat-grid-tile-footer, .mat-grid-tile-header { font-size: 14px }

.mat-grid-tile-footer .mat-line, .mat-grid-tile-header .mat-line { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block; box-sizing: border-box }

.mat-grid-tile-footer .mat-line:nth-child(n+2), .mat-grid-tile-header .mat-line:nth-child(n+2) { font-size: 12px }

.mat-input-container { font: 400 inherit/1.125 Roboto, "Helvetica Neue", sans-serif }

.mat-input-wrapper { padding-bottom: 1.29688em }

.mat-input-prefix .mat-datepicker-toggle, .mat-input-prefix .mat-icon, .mat-input-suffix .mat-datepicker-toggle, .mat-input-suffix .mat-icon { font-size: 150% }

.mat-input-prefix .mat-icon-button, .mat-input-suffix .mat-icon-button { height: 1.5em; width: 1.5em }

.mat-input-prefix .mat-icon-button .mat-icon, .mat-input-suffix .mat-icon-button .mat-icon { line-height: 1.5 }

.mat-input-infix { padding: .4375em 0; border-top: .84375em solid transparent }

.mat-input-element:-webkit-autofill + .mat-input-placeholder-wrapper .mat-float { transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.001px); -ms-transform: translateY(-1.28125em) scale(.75); width: 133.33333% }

.mat-input-placeholder-wrapper { top: -.84375em; padding-top: .84375em }

.mat-input-placeholder { top: 1.28125em }

.mat-focused .mat-input-placeholder.mat-float, .mat-input-placeholder.mat-float:not(.mat-empty) { transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.001px); -ms-transform: translateY(-1.28125em) scale(.75); width: 133.33333% }

.mat-input-underline { bottom: 1.29688em }

.mat-input-subscript-wrapper { font-size: 75%; margin-top: .60417em; top: calc(100% - 1.72917em) }

.mat-menu-item { font-family: Roboto, "Helvetica Neue", sans-serif; font-size: 16px }

.mat-paginator { font-family: Roboto, "Helvetica Neue", sans-serif; font-size: 12px }

.mat-paginator-page-size .mat-select-trigger { font-family: Roboto, "Helvetica Neue", sans-serif; font-size: 12px }

.mat-radio-button { font-family: Roboto, "Helvetica Neue", sans-serif }

.mat-select { font-family: Roboto, "Helvetica Neue", sans-serif }

.mat-select-trigger { font-size: 16px }

.mat-slide-toggle-content { font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif }

.mat-slider-thumb-label-text { font-size: 12px; font-weight: 500 }

.mat-tab-group { font-family: Roboto, "Helvetica Neue", sans-serif }

.mat-tab-label, .mat-tab-link { font-family: Roboto, "Helvetica Neue", sans-serif; font-size: 14px; font-weight: 500 }

.mat-toolbar, .mat-toolbar h1, .mat-toolbar h2, .mat-toolbar h3, .mat-toolbar h4, .mat-toolbar h5, .mat-toolbar h6 { font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif; margin: 0 }

.mat-tooltip { font-family: Roboto, "Helvetica Neue", sans-serif; font-size: 10px; padding-top: 6px; padding-bottom: 6px }

.mat-list-item { font-family: Roboto, "Helvetica Neue", sans-serif }

.mat-list .mat-list-item, .mat-nav-list .mat-list-item { font-size: 16px }

.mat-list .mat-list-item .mat-line, .mat-nav-list .mat-list-item .mat-line { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block; box-sizing: border-box }

.mat-list .mat-list-item .mat-line:nth-child(n+2), .mat-nav-list .mat-list-item .mat-line:nth-child(n+2) { font-size: 14px }

.mat-list .mat-subheader, .mat-nav-list .mat-subheader { font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif }

.mat-list[dense] .mat-list-item, .mat-nav-list[dense] .mat-list-item { font-size: 12px }

.mat-list[dense] .mat-list-item .mat-line, .mat-nav-list[dense] .mat-list-item .mat-line { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block; box-sizing: border-box }

.mat-list[dense] .mat-list-item .mat-line:nth-child(n+2), .mat-nav-list[dense] .mat-list-item .mat-line:nth-child(n+2) { font-size: 12px }

.mat-list[dense] .mat-subheader, .mat-nav-list[dense] .mat-subheader { font: 500 12px Roboto, "Helvetica Neue", sans-serif }

.mat-option { font-family: Roboto, "Helvetica Neue", sans-serif; font-size: 16px }

.mat-optgroup-label { font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif }

.mat-simple-snackbar { font-family: Roboto, "Helvetica Neue", sans-serif; font-size: 14px }

.mat-simple-snackbar-action { line-height: 1; font-family: inherit; font-size: inherit; font-weight: 500 }

.mat-ripple { overflow: hidden }

.mat-ripple.mat-ripple-unbounded { overflow: visible }

.mat-ripple-element { position: absolute; border-radius: 50%; pointer-events: none; transition: opacity, transform 0s cubic-bezier(0, 0, .2, 1); transform: scale(0) }

.mat-option { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block; line-height: 48px; height: 48px; padding: 0 16px; text-align: left; text-decoration: none; position: relative; cursor: pointer; outline: 0 }

.mat-option[disabled] { cursor: default }

[dir=rtl] .mat-option { text-align: right }

.mat-option .mat-icon { margin-right: 16px }

[dir=rtl] .mat-option .mat-icon { margin-left: 16px; margin-right: 0 }

.mat-option[aria-disabled=true] { -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; cursor: default }

.mat-optgroup .mat-option:not(.mat-option-multiple) { padding-left: 32px }

[dir=rtl] .mat-optgroup .mat-option:not(.mat-option-multiple) { padding-left: 16px; padding-right: 32px }

.mat-option-ripple { position: absolute; top: 0; left: 0; bottom: 0; right: 0; pointer-events: none }

@media screen and (-ms-high-contrast: active) {
    .mat-option-ripple { opacity: .5 }
}

.mat-option-pseudo-checkbox { margin-right: 8px }

[dir=rtl] .mat-option-pseudo-checkbox { margin-left: 8px; margin-right: 0 }

.mat-optgroup-label { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block; line-height: 48px; height: 48px; padding: 0 16px; text-align: left; text-decoration: none; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; cursor: default }

.mat-optgroup-label[disabled] { cursor: default }

[dir=rtl] .mat-optgroup-label { text-align: right }

.mat-optgroup-label .mat-icon { margin-right: 16px }

[dir=rtl] .mat-optgroup-label .mat-icon { margin-left: 16px; margin-right: 0 }

.cdk-visually-hidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; text-transform: none; width: 1px }

.cdk-global-overlay-wrapper, .cdk-overlay-container { pointer-events: none; top: 0; left: 0; height: 100%; width: 100% }

.cdk-overlay-container { position: fixed; z-index: 1000 }

.cdk-global-overlay-wrapper { display: flex; position: absolute; z-index: 1000 }

.cdk-overlay-pane { position: absolute; pointer-events: auto; box-sizing: border-box; z-index: 1000 }

.cdk-overlay-backdrop { position: absolute; top: 0; bottom: 0; left: 0; right: 0; z-index: 1000; pointer-events: auto; -webkit-tap-highlight-color: transparent; transition: opacity .4s cubic-bezier(.25, .8, .25, 1); opacity: 0 }

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing { opacity: .48 }

.cdk-overlay-dark-backdrop { background: rgba(0, 0, 0, .6) }

.cdk-overlay-transparent-backdrop { background: 0 0 }

.cdk-global-scrollblock { position: fixed; width: 100%; overflow-y: scroll }

.mat-ripple-element { background-color: rgba(0, 0, 0, .1) }

.mat-option { color: rgba(0, 0, 0, .87) }

.mat-option:focus:not(.mat-option-disabled), .mat-option:hover:not(.mat-option-disabled) { background: rgba(0, 0, 0, .04) }

.mat-option.mat-selected.mat-primary, .mat-primary .mat-option.mat-selected { color: #3F51B5 }

.mat-accent .mat-option.mat-selected, .mat-option.mat-selected.mat-accent { color: #FF4081 }

.mat-option.mat-selected.mat-warn, .mat-warn .mat-option.mat-selected { color: #F44336 }

.mat-option.mat-selected:not(.mat-option-multiple) { background: rgba(0, 0, 0, .04) }

.mat-option.mat-active { background: rgba(0, 0, 0, .04); color: rgba(0, 0, 0, .87) }

.mat-option.mat-option-disabled { color: rgba(0, 0, 0, .38) }

.mat-optgroup-label { color: rgba(0, 0, 0, .54) }

.mat-optgroup-disabled .mat-optgroup-label { color: rgba(0, 0, 0, .38) }

.mat-pseudo-checkbox { color: rgba(0, 0, 0, .54) }

.mat-pseudo-checkbox::after { color: #FAFAFA }

.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate, .mat-pseudo-checkbox-checked.mat-primary, .mat-pseudo-checkbox-indeterminate.mat-primary { background: #3F51B5 }

.mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate, .mat-pseudo-checkbox-checked.mat-accent, .mat-pseudo-checkbox-indeterminate.mat-accent { background: #FF4081 }

.mat-pseudo-checkbox-checked.mat-warn, .mat-pseudo-checkbox-indeterminate.mat-warn, .mat-warn .mat-pseudo-checkbox-checked, .mat-warn .mat-pseudo-checkbox-indeterminate { background: #F44336 }

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled, .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled { background: #B0B0B0 }

.mat-app-background { background-color: #FAFAFA }

.mat-theme-loaded-marker { display: none }

.mat-autocomplete-panel { background: #FFF; color: rgba(0, 0, 0, .87) }

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) { background: #FFF; color: rgba(0, 0, 0, .87) }

.mat-button, .mat-icon-button { background: 0 0 }

.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay { background-color: rgba(63, 81, 181, .12) }

.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay { background-color: rgba(255, 64, 129, .12) }

.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay { background-color: rgba(244, 67, 54, .12) }

.mat-button[disabled] .mat-button-focus-overlay, .mat-icon-button[disabled] .mat-button-focus-overlay { background-color: transparent }

.mat-button.mat-primary, .mat-icon-button.mat-primary { color: #3F51B5 }

.mat-button.mat-accent, .mat-icon-button.mat-accent { color: #FF4081 }

.mat-button.mat-warn, .mat-icon-button.mat-warn { color: #F44336 }

.mat-button.mat-accent[disabled], .mat-button.mat-primary[disabled], .mat-button.mat-warn[disabled], .mat-button[disabled][disabled], .mat-icon-button.mat-accent[disabled], .mat-icon-button.mat-primary[disabled], .mat-icon-button.mat-warn[disabled], .mat-icon-button[disabled][disabled] { color: rgba(0, 0, 0, .38) }

.mat-fab, .mat-mini-fab, .mat-raised-button { color: rgba(0, 0, 0, .87); background-color: #FFF }

.mat-fab.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary { color: rgba(255, 255, 255, .87) }

.mat-fab.mat-accent, .mat-mini-fab.mat-accent, .mat-raised-button.mat-accent { color: #FFF }

.mat-fab.mat-warn, .mat-mini-fab.mat-warn, .mat-raised-button.mat-warn { color: #FFF }

.mat-fab.mat-accent[disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled] { color: rgba(0, 0, 0, .38) }

.mat-fab.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary { background-color: #3F51B5 }

.mat-fab.mat-accent, .mat-mini-fab.mat-accent, .mat-raised-button.mat-accent { background-color: #FF4081 }

.mat-fab.mat-warn, .mat-mini-fab.mat-warn, .mat-raised-button.mat-warn { background-color: #F44336 }

.mat-fab.mat-accent[disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled] { background-color: rgba(0, 0, 0, .12) }

.mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element { background-color: rgba(255, 255, 255, .2) }

.mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element { background-color: rgba(255, 255, 255, .2) }

.mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element { background-color: rgba(255, 255, 255, .2) }

.mat-button.mat-primary .mat-ripple-element { background-color: rgba(63, 81, 181, .1) }

.mat-button.mat-accent .mat-ripple-element { background-color: rgba(255, 64, 129, .1) }

.mat-button.mat-warn .mat-ripple-element { background-color: rgba(244, 67, 54, .1) }

.mat-icon-button.mat-primary .mat-ripple-element { background-color: rgba(63, 81, 181, .2) }

.mat-icon-button.mat-accent .mat-ripple-element { background-color: rgba(255, 64, 129, .2) }

.mat-icon-button.mat-warn .mat-ripple-element { background-color: rgba(244, 67, 54, .2) }

.mat-button-toggle { color: rgba(0, 0, 0, .38) }

.mat-button-toggle.cdk-focused .mat-button-toggle-focus-overlay { background-color: rgba(0, 0, 0, .06) }

.mat-button-toggle-checked { background-color: #E0E0E0; color: #000 }

.mat-button-toggle-disabled { background-color: #EEE; color: rgba(0, 0, 0, .38) }

.mat-button-toggle-disabled.mat-button-toggle-checked { background-color: #BDBDBD }

.mat-card { background: #FFF; color: rgba(0, 0, 0, .87) }

.mat-card-subtitle { color: rgba(0, 0, 0, .54) }

.mat-checkbox-frame { border-color: rgba(0, 0, 0, .54) }

.mat-checkbox-checkmark { fill: #FAFAFA }

.mat-checkbox-checkmark-path { stroke: #FAFAFA !important }

.mat-checkbox-mixedmark { background-color: #FAFAFA }

.mat-checkbox-checked.mat-primary .mat-checkbox-background, .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background { background-color: #3F51B5 }

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background { background-color: #FF4081 }

.mat-checkbox-checked.mat-warn .mat-checkbox-background, .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background { background-color: #F44336 }

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background { background-color: #B0B0B0 }

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame { border-color: #B0B0B0 }

.mat-checkbox-disabled .mat-checkbox-label { color: #B0B0B0 }

.mat-checkbox:not(.mat-checkbox-disabled).mat-primary .mat-checkbox-ripple .mat-ripple-element { background-color: rgba(63, 81, 181, .26) }

.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element { background-color: rgba(255, 64, 129, .26) }

.mat-checkbox:not(.mat-checkbox-disabled).mat-warn .mat-checkbox-ripple .mat-ripple-element { background-color: rgba(244, 67, 54, .26) }

.mat-chip:not(.mat-basic-chip) { background-color: #E0E0E0; color: rgba(0, 0, 0, .87) }

.mat-chip.mat-chip-selected:not(.mat-basic-chip) { background-color: grey; color: rgba(255, 255, 255, .87) }

.mat-chip.mat-chip-selected:not(.mat-basic-chip).mat-primary { background-color: #3F51B5; color: rgba(255, 255, 255, .87) }

.mat-chip.mat-chip-selected:not(.mat-basic-chip).mat-accent { background-color: #FF4081; color: #FFF }

.mat-chip.mat-chip-selected:not(.mat-basic-chip).mat-warn { background-color: #F44336; color: #FFF }

.mat-table { background: #FFF }

.mat-header-row, .mat-row { border-bottom-color: rgba(0, 0, 0, .12) }

.mat-header-cell { color: rgba(0, 0, 0, .54) }

.mat-cell { color: rgba(0, 0, 0, .87) }

.mat-datepicker-content { background-color: #FFF; color: rgba(0, 0, 0, .87) }

.mat-calendar-arrow { border-top-color: rgba(0, 0, 0, .54) }

.mat-calendar-next-button, .mat-calendar-previous-button { color: rgba(0, 0, 0, .54) }

.mat-calendar-table-header { color: rgba(0, 0, 0, .38) }

.mat-calendar-table-header-divider::after { background: rgba(0, 0, 0, .12) }

.mat-calendar-body-label { color: rgba(0, 0, 0, .54) }

.mat-calendar-body-cell-content { color: rgba(0, 0, 0, .87); border-color: transparent }

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) { color: rgba(0, 0, 0, .38) }

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected), :not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) { background-color: rgba(0, 0, 0, .04) }

.mat-calendar-body-selected { background-color: #3F51B5; color: rgba(255, 255, 255, .87) }

.mat-calendar-body-disabled > .mat-calendar-body-selected { background-color: rgba(63, 81, 181, .4) }

.mat-calendar-body-today:not(.mat-calendar-body-selected) { border-color: rgba(0, 0, 0, .38) }

.mat-calendar-body-today.mat-calendar-body-selected { box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .87) }

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) { border-color: rgba(0, 0, 0, .18) }

.mat-dialog-container { background: #FFF; color: rgba(0, 0, 0, .87) }

.mat-expansion-panel { background: #FFF; color: #000 }

.mat-action-row { border-top-color: rgba(0, 0, 0, .12) }

.mat-expansion-panel-header:focus, .mat-expansion-panel-header:hover { background: rgba(0, 0, 0, .04) }

.mat-expansion-panel-header-title { color: rgba(0, 0, 0, .87) }

.mat-expansion-panel-header-description { color: rgba(0, 0, 0, .54) }

.mat-expansion-indicator::after { color: rgba(0, 0, 0, .54) }

.mat-icon.mat-primary { color: #3F51B5 }

.mat-icon.mat-accent { color: #FF4081 }

.mat-icon.mat-warn { color: #F44336 }

.mat-input-placeholder { color: rgba(0, 0, 0, .38) }

.mat-focused .mat-input-placeholder { color: #3F51B5 }

.mat-focused .mat-input-placeholder.mat-accent { color: #FF4081 }

.mat-focused .mat-input-placeholder.mat-warn { color: #F44336 }

.mat-input-element:disabled { color: rgba(0, 0, 0, .38) }

.mat-focused .mat-input-placeholder.mat-float .mat-placeholder-required, input.mat-input-element:-webkit-autofill + .mat-input-placeholder .mat-placeholder-required { color: #FF4081 }

.mat-input-underline { background-color: rgba(0, 0, 0, .12) }

.mat-input-ripple { background-color: #3F51B5 }

.mat-input-ripple.mat-accent { background-color: #FF4081 }

.mat-input-ripple.mat-warn { background-color: #F44336 }

.mat-input-invalid .mat-input-placeholder { color: #F44336 }

.mat-input-invalid .mat-input-placeholder.mat-accent, .mat-input-invalid .mat-input-placeholder.mat-float .mat-placeholder-required { color: #F44336 }

.mat-input-invalid .mat-input-ripple { background-color: #F44336 }

.mat-input-error { color: #F44336 }

.mat-list .mat-list-item, .mat-nav-list .mat-list-item { color: rgba(0, 0, 0, .87) }

.mat-list .mat-subheader, .mat-nav-list .mat-subheader { color: rgba(0, 0, 0, .54) }

.mat-divider { border-top-color: rgba(0, 0, 0, .12) }

.mat-nav-list .mat-list-item { outline: 0 }

.mat-nav-list .mat-list-item.mat-list-item-focus, .mat-nav-list .mat-list-item:hover { background: rgba(0, 0, 0, .04) }

.mat-menu-content { background: #FFF }

.mat-menu-item { background: 0 0; color: rgba(0, 0, 0, .87) }

.mat-menu-item[disabled] { color: rgba(0, 0, 0, .38) }

.mat-menu-item .mat-icon { color: rgba(0, 0, 0, .54); vertical-align: middle }

.mat-menu-item:focus:not([disabled]), .mat-menu-item:hover:not([disabled]) { background: rgba(0, 0, 0, .04) }

.mat-paginator { background: #FFF }

.mat-paginator, .mat-paginator-page-size .mat-select-trigger { color: rgba(0, 0, 0, .54) }

.mat-paginator-decrement, .mat-paginator-increment { border-top: 2px solid rgba(0, 0, 0, .54); border-right: 2px solid rgba(0, 0, 0, .54) }

.mat-icon-button[disabled] .mat-paginator-decrement, .mat-icon-button[disabled] .mat-paginator-increment { border-color: rgba(0, 0, 0, .38) }

.mat-progress-bar-background { background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20version%3D%271.1%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20xmlns%3Axlink%3D%27http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%27%20x%3D%270px%27%20y%3D%270px%27%20enable-background%3D%27new%200%200%205%202%27%20xml%3Aspace%3D%27preserve%27%20viewBox%3D%270%200%205%202%27%20preserveAspectRatio%3D%27none%20slice%27%3E%3Ccircle%20cx%3D%271%27%20cy%3D%271%27%20r%3D%271%27%20fill%3D%27%23c5cae9%27%2F%3E%3C%2Fsvg%3E") }

.mat-progress-bar-buffer { background-color: #C5CAE9 }

.mat-progress-bar-fill::after { background-color: #3F51B5 }

.mat-progress-bar.mat-accent .mat-progress-bar-background { background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20version%3D%271.1%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20xmlns%3Axlink%3D%27http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%27%20x%3D%270px%27%20y%3D%270px%27%20enable-background%3D%27new%200%200%205%202%27%20xml%3Aspace%3D%27preserve%27%20viewBox%3D%270%200%205%202%27%20preserveAspectRatio%3D%27none%20slice%27%3E%3Ccircle%20cx%3D%271%27%20cy%3D%271%27%20r%3D%271%27%20fill%3D%27%23ff80ab%27%2F%3E%3C%2Fsvg%3E") }

.mat-progress-bar.mat-accent .mat-progress-bar-buffer { background-color: #FF80AB }

.mat-progress-bar.mat-accent .mat-progress-bar-fill::after { background-color: #FF4081 }

.mat-progress-bar.mat-warn .mat-progress-bar-background { background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20version%3D%271.1%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20xmlns%3Axlink%3D%27http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%27%20x%3D%270px%27%20y%3D%270px%27%20enable-background%3D%27new%200%200%205%202%27%20xml%3Aspace%3D%27preserve%27%20viewBox%3D%270%200%205%202%27%20preserveAspectRatio%3D%27none%20slice%27%3E%3Ccircle%20cx%3D%271%27%20cy%3D%271%27%20r%3D%271%27%20fill%3D%27%23ffcdd2%27%2F%3E%3C%2Fsvg%3E") }

.mat-progress-bar.mat-warn .mat-progress-bar-buffer { background-color: #FFCDD2 }

.mat-progress-bar.mat-warn .mat-progress-bar-fill::after { background-color: #F44336 }

.mat-progress-spinner path, .mat-spinner path { stroke: #3F51B5 }

.mat-progress-spinner.mat-accent path, .mat-spinner.mat-accent path { stroke: #FF4081 }

.mat-progress-spinner.mat-warn path, .mat-spinner.mat-warn path { stroke: #F44336 }

.mat-radio-outer-circle { border-color: rgba(0, 0, 0, .54) }

.mat-radio-disabled .mat-radio-outer-circle { border-color: rgba(0, 0, 0, .38) }

.mat-radio-disabled .mat-radio-inner-circle, .mat-radio-disabled .mat-radio-ripple .mat-ripple-element { background-color: rgba(0, 0, 0, .38) }

.mat-radio-disabled .mat-radio-label-content { color: rgba(0, 0, 0, .38) }

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle { border-color: #3F51B5 }

.mat-radio-button.mat-primary .mat-radio-inner-circle { background-color: #3F51B5 }

.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element { background-color: rgba(63, 81, 181, .26) }

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle { border-color: #FF4081 }

.mat-radio-button.mat-accent .mat-radio-inner-circle { background-color: #FF4081 }

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element { background-color: rgba(255, 64, 129, .26) }

.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle { border-color: #F44336 }

.mat-radio-button.mat-warn .mat-radio-inner-circle { background-color: #F44336 }

.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element { background-color: rgba(244, 67, 54, .26) }

.mat-select-arrow, .mat-select-trigger { color: rgba(0, 0, 0, .38) }

.mat-select-underline { background-color: rgba(0, 0, 0, .12) }

.mat-select-arrow, .mat-select-disabled .mat-select-value, .mat-select-trigger { color: rgba(0, 0, 0, .38) }

.mat-select-content, .mat-select-panel-done-animating { background: #FFF }

.mat-select-value { color: rgba(0, 0, 0, .87) }

.mat-select:focus:not(.mat-select-disabled).mat-primary .mat-select-arrow, .mat-select:focus:not(.mat-select-disabled).mat-primary .mat-select-trigger { color: #3F51B5 }

.mat-select:focus:not(.mat-select-disabled).mat-primary .mat-select-underline { background-color: #3F51B5 }

.mat-select:focus:not(.mat-select-disabled).mat-accent .mat-select-arrow, .mat-select:focus:not(.mat-select-disabled).mat-accent .mat-select-trigger { color: #FF4081 }

.mat-select:focus:not(.mat-select-disabled).mat-accent .mat-select-underline { background-color: #FF4081 }

.mat-select:focus:not(.mat-select-disabled).mat-warn .mat-select-arrow, .mat-select:focus:not(.mat-select-disabled).mat-warn .mat-select-trigger, .mat-select:not(:focus).ng-invalid.ng-touched:not(.mat-select-disabled) .mat-select-arrow, .mat-select:not(:focus).ng-invalid.ng-touched:not(.mat-select-disabled) .mat-select-trigger { color: #F44336 }

.mat-select:focus:not(.mat-select-disabled).mat-warn .mat-select-underline, .mat-select:not(:focus).ng-invalid.ng-touched:not(.mat-select-disabled) .mat-select-underline { background-color: #F44336 }

.mat-sidenav-container { background-color: #FAFAFA; color: rgba(0, 0, 0, .87) }

.mat-sidenav { background-color: #FFF; color: rgba(0, 0, 0, .87) }

.mat-sidenav.mat-sidenav-push { background-color: #FFF }

.mat-sidenav-backdrop.mat-sidenav-shown { background-color: rgba(0, 0, 0, .6) }

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb { background-color: #E91E63 }

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar { background-color: rgba(233, 30, 99, .5) }

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element { background-color: rgba(0, 0, 0, .06) }

.mat-slide-toggle .mat-ripple-element { background-color: rgba(233, 30, 99, .12) }

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb { background-color: #3F51B5 }

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar { background-color: rgba(63, 81, 181, .5) }

.mat-slide-toggle.mat-primary:not(.mat-checked) .mat-ripple-element { background-color: rgba(0, 0, 0, .06) }

.mat-slide-toggle.mat-primary .mat-ripple-element { background-color: rgba(63, 81, 181, .12) }

.mat-slide-toggle.mat-warn.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb { background-color: #F44336 }

.mat-slide-toggle.mat-warn.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar { background-color: rgba(244, 67, 54, .5) }

.mat-slide-toggle.mat-warn:not(.mat-checked) .mat-ripple-element { background-color: rgba(0, 0, 0, .06) }

.mat-slide-toggle.mat-warn .mat-ripple-element { background-color: rgba(244, 67, 54, .12) }

.mat-disabled .mat-slide-toggle-thumb { background-color: #BDBDBD }

.mat-disabled .mat-slide-toggle-bar { background-color: rgba(0, 0, 0, .1) }

.mat-slide-toggle-thumb { background-color: #FAFAFA }

.mat-slide-toggle-bar { background-color: rgba(0, 0, 0, .38) }

.mat-slider-track-background { background-color: rgba(0, 0, 0, .26) }

.mat-primary .mat-slider-thumb, .mat-primary .mat-slider-thumb-label, .mat-primary .mat-slider-track-fill { background-color: var(--primary-lighter) }

.mat-primary .mat-slider-thumb-label-text { color: rgba(255, 255, 255, .87) }

.mat-accent .mat-slider-thumb, .mat-accent .mat-slider-thumb-label, .mat-accent .mat-slider-track-fill { background-color:  var(--primary-lighter) }

.mat-accent .mat-slider-thumb-label-text { color: #FFF }

.mat-warn .mat-slider-thumb, .mat-warn .mat-slider-thumb-label, .mat-warn .mat-slider-track-fill { background-color: #F44336 }

.mat-warn .mat-slider-thumb-label-text { color: #FFF }

.mat-slider-focus-ring { background-color: rgba(255, 64, 129, .2) }

.cdk-focused .mat-slider-track-background, .mat-slider:hover .mat-slider-track-background { background-color: rgba(0, 0, 0, .38) }

.mat-slider-disabled .mat-slider-thumb, .mat-slider-disabled .mat-slider-track-background, .mat-slider-disabled .mat-slider-track-fill { background-color: rgba(0, 0, 0, .26) }

.mat-slider-disabled:hover .mat-slider-track-background { background-color: rgba(0, 0, 0, .26) }

.mat-slider-min-value .mat-slider-focus-ring { background-color: rgba(0, 0, 0, .12) }

.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb, .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label { background-color: #000 }

.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb, .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label { background-color: rgba(0, 0, 0, .26) }

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb { border-color: rgba(0, 0, 0, .26); background-color: transparent }

.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb { border-color: rgba(0, 0, 0, .38) }

.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb { border-color: rgba(0, 0, 0, .26) }

.mat-slider-has-ticks .mat-slider-wrapper::after { border-color: rgba(0, 0, 0, .7) }

.mat-slider-horizontal .mat-slider-ticks { background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7) 2px, transparent 0, transparent); background-image: -moz-repeating-linear-gradient(.0001deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7) 2px, transparent 0, transparent) }

.mat-slider-vertical .mat-slider-ticks { background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7) 2px, transparent 0, transparent) }

.mat-tab-header, .mat-tab-nav-bar { border-bottom: 1px solid rgba(0, 0, 0, .12) }

.mat-tab-group-inverted-header .mat-tab-header, .mat-tab-group-inverted-header .mat-tab-nav-bar { border-top: 1px solid rgba(0, 0, 0, .12); border-bottom: none }

.mat-tab-label:focus { background-color: rgba(197, 202, 233, .3) }

.mat-ink-bar { background-color: #3F51B5 }

.mat-tab-label, .mat-tab-link { color: rgba(0, 0, 0, .87) }

.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled { color: rgba(0, 0, 0, .38) }

.mat-toolbar { background: #F5F5F5; color: rgba(0, 0, 0, .87) }

.mat-toolbar.mat-primary { background: #3F51B5; color: rgba(255, 255, 255, .87) }

.mat-toolbar.mat-accent { background: #FF4081; color: #FFF }

.mat-toolbar.mat-warn { background: #F44336; color: #FFF }

.mat-tooltip { background: rgba(97, 97, 97, .9) }
