.giacom-manage-hosted-exchange-address-box {
    margin-top: 45px;
    margin-bottom: 45px;

    .giacom-user-manage-access__panel {
        margin-top: 10px;
    }
}

.giacom-edit-address-book {
    &.giacom-popup .modal-dialog.customise {
        width: 620px;
    }

    &__form {
        .address-box {
            max-height: 450px;
            overflow-y: auto;
            overflow-x: hidden;
        }

        &.form-horizontal {
            .form-group {
                margin-right: -5px;
            }
        }
    }

    .giacom-form {
        .form-control {
            @include Gotham-400;
            font-size: 16px;
        }

        .button-group {
            margin-top: 0px;
        }
    }
}
