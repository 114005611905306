@import '../../../assets/css/variables/index.scss';

.giacom-dashboard {
    background-color: $giacom-blue1;
    @media screen and (min-width: 768px) and (min-height: 1295px) and (orientation: landscape),
    screen and (width: 1920px) and (height: 1080px),
    screen and (width: 1280px) and (height: 1024px) {
        //position: fixed;
        min-height: 100%;
    }

    &.giacom-dashboard-fixed {
        //position: fixed;
        min-height: 100%;
    }

    .customer-user {
        height: 667px;
        width: 100%;

        .button-bottom {
            bottom: 20px;
        }

        .button-group {
            margin-bottom: 30px;
        }

        ul.list {
            @include giacom-panel-list;
        }
    }

    .giacom-panel-item {
        &.row {
            margin-left: 0;
            margin-right: 0;
        }

        .col-lg-6,
        .col-md-6,
        .col-sm-6 {
            &:not(.customer-user-list) {
                padding: 0 8px;
            }
        }

        .customer-user-list {
            padding-left: 0;
        }
    }

    .customized-display {
        &.giacom-panel-item {
            div.col-md-6 {
                padding: 0;
            }
        }

        .giacom-panel.support {
            width: 96%;
        }
    }
}

.giacom-dashboard-title {
    .row {
        margin: 20px 0;
    }

    .welcome-message {
        padding-left: 0;

        h2 {
            font-size: 22px;
            @include Gotham-400;
            color: $giacom-white;
            margin: 0;
            margin-top: 5px;
            cursor: pointer;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            line-height: 32px;
        }
    }

    .col-md-8 {
        padding-left: 0;
        padding-right: 0;
    }

    ul.network-status {
        height: 40px;
        list-style: none;
        border: 1px solid;
        padding: 8px 10px;
        margin-bottom: 0;

        li {
            display: inline-block;
            color: $giacom-white;
            font-size: 16px;
            @include Gotham-400;

            &.icon {
                .fas {
                    font-size: 25px;
                }
            }

            &.status {
                .text {
                    position: relative;
                    top: -3px;
                }

                .text-bold {
                    font-weight: 600;
                }
            }

            &.view-detail {
                float: right;
                font-size: 16px;
                @include Gotham-400;
                color: $giacom-white;

                a {
                    font-size: inherit;
                    color: inherit;
                }

                .fa-play {
                    font-size: 10px;
                    vertical-align: middle;
                    margin-top: -2px;
                    margin-left: 5px;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .giacom-dashboard-title {
        ul.network-status {
            height: 60px;

            li {
                &.status {
                    .text {
                        top: 0;
                    }

                    span {
                        display: block;
                    }
                }

                &.icon,
                &.view-detail {
                    position: relative;
                }

                &.icon {
                    top: -5px;
                }

                &.view-detail {
                    top: 10px;
                }
            }
        }
    }
    .giacom-panel-item {
        .customer-user-list {
            padding-right: 0;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .giacom-dashboard {
        .giacom-panel-item {
            display: flex;
            justify-content: center;
            align-content: center; // width: 100%;
            .customer-user-list {
                order: 1;
                width: 50%;
            }

            .panels {
                order: 2;
                width: 50%;
                display: flex;
                flex-direction: column;
            }

            .col-xs-12.col-md-6 {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .giacom-dashboard-title {
        .welcome-message {
            padding-bottom: 20px;
        }
    }
}

@media screen and (max-width: 1023px) {
    .giacom-dashboard {
        &.container-fluid {
            padding: 0;
        }
    }
}
