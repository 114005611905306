.giacom-end-user-reset-password {
    .giacom-form {
        @include Gotham-400;
        color: $giacom-grey2;
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;

        &__item {
            &:first-child {
                margin-top: 10px;
            }

            display: flex;
            margin-bottom: 15px;

            span.name {
                margin-left: 10px;
                @include Gotham-400;
            }
        }
    }

    .text-bold {
        @include Gotham-600;
        columns: inherit;
    }

    &__note {
        @include Gotham-400;
        columns: inherit;
    }

    &__generated-password {
        font-size: 25px;
        color: $giacom-grey2;
        @include Gotham-600;
    }
}
