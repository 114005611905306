.giacom-datetime {
    input {
        height: 40px;
    }
    .input-group-addon {
        background-color: $giacom-blue1;
        .glyphicon-calendar,
        .fa-calendar {
            color: $giacom-white;
            font-size: 21px;
        }
    }
}
