.hosted_voice {
    &.form-content {
        .form-title {
            .step-title {
                margin-bottom: 20px;
            }
        }
    }

    &__admin {
        margin-bottom: 20px;

        &__headline {
            display: inline-block;
            width: 100%;
            margin-bottom: 15px;

            &__title {
                @include Gotham-600;
                font-size: 16px;
                color: $giacom-grey2;
            }

            &__checkbox {
                display: inline-flex;

                .message {
                    margin-left: 10px;
                }

            }
        }
    }

    &__tooltip {
        position: relative;
        float: right;
    }

    &__input {
        margin-bottom: 15px;

        &__title {
            display: flex;
            height: 40px;
            justify-content: center;
            align-items: center;
            @include Gotham-400;
            color: $giacom-grey2;
            font-size: 16px;

            span {
                margin-left: auto;
            }
        }

        &__value {
            display: inline-flex;

            .bitdefender__tooltip {
                align-self: center;
            }

            &.display-name {
                input {
                    width: 92.5%;
                }
            }
        }
    }

    &.service-select-product {
        .select-product {
            .product {
                .name {
                    @include Gotham-400;
                }
            }
        }
    }
}
