.panel-theme-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid $giacom-border-input;
  padding-bottom: 20px;
}

.panel-theme {
  width: 205px;
  padding: 10px 20px 20px;
  border: 1px solid $giacom-grey5;
  margin: 10px;

  &.custom-theme {
    width: 400px;

    input[type='color'] {
      margin-left: 10px;
      margin-bottom: 10px;
      width: 140px;
      height: 40px;
      vertical-align: middle;
    }
  }

  &.default-theme {
    span {
      &:nth-child(2) {
        background-color: $giacom-pink1;
      }

      &:nth-child(3) {
        background-color: $giacom-blue1;
      }
    }
  }

  &.selected {
    outline: 5px solid $giacom-grey5;
    background-color: $giacom-grey4;
  }

  &:hover {
    background-color: $giacom-grey4;
  }
}

.btn-picked-color {
  width: 100%;
  text-align: center;

  + .btn-picked-color {
    margin-top: 10px;
  }

  padding: 10px 20px;
  background-color: $giacom-blue3;
  display: inline-block;
  color: $giacom-white;
}

.theme-name {
  text-align: center;
  font-weight: 600;
  margin-bottom: 10px;
}

.image-margin-setting {
  border-bottom: 1px solid $giacom-border-input;
  padding-bottom: 20px;

  .g-row {
    margin-bottom: 10px;
  }

  .glabel {
    display: inline-block;
    width: 38%;
    text-align: right;
    font-weight: 600;
    margin-bottom: 10px;
  }

  input {
    width: 58%;
    display: inline-block;
    margin-left: 10px;
    max-width: 300px;
    margin-right: 10px;
    text-align: right;
  }

  @media screen and (max-width: 767px) {
    .glabel,
    input {
      width: 100%;
      text-align: left;
    }
    input {
      margin-left: 0;
    }
  }
}

.input-color {
  position: relative;
  text-align: right;

  span {
    position: absolute;
    right: 40px;
    top: 9px;
    color: #FFFFFF;
    font-size: 15px;
    cursor: pointer;
  }

  .color-picker {
    cursor: pointer;
  }
}

.section-title {
  font-weight: 600;
  font-size: 18px;
}

.w3c {
  color: $giacom-blue1;
}

.reset-checkbox {
  margin-top: 15px;

  .giacom-checkbox {
    display: inline-block;
    margin-right: 10px;
  }
}

.giacom-image-upload {
  position: relative;
  border: 1px solid $giacom-border-input;
  min-height: 170px;

  &.image-selected {
    .drag-photo-here,
    .p-fileupload-buttonbar {
      display: none;
    }
  }

  .drag-photo-here {
    position: absolute;
    top: 15px;
    text-align: center;
    width: 100%;
    display: inline-block;

    .or {
      margin-top: 10px;
      text-align: center;
    }
  }

  .p-messages-summary,
  .p-messages-detail {
    color: $giacom-pink1;
  }

  .p-messages-error {
    .p-messages-icon {
      display: none;
    }

    ul {
      list-style: none;
    }
  }

  .p-fileupload {
    //padding-bottom: 15px;
  }

  .p-fileupload-content {
    text-align: center;
    min-height: 100px;
    position: absolute;
    width: 100%;
    top: 0;
  }

  .p-fileupload-buttonbar {
    text-align: center;
    margin-top: 15px;

    .p-fileupload-choose {
      display: block;
      width: 215px;
      position: relative;
      margin: 100px 8px 0 auto;
      overflow: hidden;
      border-style: solid;
      border-width: 1px;
      // border: 1px solid $giacom-blue1;
      // color: $giacom-blue1;
      padding: 8px 0;

      input[type='file'] {
        position: absolute;
        top: 0;
        right: 0;
        min-height: 100%;
        font-size: 100px;
        opacity: 0;
        cursor: pointer;
      }

      .fa-plus {
        display: none;
      }
    }
  }

  .p-fileupload-row {
    margin-top: 10px;

    .p-button-text {
      display: none;
    }

    div:nth-child(2),
    div:nth-child(3) {
      display: none;
    }

    img {
      max-width: 193px;
      max-height: 55px;
      margin: 10px 0;
    }

    .p-button-icon-only {
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
        background: transparent;

      &:hover,
      &:active,
      &:focus {
        border: none;
      }
    }
  }
}

.whitelabel-logo-box {
  padding: 10px;
}

.background-color-header {
  &.dark-header {
    background-color: $giacom-grey2;
  }

  &.light-header {
    background-color: #FFFFFF;
  }
}
