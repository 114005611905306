@import '../../../styles/variables';

$button-root-selector: '.cm-button-new';
$button-padding: 6px;

@mixin colorTheme($colorThemeName, $whitelabelColorKeys) {
  &#{$button-root-selector}--#{$colorThemeName} {
    @include setWhitelabelColorWithFallback('border-color', map-get($whitelabelColorKeys, $cm-color-variant-regular));
    @include setWhitelabelColorWithFallback('color', map-get($whitelabelColorKeys, $cm-color-variant-variable-text));

    &:focus {
      @include setWhitelabelColorWithFallback('border-color', map-get($whitelabelColorKeys, $cm-color-variant-darker));
    }

    &:disabled,
    &[disabled] {
      @include setWhitelabelColorWithFallback('border-color', map-get($whitelabelColorKeys, $cm-color-variant-lightest));
    }
  }
}

@mixin colorFillTheme($colorThemeName, $whitelabelColorKeys) {
  &#{$button-root-selector}--#{$colorThemeName} {
    @include setWhitelabelColorWithFallback('border-color', map-get($whitelabelColorKeys, $cm-color-variant-regular));
    @include setWhitelabelColorWithFallback('background-color', map-get($whitelabelColorKeys, $cm-color-variant-regular));
    @include setWhitelabelColorWithFallback('color', map-get($whitelabelColorKeys, $cm-color-variant-variable-text));

    &:focus {
      @include setWhitelabelColorWithFallback('background-color', map-get($whitelabelColorKeys, $cm-color-variant-darker));
    }

    &:disabled,
    &[disabled] {
      @include setWhitelabelColorWithFallback('border-color', map-get($whitelabelColorKeys, $cm-color-variant-lightest));
      @include setWhitelabelColorWithFallback('background-color', map-get($whitelabelColorKeys, $cm-color-variant-lightest));
    }
  }
}

#{$button-root-selector} {
  background: none;
  font: inherit;
  cursor: pointer;
  outline: none;
  color: map-get($cm-color-grey, $cm-color-variant-darker);
  border: solid 2px map-get($cm-color-grey, $cm-color-variant-darker);
  height: $cm-button-height;
  padding: $button-padding;
  border-radius: 4px;

  &:disabled,
  &[disabled] {
    color: map-get($cm-color-grey, $cm-color-variant-lighter);
    cursor: not-allowed;
    opacity: 0.8;
  }

  @include colorTheme(primary, $cm-white-label-color-primary-keys);
  @include colorTheme(secondary, $cm-white-label-color-secondary-keys);

  &#{$button-root-selector}--fill {
    @include colorFillTheme(primary, $cm-white-label-color-primary-keys);
    @include colorFillTheme(secondary, $cm-white-label-color-secondary-keys);
  }

  &#{$button-root-selector}--corners-spiked {
    border-radius: 0;
  }

  &#{$button-root-selector}--large {
    height: $cm-button-large-height;
  }
}
