.hosted-exchange {
    &.step-one {
        .form-title {
            .step-title {
                padding-bottom: 15px;
            }
        }

        .service-select-product {
            .select-product {
                padding-top: 10px;

                .add-ons {
                    .item button {
                        width: 115px;
                        text-align: left;
                    }
                }
            }
        }
    }

    &.step-two {
        .select-user {
            .list-unstyled {
                height: auto;
                width: 150px;
                border: 1px $giacom-border-input solid;
                width: 100%;

                li {
                    padding-left: 10px;

                    a {
                        color: $giacom-black;
                        text-decoration: none;
                    }
                }
            }
        }

        .product-list {
            padding-top: 10px;
            padding-bottom: 20px;
        }

        .form-title {
            display: inline-grid;
            width: 100%;
            justify-content: start;

            .step-title {
                align-self: center;
                grid-column-start: 1;
            }

            .add-new-user {
                grid-column-start: 3;
                margin: 0 0 15px 20px;
            }

            .title-setting {
                margin-bottom: 15px;
                font-size: 18px;
                @include Gotham-600;
            }
        }

        .button-group * {
            margin-left: 10px;
        }

        .panel-group {
            margin-top: 50px;

            &:first-child {
                .panel-heading {
                    border-top: none;
                }
            }

            .panel-heading {
                // padding-top: 0;
                // padding-bottom: 0;
            }
        }

        // .giacom-datetime {
        //     width: 100%;
        // }
    }

    .user-setup {
        &:not(:first-child) {
            border-top: 1px solid $giacom-grey3;

            .headline {
                margin-top: 20px;
            }

            .select-user {
                margin-top: 20px;
            }
        }

        .headline {
            margin-bottom: 20px;

            .title {
                padding-left: 0;
                margin-top: 5px;
                font-size: 18px;
                @include Gotham-600;
                color: $giacom-grey2;

                span {
                    vertical-align: middle;
                }

                .user-name {
                    margin-top: 5px;
                    @include Gotham-400;
                    font-size: 16px;
                }
            }

            .action {
                text-align: right;
                padding-right: 0;
                padding-left: 0;

                button:nth-child(2) {
                    margin-left: 5px;
                }
            }
        }

        .row {
            margin: 0;
            margin-bottom: 20px;

            .list-product {
                padding-top: 10px;
            }

            .name-headline {
                text-align: right;
                @include Gotham-600;
            }

            .product-price {
                text-align: right;
                padding-right: 0;
            }

            &:not(.headline) {
                .title {
                    padding: 0;
                    padding-right: 15px;
                    text-align: right;
                    font-size: 16px;
                    @include Gotham-600;
                    color: $giacom-grey2;
                    margin-top: 10px;
                }
            }

            &.advanced-options,
            &.spam-action {
                .title {
                    margin-top: 0;
                }
            }

            .hint {
                position: absolute;
                margin: 0 auto;
                right: -35px;
                top: 10px;
            }

            .until {
                &.title,
                &.input {
                    margin-top: 10px;
                }
            }
        }

        .select-user {
            width: 100%; // .input {
            .giacom-dropdown-header {
                width: 100%;

                button.dropdown-toggle {
                    width: 100%;
                    text-align: left;
                    display: inline-flex;

                    .user-name {
                        width: 97%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .dropdown-menu {
                    width: 100%;
                    max-height: 250px;
                    overflow-y: auto;

                    a {
                        word-break: break-all;
                    }
                }
            }

            // }
        }

        .spam-action {
            // height: 105px;
        }

        ul.items {
            display: flex;
            flex-direction: column;

            li {
                height: 35px;
                display: inline-flex;

                span.name {
                    margin-left: 5px;
                    color: $giacom-grey2;
                }
            }
        }

        .input {
            padding-left: 0;
            padding-right: 0;

            &.align-right {
                float: right;
            }

            &.date-time {
                margin-bottom: 25px;

                .giacom-datetime .input-group-addon .fa-calendar {
                    padding: 0;
                }
            }
        }

        ul.items {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        input {
            color: $giacom-grey2;
            border-radius: 0;
            height: 40px;
            @include Gotham-400;
            font-size: 16px;

            &::placeholder {
                color: $giacom-grey5;
            }

            &:focus {
                outline: none;
                border: 1px solid $giacom-grey5;
                box-shadow: none;
            }

            &.errorInput {
                border: 1px solid $giacom-pink1;
            }
        }
    }

    .select-product.user-setup {
        .panel-heading {
            padding-left: 0;
            padding-right: 0;
        }

        .edit {
            margin-left: 10px;
        }
    }

    .accordion-title {
        display: inline-grid;
        justify-content: center;
        align-items: center;
        width: 100%;
        grid-template-columns: 3fr 1fr 1fr;

        .title {
            grid-column-start: 1;
        }

        .remove {
            grid-column-start: 4;
        }

        .edit {
            grid-column-start: 6;
        }
    }

    .accordion-body {
        margin-top: -45px;

        .select-user {
            display: inline-block;
            vertical-align: middle;
            float: none;

            .title {
                display: inline-block;
                max-width: 100%;
                text-align: right;
            }

            .input {
                .dropdown-toggle,
                .dropdown-menu {
                    width: 100%;
                    text-align: left;
                }
            }
        }

        ul.container {
            list-style: none;
            padding-left: 15px;
            width: 100%;
            display: inline-grid;
            grid-template-columns: 220px 2fr 25px;
            grid-column-gap: 10px;

            .title {
                grid-column: 1 / span 1;
                align-self: center;
                text-align: right;
            }

            .input {
                grid-column: 2 / span 1;
            }

            .clue {
                grid-column: 3 / -1;
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;

                .hint {
                    margin-left: 3px;
                }
            }

            .items {
                list-style: none;
            }

            &.email {
                .input {
                    display: inline-grid;
                    grid-template-columns: 160px 3fr;
                    grid-gap: 13px;

                    input {
                        grid-column: 1;
                        width: 160px;
                    }

                    .dropdown {
                        grid-column: 2 / -1;
                    }
                }
            }

            &.spam-action,
            &.advanced-options {
                .title {
                    align-self: start;
                }

                .items {
                    padding-left: 0;
                    display: grid;

                    li {
                        display: inline-flex;

                        &:nth-of-type(1) {
                            grid-column-start: 1;
                            grid-row-start: 1;
                        }

                        &:nth-of-type(2) {
                            grid-column-start: 1;
                            grid-row-start: 2;
                        }

                        &:nth-of-type(3) {
                            grid-column-start: 1;
                            grid-row-start: 3;
                        }

                        &.clue {
                            grid-column-start: 2;
                            grid-row: 1 / span 3;

                            .hint {
                                margin-top: -10px;
                            }
                        }
                    }
                }
            }

            &.spam-action {
                .items {
                    grid-template-columns: 160px 20px;
                    grid-template-rows: 35px 35px 35px;
                }
            }

            &.advanced-options {
                .items {
                    grid-template-columns: auto 20px;
                    grid-template-rows: 35px;
                }

                .delay-activation {
                    .hint {
                        margin-left: 10px;
                    }
                }

                .internal-email {
                    .hint {
                        margin-left: 10px;
                    }
                }

                .select-delay-activation {
                    margin-bottom: 15px;

                    .hint {
                        margin-top: 10px;
                        margin-left: 13px;
                    }
                }
            }

            .dropdown {
                button.dropdown-toggle {
                    width: 100%;
                    text-align: left;
                }

                ul.dropdown-menu {
                    width: 100%;
                    max-height: 200px;
                    overflow-y: auto;
                }
            }

            &.spam-action {
                height: 90px;

                &.until {
                    height: 50px;
                }
            }

            &.advanced-options {
                height: 20px;
            }
        }

        .title {
            font-size: 16px;
            @include Gotham-600;
            color: $giacom-grey2;
        }

        input {
            color: $giacom-grey2;
            border-radius: 0;
            height: 40px;
            @include Gotham-400;
            font-size: 16px;

            &::placeholder {
                color: $giacom-grey5;
            }

            &:focus {
                outline: none;
                border: 1px solid $giacom-grey5;
                box-shadow: none;
            }

            &.errorInput {
                border: 1px solid $giacom-pink1;
            }
        }
    }

    .giacom-alert {
    }
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 767px) {
    .hosted-exchange.step-two {
        .button-group {
            button {
                margin-left: 0;

                &.continute {
                    order: 1;
                }

                &.add-another-user {
                    order: 2;
                }

                &.back {
                    order: 3;
                }
            }
        }

        .user-setup {
            .row {
                &:not(.headline) {
                    .title {
                        text-align: left;
                        margin-bottom: 15px;
                    }
                }

                .name-headline {
                    padding: 0;
                    text-align: left;
                }
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    .hosted-exchange.step-two {
        .giacom-datetime {
            width: 95%;

            input {
                width: 100%;
            }
        }
    }
    .hosted-exchange .user-setup .select-user .input .giacom-dropdown-header button.dropdown-toggle,
    .hosted-exchange .user-setup input,
    .hosted-exchange .user-setup .select-user .input .giacom-dropdown-header .dropdown-menu {
        width: 95%;
    }
    .hosted-exchange .user-setup .row .hint {
        right: 0;
    }
}
