.email-security {
    max-width: $serivice-form-width;

    .form-content {
        margin-right: 0;
    }

    .dropdown-menu {
        a {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    label.giacom-label {
        margin-right: -15px;
        margin-top: 10px;
    }

    .form-body {
        margin-top: 20px;
    }

    .btn-continue {
        float: right;
        margin-bottom: 30px;
        margin-top: 20px;
    }

    .form-title {
        padding-bottom: 15px;
    }

    .radio-group {
        margin-bottom: 15px;

        label {
            display: block;

            input {
                margin-right: 10px;
            }
        }
    }

    .line-bottom {
        color: $giacom-grey2;
        border-bottom: 1px solid $giacom-grey3;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .step-title {
        display: inline-block;
    }

    .add-new-user {
        margin-left: 10px;
    }

    .add-ons-message {
        font-size: 16px;
        @include Gotham-400;
        color: $giacom-grey5;
        padding-bottom: 10px;
        display: inline-block;
        text-align: right;
        margin-right: -15px;

        .hint {
            display: inline-block;
            margin-left: 10px;
        }
    }

    .item {
        display: inline-flex;
        width: 100%;
        margin-bottom: 10px;

        .recommend {
            margin-left: 30px;
            margin-top: 10px;
        }

        .item-option {
            .giacom-radio,
            .giacom-checkbox {
                display: inline-block;

                input {
                    margin-right: 5px;
                }
            }

            .name {
                margin-left: 5px;
            }
        }

        .amount-period {
            width: 50%;
            text-align: right;

            .amount {
                font-size: 16px;
                @include Gotham-600;
                padding-right: 0.5em;
                color: $giacom-grey2;
            }
        }
    }

    .btn-group {
        margin-bottom: 20px;
        float: right;

        .btn + .btn {
            margin-left: 10px;
        }

        .btn-view-customer {
            display: inline-flex;
            justify-content: space-around;
        }

        .customer-name {
            text-overflow: ellipsis;
            overflow: hidden;
            width: 150px;
        }
    }

    .option {
        margin-bottom: 15px;
    }

    .form-group.confirm-group {
        border-bottom: 1px solid $giacom-grey3;
        padding-bottom: 15px;
        margin-left: 0;
        margin-right: 0;
    }

    .confirm-label {
        text-align: left;
        font-weight: 400;
        padding-left: 0;
    }

    .confirm-cost {
        text-align: right;
        font-weight: 400;
    }

    .confirm-value {
        font-weight: 600;
        padding: 0;
    }

    .remove-edit-group {
        padding-right: 0;
        padding-left: 25px;

        .username {
            margin-bottom: 10px;
        }
    }

    .giacom-select {
        min-width: 160px;
        width: calc(100% - 40px);
        position: relative;
        float: left;

        .btn-default {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-right: 20px;
        }

        + .hint {
            margin-top: 10px;
        }

        button {
            width: 100%;
            text-align: left;
            height: 40px;
        }

        .caret {
            position: absolute;
            top: 18px;
            right: 10px;
        }

        .dropdown-menu {
            width: 100%;
        }
    }

    .each-user {
        margin-bottom: 20px;

        &.off {
            display: none;
        }
    }

    .order-total {
        border-top: 5px solid $giacom-grey3;
        padding-bottom: 3.125em;
        height: 6.25em;

        div {
            padding: 1.25em 0 0 0;
        }

        .name {
            font-size: 16px;
            @include Gotham-600;
        }

        .amount-period {
            margin-bottom: 0;
            display: inline-flex;
            justify-content: flex-end;
            align-items: center;
            align-content: space-between;

            .amount {
                order: 1;
                font-size: 16px;
                @include Gotham-600;
                padding-right: 0.5em;
            }

            .period {
                order: 2;
                padding-right: 0.852em;
                @include Gotham-400;
            }
        }
    }

    &.step-one {
        .add-ons-message {
            text-align: left;
        }

        .item {
            flex-direction: column;
        }

        .item-option {
            width: 60%;
        }

        .amount-period {
            width: 40%;
        }
    }

    &.step-two {
        .form-group {
            margin-right: -30px;
        }
    }

    .popup-promotion {
        .btn-group {
            margin-top: 20px;
        }
    }

    .why-add {
        padding-left: 10px;
        color: $giacom-blue1;
    }

    .giacom-input {
        width: calc(100% - 40px);
        display: inline-block;
    }

    .form-content .hint {
        display: inline-block;
    }

    .giacom-datetime {
        width: calc(100% - 20px);
    }

    .accordion-title {
        .giacom-label {
            padding-right: 10px;
        }

        .btn-group {
            margin: 0;
        }

        .title {
            float: right;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    .step-info {
        span {
            text-decoration: underline;
        }
    }

    @media screen and (max-width: 767px) {
        &.step-one {
            .item {
                display: inline-block;

                .item-option,
                .amount-period {
                    width: 100%;
                }

                .amount-period {
                    text-align: left;
                    margin-left: 30px;
                }
            }
        }
        &.step-two {
            .form-group {
                margin-right: -15px;
            }
        }
        .remove-edit-group {
            padding-left: 15px;
            margin-left: 0;

            .btn-group {
                padding-right: 15px;
            }
        }
        .add-ons-message {
            &.giacom-label {
                text-align: left;
            }
        }
        .giacom-btn {
            width: 100%;
        }
        .add-new-user {
            width: auto;
        }
        .btn-group {
            display: flex;
            width: 100%;
            flex-direction: column;

            .btn + .btn {
                margin-left: 0;
            }

            .btn {
                margin-bottom: 10px;
            }

            .giacom-btn {
                width: 100%;
            }

            .btn-review-order {
                order: 1;
            }

            .btn-add-another {
                order: 2;
            }

            .back {
                order: 3;
            }
        }
        .accordion-title .title {
            float: left;
        }
    }
}
