.giacom-service-form {
    .form-content {
        max-width: $serivice-form-width;
        margin: 1.875em 2em 0 0;

        .form-title {
            color: $giacom-grey2;
            border-bottom: 1px solid $giacom-grey3;
            margin-bottom: 30px;

            .title-order {
                margin-top: 20px;
            }

            .step-title {
                font-size: 18px;
                @include Gotham-600;
                color: inherit;
                line-height: 0;
                padding: 0;
                margin: 0;
                margin-bottom: 15px;

                span {
                    &.fa-question-circle {
                        color: $giacom-grey5;
                        font-size: 20px;
                        cursor: pointer;
                    }
                }
            }

            .step-intro {
                font-size: 16px;
                @include Gotham-400;
                color: inherit;
                padding-bottom: 15px;
            }

            .title-order {
                font-size: 16px;
                @include Gotham-600;
                color: $giacom-grey2;
                line-height: 0;
                padding-bottom: 35px;
            }
        }

        .select-license {
            color: $giacom-grey2;
            margin-bottom: 22px;

            .title-license {
                font-size: 18px;
                @include Gotham-600;
                color: inherit;
                line-height: 0;
                margin-bottom: 20px;
            }

            .title-intro {
                margin-top: 25px;

                .title-later {
                    display: inline-block;
                    width: calc(100% - 165px);
                }
            }
        }

        .hint {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 13px;
            border-radius: 100%;
            width: 21px;
            height: 21px;
            color: $giacom-grey5;
            cursor: pointer;

            .fas {
                font-size: 21px;
            }
        }
    }

    .button-group {
        border-top: 1px solid $giacom-grey3;
        margin-bottom: 30px;
        display: flex;
        justify-content: flex-end;
        align-content: flex-end;
        width: 100%;

        button {
            margin-top: 30px;

            &:last-child {
                margin-left: 10px;
            }
        }
    }

    .display-selection {
        padding-bottom: 50px;
    }

    .giacom-form .button-group button,
    .giacom-form .button-group-customized button {
        margin-right: 0;
    }
}

@media screen and (max-width: 1023px) {
    .giacom-service-form {
        .form-content {
            margin-right: 0;
        }
    }
}

@media screen and (max-width: 767px) {
    .giacom-service-form {
        .form-content {
            max-width: initial;
            width: 100%;
        }

        .button-group {
            padding-top: 7px;
            margin-bottom: 0;
            flex-wrap: wrap;
            width: 100%;

            button {
                margin: 0 0 10px 0;
                width: 100%;

                &:first-child {
                    order: 2;
                    margin-bottom: 0;
                }

                &:last-child {
                    margin-left: 0;
                    order: 1;
                }
            }
        }
    }
}

@media screen and (min-width: 991px) and (max-width: 1023px) {
    .giacom-service-form {
        .col-md-9 {
            width: 100%;
        }
    }
}

.office-365-step-third {
    .title-edit {
        padding-bottom: 20px;
    }

    .form-title {
        .service-name {
            font-size: 16px;
            @include Gotham-600;
            color: $giacom-grey2;
        }

        .note {
            padding-bottom: 20px;
        }
    }

    .form-body {
        .product-name {
            margin-bottom: 20px;
            border-bottom: 1px solid $giacom-grey3;
            padding-bottom: 30px;

            .licenses {
                margin-bottom: 10px;
            }
        }

        .bitdefender-product-name {
            @include Gotham-600;
            color: $giacom-grey2;
        }

        .group-button {
            float: right;
            margin-bottom: 30px;

            .giacom-btn-no-background {
                margin-right: 5px;
            }
        }

        .order-total {
            border-top: 5px solid $giacom-grey3;
            padding-top: 20px;
            margin-top: -20px;
        }

        .order-summary {
            margin-bottom: 20px;
        }
    }
}
