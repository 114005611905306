@mixin giacom-panel-list {
    text-align: left;
    list-style: none;
    padding-left: 0;
    li {
        padding: 10px 15px;
        $border-style: 1px solid $giacom-grey3;
        border-top: $border-style;

        &:last-child {
            border-bottom: $border-style;
        }

        &:hover {
            background-color: $giacom-grey4;
        }

        a {
            font-size: inherit;
            @include Gotham-400;
            // color: $giacom-blue1;
        }
    }
}

@mixin generate-font($font-size: 16px, $color: $giacom-blue1) {
    font-size: $font-size;
    color: $color;
}
