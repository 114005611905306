.ticket-issue {
    .form-horizontal {
        .control-label {
            padding-top: 0;
        }

        .select-user,
        .ticket-detail-content {
            .control-label {
                padding-top: 7px;
            }
        }
    }
}

.ticket-issue-result {
    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 10px;

        li {
            margin-bottom: 10px;
        }

        a {
            color: $giacom-blue1;
            @include Gotham-400;
            font-size: 16px;

            &:hover,
            &:active,
            &:focus {
                color: $giacom-blue1;
            }
        }
    }
}

.ticket-issue-complete {
    .ticket-id {
        margin-bottom: 30px;
    }

    .ticket-promotion {
        height: 152px;
        width: 645px;
        background-color: $giacom-grey4;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        margin-bottom: 30px;
    }
}

.ticket-issue-brief {
}

.text-bold {
    @include Gotham-600;
}

.ticket-issue-detail {
    .issue-summary {
        margin: 0;
        padding-bottom: 15px;
        border-bottom: 1px solid $giacom-grey3;
        margin-bottom: 20px;

        &__title {
            text-align: right;
        }

        &__content {
            &--edit {
                margin-top: 10px;
            }
        }
    }

    .ticket-issue-detail-list {
        &--checkbox {
            display: inline-flex;
            align-items: center;

            .name {
                margin-left: 10px;
                width: calc(100% - 20px);
            }
        }

        .giacom-credit__content__header__date,
        .giacom-credit__content__item__date {
            width: 195px;
        }

        .giacom-credit__content__item__date {
            text-align: right;
        }

        .tbl-content {
            height: 330px;
            overflow-x: hidden;
            overflow-y: auto;
            margin-top: 0px;
        }

        .button-group {
            margin-top: 45px;
        }
    }

    .giacom-credit__content .table {
        margin: 0;
        width: 100%;
        table-layout: fixed;
    }

    .select-user {
        button.dropdown-toggle {
            width: 100%;
        }
    }

    .headline {
        margin-bottom: 15px;
    }

    .review-summary-panel {
        display: flex;
        margin-bottom: 15px;
        justify-content: center;

        &__title {
            padding: 15px;
            background-color: $giacom-blue3;
            order: 1;
            flex-basis: 170px;
            text-align: right;
            @include Gotham-600;
        }

        &__content {
            order: 2;
            padding: 15px;
            flex-basis: calc(100% - 170px);

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    margin-bottom: 10px;
                }
            }
        }
    }

    .giacom-form {
        .button-group {
            button {
                margin: 0;
                margin-top: 30px;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
