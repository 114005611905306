.giacom-payment-options {
    margin-bottom: 30px;

    .content {
        margin-top: 35px;
    }

    .download {
        margin-top: 40px;

        &__title {
            font-size: 16px;
            @include Gotham-600;
            color: $giacom-grey2;
        }

        &__file {
            list-style: none;
            padding: 0;
            margin: 0;
            margin-top: 10px;

            &__item {
                padding-bottom: 10px;

                &:last-child {
                    padding-bottom: 0;
                }

                &__icon,
                &__link {
                    color: $giacom-blue2;
                }

                &__info {
                    color: $giacom-grey5;
                    @include Gotham-400;
                    margin-left: 5px;
                }
            }
        }
    }
}

.giacom-display-settings {
    padding-top: 10px;

    &__show-prices {
        display: inline-flex;
        margin-top: 10px;
        margin-bottom: 20px;

        .name {
            margin-left: 10px;
        }
    }

    .button-group {
        margin-top: 10px;

        button {
            &:first-child {
                margin-right: 5px;
            }
        }
    }
}
