.giacom-market-place {
  .giacom-sub-navigation {
    .nav-menu {
      padding-top: 15px;
    }
    .nav-title {
      padding-bottom: 0;
    }
  }
  .giacom-content {
    background-color: $giacom-white;
    padding-top: 20px;
  }

  .items {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .selected-menu-item {
    float: left;
    font-size: 21px;
    font-weight: 600;
  }

  .suggest-price-list-wrapper {
    float: right;

    > * {
      margin-left: 20px;
    }
  }

  .detail {
    display: block;
    margin-top: 10px;
    color: $giacom-blue1;
  }

  .giacom-page {
    .row {
      margin-left: -8px;
      margin-right: -10px;
    }
    .col-lg-4, .col-md-4, .col-sm-4 {
      padding: 0 8px;
    }
  }
}

.market-service-panel {
  &.panel.giacom-panel {
    border: 1px solid $giacom-grey3;
    box-shadow: 3px 3px 5px $giacom-grey3;
    position: relative;
    padding-top: 10px;
    min-height: 293px;
  }
  .information {
    .main-content {
      margin-top: 20px;
      margin-bottom: 5px;
      font-size: 30px;
    }
  }

  .button-bottom {
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.graphic {
  padding-bottom: 7px;
  padding-top: 30px;
  img  {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-radius: 50%;
    background-color: #ecedec;
  }
}

@media screen and (max-width: 443px)  {  
  .market-service-panel {
    &.panel.giacom-panel {
      padding-left: 0px;
  }
}
}

.service-label {
  position: absolute;
  top: 0;
  right: 10px;
  background-color: $giacom-yellow1;
  width: 90px;
  padding: 5px;
  font-size: 15px;
  text-align: center;

}




