.giacom-credit {
    &__headline {
        width: 100%;
        height: 180px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;

        &.form-content {
            max-width: initial;
            background-color: $giacom-grey4;
        }

        &__title {
            order: 1;
            font-size: 16px;
            color: $giacom-grey2;
            display: inline-flex;
            margin-bottom: 5px;
        }

        &__total {
            font-size: 35px;
            @include Gotham-600;
            color: $giacom-grey2;
            order: 2;
            margin-bottom: 25px;
        }

        &__action {
            order: 3;
        }
    }

    &__content {
        .table {
            margin-top: 20px;

            thead {

                tr:hover {
                    cursor: initial;
                    background-color: transparent;
                }

                th {
                    border-bottom: none;
                    height: 60px;
                    vertical-align: middle;
                }
            }

            tbody {
                tr {
                    td {
                        border-top: none;
                        height: 50px;
                        vertical-align: middle;
                    }

                    border-top: 1px solid $giacom-grey3;

                    &:first-child {
                        border-top: none;
                    }

                    &:last-child {
                        border-bottom: 1px solid $giacom-grey3;
                    }
                }
            }
        }

        &__header {
            color: $giacom-grey2;

            &__date {
                width: 130px;
            }

            &__detail {
            }

            &__balance {
                text-align: right;
            }
        }

        &__item {
            &:hover {
                background-color: $giacom-grey4;
            }

            .amount {
                @include Gotham-600;
                color: $giacom-blue1;
            }

            &__no-data {
                text-align: center;
                vertical-align: middle;
                color: $giacom-pink1;
            }

            &__date {
            }

            &__detail {
            }

            &__balance {
                text-align: right;
            }

            &__balance {
                // @include OpenSans-600;
                // color: $giacom-grey2;
            }
        }
    }
}
