.navbar .navbar-nav .dropdown.show:before {
    top: var(--header-height);
}

// LOGO CONVERTING TO GRAYSCALE - for the future
//[theme="dark"] .navbar-brand-without-logo img {
//    filter: grayscale(100%);
//}
//
//[theme="light"] .navbar-brand-without-logo img {
//    filter: grayscale(100%) invert(100%);
//}
