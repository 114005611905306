.service-related-links {
    padding-top: 30px;
    order: 2;
    margin-left: auto;

    ul {
        padding: 0;
        list-style: none;

        li {
            font-size: 16px;

            &:first-child {
                border-top: 0.375em solid $giacom-grey4;
                padding: 0.9375em 0;
                color: $giacom-grey2;
                @include Gotham-600;
            }

            padding-bottom: 0.625em;

            a {
                word-break: break-word;
                color: $giacom-blue1;
                @include Gotham-400;
            }
        }
    }
}

.manage-service-link {
    margin-top: 10px;
    order: 2;
    margin-left: auto;

    ul {
        padding: 0;
        list-style: none;
        text-align: center;

        li {
            font-size: 16px;
            background-color: $giacom-blue3;

            &:first-child {
                padding: 0.9375em 0;
                color: $giacom-grey2;
            }

            padding-bottom: 0.625em;

            a {
                word-break: break-word;
                color: $giacom-blue1;
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    .service-related-links {
        width: initial;
        padding-top: 3.125em;

        &.col-xs-12.col-sm-12.col-md-3 {
            width: 100%;
        }
    }
    .manage-service-link {
        width: initial;
        padding-top: 3.125em;

        &.col-xs-12.col-sm-12.col-md-3 {
            width: 100%;
        }
    }
}
