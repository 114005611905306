.giacom-add-admin-user {
    .giacom-service-form .form-content .form-title .step-title {
        padding-bottom: 15px;
    }

    .giacom-popup-form label.text-bold {
        margin-top: 0;
    }

    button.dropdown-toggle {
        width: 100%;
    }

    &__form {
        &__guideline {
            margin-bottom: 10px;
            @include Gotham-400;
            color: $giacom-grey2;
        }

        &__warning {
            @include Gotham-600;
            color: $giacom-pink1;
        }
    }

    &__options {
        &__checkbox {
            margin-top: 8px;
        }
    }

    .giacom-service-form .giacom-user-edit-permission__body .button-group {
        border-top: 0;
        margin-top: -20px;
    }
}

@import "add_admin_user_popup";
@import "add_admin_user_login_details";
