.hosted-exchange {
    .review-order {
        &:not(.first) {
            // border-top: 1px solid $giacom-grey3;
            .review-order--add {
                margin-top: 20px;
            }
        }

        &__title {
            font-size: 16px;
            margin-bottom: 10px;

            &__label {
                @include Gotham-400;
                //text-align: right;
            }

            &__user-name {
                @include Gotham-600;
                word-break: break-all;
            }
        }

        &__email {
            font-size: 16px;
            color: $giacom-grey2;
            margin-bottom: 5px;

            &___address {
                @include Gotham-400;
            }

            &__settings {
                @include Gotham-600;
            }
        }

        &__product {
            margin-bottom: 40px;

            &__name {
                @include Gotham-600;
                font-size: 16px;
                color: $giacom-grey2;
            }

            &__price {
                text-align: right;
                font-size: 16px;
                color: $giacom-grey2;

                &__amount {
                    @include Gotham-600;
                }

                &__period {
                    @include Gotham-400;
                }
            }
        }

        &__previous,
        &__current {
            font-size: 16px;
            color: $giacom-grey2;
            @include Gotham-400;
        }
    }

    &.step-three {
        .title-email {
            padding-bottom: 15px;
        }

        .review-order {
            &:first-child {
                // height: 120px;
            }

            &:not(:first-child) {
                // border-top: 1px solid $giacom-grey3;
                // margin-top: 20px;
                .title {
                    // margin-top: 15px;
                }
            }

            //   height: 145px;
            // margin-bottom: 40px;
            // display: grid;
            // grid-template-columns: 3fr 1fr;
            // grid-template-rows: 1fr 2fr auto;
            // grid-column-gap: 15px;
            .title {
                grid-column: 1 / -1;
                grid-row-start: 1;
                font-size: 16px;
                @include Gotham-600;
            }

            .email {
                grid-column: 1 / -1;
                grid-row-start: 2;
                display: inline-grid;
                grid-template-columns: 3fr 2fr;
                align-items: center;

                .email-address {
                    grid-column-start: 1;
                    @include Gotham-300;
                    font-size: 16px;
                    color: $giacom-grey2;
                }

                .email-settings {
                    grid-column-start: 2;
                    text-align: right;
                    font-size: 16px;
                    @include Gotham-600;
                    color: $giacom-grey2;
                }
            }

            .products {
                grid-column: 1 / -1;
                grid-row-start: 3;
                display: grid;
                grid-template-columns: auto;
                grid-template-rows: 25px;
            }

            .item {
                grid-column: 1 / -1;
                display: inline-grid;
                grid-template-columns: 3fr 2fr;
                align-items: center;
                grid-row-gap: 5px;

                .name {
                    grid-column-start: 1;
                    @include Gotham-300;
                    font-size: 16px;
                    color: $giacom-grey2;
                }

                .price {
                    grid-column-start: 2;
                    text-align: right;
                    font-size: 16px;
                    color: $giacom-grey2;

                    .amount {
                        @include Gotham-600; // padding-right: 0.5em;
                    }

                    .period {
                        @include Gotham-300;
                    }
                }
            }
        }

        .order-total {
            .amount-period {
                .amount {
                    padding-right: 5px;
                }

                .period {
                    padding-right: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .hosted-exchange {
        .review-order {
            &__title {
                &__label {
                    margin-bottom: 10px;
                    text-align: left;
                }
            }
        }
    }
}
