$max-container-width-lg: 1024;
$max-container-width-lg-wide: 1500;

.giacom-logo-wrapper,
.giacom-navbar,
.giacom-page-wrapper {
  max-width: #{$max-container-width-lg}px;
  margin: 0 auto;
  padding: 0 15px;
}

.giacom-page--wide {
  .giacom-logo-wrapper,
  .giacom-navbar,
  .giacom-page-wrapper {
    max-width: #{$max-container-width-lg-wide}px;
    padding: 0 30px;
  }
}

.giacom-spinner-wrapper {
  position: unset !important;
  height: 100%;
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .giacom-page-wrapper,
  .giacom-navbar {
    max-width: 740px;
  }
}

@media screen and (max-width: 767px) {
}

.responsive-container {
  max-width: #{$max-container-width-lg}px;
  margin: 0 auto;
  padding: 0 15px;

  &--wide {
    max-width: #{$max-container-width-lg-wide}px;
  }
}

