.giacom-error-page {
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: $giacom-blue1;
    padding: 0;
    margin: 0;

    .giacom-header {
        width: 100%;
        position: fixed;

        .navbar-collapse {
            display: none;
        }
    }

    .giacom-error-page-wrapper {
        width: 1024px;
        height: 100%;
        display: table;
        margin: 0 auto;
        vertical-align: middle;
        @media screen and (max-width: 1023px) {
            width: 740px;
        }
        @media screen and (max-width: 443px) {
            width: 350px;
        }

    }

    .giacom-error-page-content {
        height: 100%;
        display: table-cell;
        vertical-align: middle;
        padding-left: 15px;
        padding-right: 15px;
    }

    h1 {
        font-size: 45px;
        @media screen and (max-width: 443px) {
            font-size: 20px;
        }
        color: $giacom-blue1;
        @include Gotham-600;
        background-color: $giacom-white;
        display: table;
        padding: 15px 25px 23px 25px;
        margin-bottom: 2px;
        border: 0;
        position: relative;
    }

    h2 {
        color: $giacom-grey2;
        font-size: 23px;
        @media screen and (max-width: 443px) {
            font-size: 18px;
        }

        @include Gotham-400;
        letter-spacing: 1px;
        border: 0;
        line-height: 50px;
        background-color: $giacom-white;
        display: table;
        padding: 10px 25px 16px 25px;
        margin-bottom: 2px;
    }

    .giacom-btn {
        margin-top: 20px;
    }
}
