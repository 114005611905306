/* variations */
/* UI */
/* font */
/* background */
/* status */
/* UI */
/* font */
/* PSA */
/* for use with heading typography - e.g. h1, h2 etc */
/* for use with non heading typography - e.g. p, ul, table etc */
/* font-weights */
/* line-heights */
.cm-button-new {
  background: none;
  font: inherit;
  cursor: pointer;
  outline: none;
  color: #1B1B22;
  border: solid 2px #1B1B22;
  height: 40px;
  padding: 6px;
  border-radius: 4px;
}
.cm-button-new:disabled, .cm-button-new[disabled] {
  color: #49495A;
  cursor: not-allowed;
  opacity: 0.8;
}
.cm-button-new.cm-button-new--primary {
  border-color: var(--primary);
  border-color: var(--color-1-1) !important;
  color: var(--white);
  color: var(--color-1-t) !important;
}
.cm-button-new.cm-button-new--primary:focus {
  border-color: var(--primary-darker);
  border-color: var(--color-1-2) !important;
}
.cm-button-new.cm-button-new--primary:disabled, .cm-button-new.cm-button-new--primary[disabled] {
  border-color: var(--primary-lighter);
  border-color: var(--color-5-2) !important;
}
.cm-button-new.cm-button-new--secondary {
  border-color: #E6007E;
  border-color: var(--color-2-1) !important;
  color: #FFFFFF;
  color: var(--color-2-t) !important;
}
.cm-button-new.cm-button-new--secondary:focus {
  border-color: #C71253;
  border-color: var(--color-2-2) !important;
}
.cm-button-new.cm-button-new--secondary:disabled, .cm-button-new.cm-button-new--secondary[disabled] {
  border-color: #F05089;
  border-color: var(--color-6-2) !important;
}
.cm-button-new.cm-button-new--fill.cm-button-new--primary {
  border-color: var(--primary);
  border-color: var(--color-1-1) !important;
  background-color: var(--primary);
  background-color: var(--color-1-1) !important;
  color: var(--white);
  color: var(--color-1-t) !important;
}
.cm-button-new.cm-button-new--fill.cm-button-new--primary:focus {
  background-color: var(--primary-darker);
  background-color: var(--color-1-2) !important;
}
.cm-button-new.cm-button-new--fill.cm-button-new--primary:disabled, .cm-button-new.cm-button-new--fill.cm-button-new--primary[disabled] {
  border-color: var(--primary-lighter);
  border-color: var(--color-5-2) !important;
  background-color: var(--primary-lighter);
  background-color: var(--color-5-2) !important;
}
.cm-button-new.cm-button-new--fill.cm-button-new--secondary {
  border-color: #E6007E;
  border-color: var(--color-2-1) !important;
  background-color: #E6007E;
  background-color: var(--color-2-1) !important;
  color: #FFFFFF;
  color: var(--color-2-t) !important;
}
.cm-button-new.cm-button-new--fill.cm-button-new--secondary:focus {
  background-color: #C71253;
  background-color: var(--color-2-2) !important;
}
.cm-button-new.cm-button-new--fill.cm-button-new--secondary:disabled, .cm-button-new.cm-button-new--fill.cm-button-new--secondary[disabled] {
  border-color: #F05089;
  border-color: var(--color-6-2) !important;
  background-color: #F05089;
  background-color: var(--color-6-2) !important;
}
.cm-button-new.cm-button-new--corners-spiked {
  border-radius: 0;
}
.cm-button-new.cm-button-new--large {
  height: 60px;
}

/* variations */
/* UI */
/* font */
/* background */
/* status */
/* UI */
/* font */
/* PSA */
/* variations */
/* UI */
/* font */
/* background */
/* status */
:root {
  --color-1-1-alpha0_1: rgba(0, 184, 197, 0);
  --color-1-2: var(--primary-darker);
  --color-1-1: var(--primary);
  --color-5-1: var(--primary-lighter);
  --color-5-2: var(--primary-lighter);
  --color-1-3: #E6F8F9;
  --color-1-t: var(--white);
  --color-2-2: #C71253;
  --color-2-1: #E6007E;
  --color-6-1: #EF4481;
  --color-6-2: #F05089;
  --: #FFFFFF;
  --color-2-t: #FFFFFF;
}

.background-color5-1 {
  background-color: var(--primary-lighter);
  background-color: var(--color-5-1) !important;
}

.background-color5-2 {
  background-color: var(--primary-lighter);
  background-color: var(--color-5-1) !important;
}

.background-hover-color1-1:hover {
  background-color: var(--primary);
  background-color: var(--color-1-1) !important;
}

.hover-color1-1:hover {
  color: var(--primary);
  color: var(--color-1-1) !important;
}

.border-color1-1 {
  border-color: var(--primary);
  border-color: var(--color-1-1) !important;
}

.bottom-border-color1-1 {
  border-bottom-color: var(--primary);
  border-bottom-color: var(--color-1-1) !important;
}

.giacom-btn-secondary,
.button--secondary,
.giacom-panel .information span.fas {
  background-color: var(--primary);
  background-color: var(--color-1-1) !important;
}

.giacom-dropdown-header ul.dropdown-menu li,
.giacom-dropdown-header ul.dropdown-menu li:hover {
  color: var(--primary);
  color: var(--color-1-1) !important;
}

.giacom-dropdown-grid ul.dropdown-menu {
  border-left: 1px solid var(--primary);
  border-right: 1px solid var(--primary);
  border-bottom: 1px solid var(--primary);
}

.giacom-billing .giacom-stacked-bar-chart .btn-group.month .btn {
  border-color: var(--primary);
  border-color: var(--color-1-1) !important;
}

.giacom-btn-secondary-alt,
.cross-sell__placeholder-container--traversed {
  color: var(--primary);
  color: var(--color-1-1) !important;
  border-color: var(--primary);
  border-color: var(--color-1-1) !important;
}

.giacom-select-service .logo img[src$=".png"] {
  background-color: var(--primary);
  background-color: var(--color-1-1) !important;
}

.color1-2 {
  color: var(--primary-darker);
  color: var(--color-1-2) !important;
}

.giacom-billing .iTable.by-service .col-name {
  color: var(--primary);
  color: var(--color-1-1) !important;
}

.giacom-dashboard .box-shadow1-2,
.giacom-panel-form.panel {
  box-shadow: 3px 3px 5px var(--primary-darker) !important;
  box-shadow: 3px 3px 5px var(--color1-2) !important;
}

.border-color1-2 {
  border-color: var(--primary-darker);
  border-color: var(--color-1-2) !important;
}

.color1-3 {
  color: #E6F8F9;
  color: var(--color-1-3) !important;
}

.background-color1-3,
.hover-background-color1-3:hover {
  background-color: #E6F8F9;
  background-color: var(--color-1-3) !important;
}

.color2-1 {
  color: #E6007E;
  color: var(--color-2-1) !important;
}

.color2-2 {
  color: #C71253;
  color: var(--color-2-2) !important;
}

.color5-1 {
  color: var(--primary-lighter);
  color: var(--color-5-1) !important;
}

.color5-2 {
  color: var(--primary-lighter);
  color: var(--color-5-2) !important;
}

.fill1-1 {
  fill: var(--primary);
  fill: var(--color-1-1) !important;
}

.fill2-1 {
  fill: #E6007E;
  fill: var(--color-2-1) !important;
}

.background-color1-1 svg {
  fill: var(--white);
  fill: var(--color-1-t) !important;
}

.color1-1,
.giacom-billing .paid,
.giacom-breadcrumb.no-background,
.giacom-customer-service-suggestion ul.content .list-activity span a,
.giacom-manage-access__body__item__name a,
.giacom-market-place .detail,
.giacom-btn-clear,
.giacom-popup .popup-title .upload,
.giacom-support .giacom-support-network__display,
.giacom-support-knowledgebase-result__list li a,
.giacom-support-tickets__grid__item__issue__name a,
.giacom-support__panel__body a,
.giacom-your-account .table tbody tr td:first-child a,
.service-related-links ul li a,
ul.giacom-select-service li span.fas,
.stepper-item__label--completed {
  color: var(--primary);
  color: var(--color-1-1) !important;
}

.giacom-add-service .giacom-add-service-wrapper,
.giacom-page-wrapper .giacom-breadcrumb,
.giacom-add-service .giacom-breadcrumb,
.giacom-customer-user,
.giacom-customer-user .giacom-breadcrumb,
.giacom-login.giacom-page,
.giacom-pre-dashboard .carousel-control,
.giacom-pre-dashboard .carousel-indicators .active,
.giacom-pre-dashboard.giacom-page,
.giacom-sub-navigation__breadcrumb,
.giacom-sub-navigation__breadcrumb .giacom-breadcrumb {
  background-color: var(--primary);
  background-color: var(--color-1-1);
}

.stepper-item__circle--selected {
  background-color: var(--primary);
  background-color: var(--color-1-1) !important;
}

.giacom-sub-navigation__breadcrumb .giacom-breadcrumb.no-background {
  background-color: transparent;
}

.giacom-btn-no-background.giacom-btn-secondary.btn,
.giacom-btn-no-background.giacom-btn-secondary.btn:hover {
  color: var(--primary);
  color: var(--color-1-1) !important;
  border-color: var(--primary);
  border-color: var(--color-1-1) !important;
}

.giacom-header ul.giacom-menu li.active a,
.giacom-header ul.giacom-menu li:focus a,
.giacom-header ul.giacom-menu li:hover a {
  border-bottom: 4px solid var(--primary-darker);
  border-bottom: 4px solid var(--color-1-2);
}

.dark-menu li.selected span,
.dark-menu li:hover span {
  border-bottom: 2px solid var(--primary-darker) !important;
  border-bottom: 2px solid var(--color-1-2) !important;
}

.giacom-btn-secondary:hover,
.button--secondary:hover {
  background-color: var(--primary-darker);
  background-color: var(--color-1-2) !important;
}

.background-hover-color1-2:hover,
.giacom-sub-navigation .nav-title .giacom-dropdown-grid .giacom-btn {
  background-color: var(--primary-darker);
  background-color: var(--color-1-2) !important;
}

.giacom-manage-access__body__item__icon {
  color: var(--primary-darker);
  color: var(--color-1-2) !important;
}

.giacom-btn-secondary,
.button--secondary,
.giacom-panel .information span.fas {
  background-color: var(--primary);
  background-color: var(--color-1-1) !important;
}

.giacom-your-account .table thead tr,
.background-color1-3 {
  background-color: #E6F8F9;
  background-color: var(--color-1-3) !important;
}

.ps__rail-y {
  z-index: 2;
}

.ps__rail-x {
  z-index: 2;
}

.domain .table thead,
.giacom-billing .iTable .iHeader,
.giacom-billing .monthly-invoice thead,
.giacom-support__panel,
.giacom-user-manage-access__panel__body,
.giacom-user-manage-access__panel__heading,
.giacom-your-account .table thead tr {
  background-color: #E6F8F9;
  background-color: var(--color-1-3) !important;
}

.giacom-dropdown-header ul.dropdown-menu li,
.giacom-dropdown-header ul.dropdown-menu li:hover,
.giacom-grid .table {
  color: var(--primary);
  color: var(--color-1-1) !important;
}

.background-color2-1,
.giacom-btn-primary,
.button--primary,
.giacom-search-bar .input-group-addon,
.giacom-service-header,
.giacom-service-header-wrapper {
  background-color: #E6007E;
  background-color: var(--color-2-1) !important;
}

.text-decoration-color2-1 {
  text-decoration-color: #E6007E;
  text-decoration-color: var(--color-2-1) !important;
}

.dashboard-alert,
.giacom-grid .table .icons .fas,
.giacom-service-header .steps ul .step.active {
  color: #E6007E;
  color: var(--color-2-1) !important;
}

.giacom-btn-primary:hover,
.button--primary:hover,
.giacom-search-bar .input-group-addon:hover {
  background-color: #C71253;
  background-color: var(--color-2-2) !important;
}

.button[disabled] {
  opacity: 0.5;
}

body .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button,
body .p-radiobutton .p-radiobutton-box.p-state-active {
  background-color: var(--primary);
  background-color: var(--color-1-1) !important;
   border-color: var(--primary);
   border-color: var(--color-1-1) !important;
}

.fulfilment-section__header--error {
  background-color: #E6007E;
  background-color: var(--color-2-1) !important;
}

.fulfilment-section__header--error {
  background-color: #E6007E;
  background-color: var(--color-2-1) !important;
}

.fulfilment-section__body--error {
  border-color: #E6007E;
  border-color: var(--color-2-1) !important;
}

/* forced styles */
.color1-2-important {
  color: var(--primary-darker);
  color: var(--color-1-2) !important;
}

/* prime ng - checkbox*/
body .p-chkbox .p-chkbox-box.p-state-active {
  background-color: var(--primary);
  background-color: var(--color-1-1) !important;
  border-color: var(--primary);
  border-color: var(--color-1-1) !important;
}