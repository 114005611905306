.user-alias {
    color: $giacom-pink1;
}

.giacom-user-manage-access {
    .giacom-title {
        justify-content: initial;

        .headline {
            display: flex;
            flex-direction: column;

            .title {
                order: 1;
            }

            .information {
                order: 2;
                font-size: 16px;
            }
        }
    }

    &__panel {
        width: 655px;
        margin-bottom: 10px;

        @media screen and (max-width: 767px) {
            width: 100%;
        }

        &.panel--first {
            margin-top: 30px;
        }



        span {
            &.fa-trash-o {
                cursor: pointer;
            }
        }

        .disaster-recovery {
            float: right;
            cursor: pointer;

            a {
                color: $giacom-blue1;
            }

            span {
                &.name {
                    margin-left: 5px;
                }
            }
        }

        &.permissions {
            margin-bottom: 40px;
        }

        &__heading {
            width: inherit;
            background-color: $giacom-blue3;
            margin-bottom: 1px;
            height: 60px;
            display: inline-flex;
            align-items: center;

            .title {
                padding-left: 15px;
                @include Gotham-600;
                color: $giacom-grey2;
                order: 1;
                margin-right: auto;
            }

            .action {
                order: 2;
                margin-left: auto;
                padding-right: 15px;
            }

            button.giacom-btn-secondary {
                background-color: transparent;
            }
        }

        &__body {
            width: inherit;
            background-color: $giacom-blue3;
            padding-bottom: 40px;
            padding-top: 25px;

            .customize {
                margin-left: 10px;
                margin-right: 18px;
            }

            &__item {
                margin-bottom: 20px;

                &.row {
                    margin-left: 0;
                    margin-right: 0;
                }

                &:last-child {
                    margin-bottom: 0px;
                }

                &__title {
                    @include Gotham-600;
                    color: $giacom-grey2;
                    text-align: right;

                    &.full-width {
                        &__title {
                            text-align: left;
                        }
                    }
                }

                &__name {
                    @include Gotham-600;
                    color: $giacom-grey2;
                }

                &__address {
                    text-align: right;

                    &__customizeAddress {
                        word-wrap: break-word;
                    }
                }

                &.full-width {
                    height: 40px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid $giacom-grey3;
                    margin-top: 30px;
                    margin-bottom: 20px;
                }

                &__content {
                    word-break: break-all;

                    &__customizeName {
                        word-wrap: break-word;
                    }

                    &__rules {
                        padding: 0;
                        margin: 0;
                        margin-left: 20px;

                        li {
                            margin-bottom: 10px;
                            word-break: break-word;
                        }
                    }
                }
            }

            .full-width {
                &:first-child {
                    margin-top: 0;
                }
            }

            .alert-distribution {
                text-align: center;
                color: $giacom-pink1;
            }

            span {
                &.note {
                    color: $giacom-pink1;
                    @include Gotham-400;
                }
            }
        }
    }
}
