ul.giacom-title {
    list-style: none;
    display: inline-flex;
    padding: 0;
    justify-content: space-around;
    width: 100%;

    li {
        &.headline {
            // margin-left: 15px;
            // margin-right: 15px;
        }

        &.action {
            margin-left: auto; // margin-right: 15px;
        }
    }
}

.giacom-title {
    margin: 30px 0;
    font-size: 21px;
    color: $giacom-grey2;

    .title {
        font-size: inherit;
        @include Gotham-600;
        color: inherit;
    }

    .information {
        font-size: inherit;
        color: inherit;
        @include Gotham-400;
    }

    button.giacom-btn.add {
        margin-left: 10px;
        width: 40px;
        height: 40px;
        font-size: initial;

        .fas {
            position: relative;
            left: 0;
            top: 0;
            font-size: 16px;
            color: $giacom-white;
        }
    }
}

.giacom-sub-title {
    margin: 20px 0;
    border-bottom: 1px solid $giacom-grey3;
    padding-bottom: 10px;

    span:not(.fa) {
        font-size: 21px;
        @include Gotham-600;
        color: $giacom-grey2;
    }
}

.title-page-content {
    font-size: 21px;
    color: $giacom-grey2;
    font-weight: 600;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    ul.giacom-title {
        li {
            &.headline {
            }
        }
    }
}

@media screen and (max-width: $giacom-small) {
    .giacom-title {
        margin: 20px 0;
        font-size: 18px;
        color: $giacom-grey2;

        .title {
            font-size: inherit;
            color: inherit;
            @include Gotham-600;
        }

        .information {
            font-size: inherit;
            color: inherit;
            @include Gotham-400;
        }

        button.giacom-btn.add {
            margin-left: 5px;
        }
    }
}
