/* .custom-checkbox */

.giacom-checkbox {
    width: 20px;
    position: relative; // margin: 20px auto;
    label {
        width: 20px;
        height: 20px;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 0;
        border: 1px solid $giacom-grey5;
        background: transparent;
        &:after {
            content: '';
            width: 15px;
            height: 6px;
            position: absolute;
            top: 5px;
            left: 2px;
            border: 3px solid $giacom-pink1;
            border-top: none;
            border-right: none;
            background: transparent;
            opacity: 0;
            transform: rotate(-45deg);
        }
        &:hover::after {
            opacity: 0.3;
        }
        &.intermediate:after {
            content: '';
            width: 15px;
            height: 6px;
            position: absolute;
            top: 5px;
            left: 2px;
            border-bottom: 3px solid $giacom-pink1;
            border-top: none;
            border-right: none;
            border-left: none;
            background: transparent;
            opacity: 0;
            transform: rotate(0deg);
        }
    }
    input[type=checkbox] {
        visibility: hidden;
        &:checked+label:after {
            opacity: 1;
        }
    }
    &.giacom-checkbox--disabled {
        label,
        input {
            cursor: not-allowed;
            background-color: $giacom-grey3;
            opacity: 0.5;
        }
    }
}

.giacom-radio {
    @extend .giacom-checkbox;
    label {
        border-radius: 100%;
        width: 25px;
        height: 20px;
    }
    input[type=radio] {
        width:20px;
        visibility: hidden;
        &:checked+label:after {
            opacity: 1;
        }
    }
}

.radioinline {
    margin-right: 10px;
    display: inline-block;
}
.group-box {
    padding-top: 10px;
}

/* end .custom-checkbox */
