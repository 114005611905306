$input-search-width: 20.313em;
.giacom-search-bar {
    float: right;
    font-size: initial;

    input {
        height: 40px;
        font-size: 16px;
        @include Gotham-400;
        border-radius: 0;
        border: 1px solid $giacom-grey3;
        padding-left: 10px;

        &.form-control {
            box-shadow: none;

            &:focus {
                border: 1px solid $giacom-grey3;
                box-shadow: none;
                font-size: 16px;
                color: $giacom-grey2;
            }
        }

        &::placeholder {
            font-size: 16px;
            color: $giacom-grey5;
        }
    }

    .input-group {
        width: 100%;

        input {
            width: $input-search-width;
            float: right;
        }
    }

    .input-group-addon {
        width: 2.5em;
        height: 2.5em;
        background-color: $giacom-pink1;
        color: $giacom-white;
        border: none;
        border-radius: 0;

        .fa-search {
            font-size: inherit;
            color: inherit;
        }

        &:hover {
            cursor: pointer;
            background-color: $giacom-pink2;
        }
    }

    ul.suggestion-list {
        width: $input-search-width + 2.5em;
        margin: 0;
        margin-top: 1px;
        list-style: none;
        padding: 0;
        position: absolute;
        right: 0;
        top: 40px;
        background-color: $giacom-white;
        border: 1px solid $giacom-grey3;
        z-index: 100;

        li {
            font-size: 16px;
            color: $giacom-grey5;
            @include Gotham-400;
            padding: 10px 15px 10px 10px;

            &:hover {
                cursor: pointer;
            }

            span {
                word-wrap: break-word;
                white-space: inherit;
            }
        }
    }

    .clear {
        background-color: $giacom-grey3;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        position: absolute;
        right: 50px;
        top: 8px;
        z-index: 10;

        .fa-times {
            font-size: 16px;
            color: $giacom-grey1;
            position: absolute;
            top: 3px;
            left: 5px;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    $input-search-width: 18.750em;
    .giacom-search-bar {
        width: initial;
        margin-right: 0;

        .input-group {
            input {
                width: $input-search-width;
            }
        }

        ul.suggestion-list {
            width: $input-search-width + 2.5em;
        }
    }
}

@media screen and (max-width: $giacom-small) {
    .giacom-search-bar .input-group {
        width: 2.5em;
        height: 2.5em;
        float: right;
    }
    .giacom-search-bar {
        float: right;
        width: 10%;

        .input-group {
            ul.suggestion-list {
                position: absolute;
                width: 100%;
                right: 0;
                top: 2.5em;
            }

            .clear {
                right: 3.125em;
                top: 0.5em;

                .fa-times {
                    top: 0;
                    left: 7px;
                }
            }

            input.form-control {
                width: 100%;
            }
        }
    }
    li.fullSearch {
        .giacom-search-bar {
            width: 100%;

            .input-group {
                width: 100%;
            }
        }
    }
}

.giacom-search-result {
    font-size: initial;
    padding: 0.625em 0.938em 1.875em 0.938em;
    font-size: 1.125em;
    color: $giacom-grey2;

    .result {
        @include Gotham-400;
        font-size: inherit;
    }

    .keyword {
        @include Gotham-600;
        font-size: inherit;
    }

    .link {
        font-size: 16px;

        a {
            @include Gotham-400;
            color: $giacom-blue1;

            &:hover {
                cursor: pointer;
            }
        }
    }
}
