
.hidden {
    display: none;
    visibility: false;
}

@media (min-width: 450px) {
    .daterangepicker {
        z-index: 3000;
        border-radius: 4px;
        box-shadow: 0px 2px 2px 2px #888;
        max-width: 450px;
        min-width: 450px;
    }
}

@media (max-width: 450px) {
    .daterangepicker {
        z-index: 3000;
        border-radius: 4px;
        box-shadow: 0px 2px 2px 2px #888;
        max-width: 270px;
    }
    .text-center .pull-right {
        float: none !important;
    }
    .ranges {
        display: none;
    }
}

.daterangepicker .calendar {
    margin: 4px;
    float: left;
    border-radius: 4px !important;
}

.applyBtn {
    margin: 4px;
}

.daterangepicker .flush {
    padding: 0 !important;
    margin: 0 !important;
}

.daterangepicker .flush-bottom {
    padding-bottom: 0 !important;
}

.daterangepicker .flush-left {
    padding-left: 0 !important;
}

.daterangepicker .flush-right {
    padding-right: 0 !important;
}

.daterangepicker .flush-half--left {
    padding-left: 4px !important;
}

.daterangepicker .flush-half--right {
    padding-right: 4px !important;
}

.daterangepicker .nudge-top {
    top: 5px;
}

.daterangepicker th {
    margin: 1px !important;
    padding: 1px !important;
    text-align: center;
    border-radius: 4px !important;
}

.daterangepicker td {
    font-size: 14px;
    height: 20px;
    width: 20px;
    text-align: center;
    padding: 2px !important;
    margin: 1px !important;
    border-radius: 4px !important;
    white-space: nowrap;
    text-align: center;
}

.daterangepicker .btn.btn-flat {
    border: none;
    background: transparent;
    margin: 3px !important;
    padding: 1px !important;
}

.daterangepicker .off {
    color: #A2A2A2;
}

.daterangepicker table {
    border-spacing: 0;
    border-collapse: collapse;
}

.daterangepicker td,
.daterangepicker th {
    padding: 0;
}

.daterangepicker .clickable {
    cursor: pointer;
}

.daterangepicker .clickable-link {
    color: #337AB7;
}

.daterangepicker .clickable.disabled {
    pointer-events: none;
    color: #A2A2A2;
}

.daterangepicker label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 600;
}

.daterangepicker .btn-link {
    padding: 1px 6px 1px 6px !important;
}
