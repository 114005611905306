// base font size is 10px
@use "sass:math";

$cm-heading-base-size: 1.6rem;
$cm-text-base-size: 1.4rem;

$cm-heading-scale-ratio: 1.12;
$cm-text-scale-ratio: 1.1;

/* for use with heading typography - e.g. h1, h2 etc */
$heading-xs: math.div(math.div($cm-heading-base-size, $cm-heading-scale-ratio), $cm-heading-scale-ratio);
$heading-sm: $heading-xs * $cm-heading-scale-ratio;
$heading-md: $heading-sm * $cm-heading-scale-ratio * $cm-heading-scale-ratio;
$heading-lg: $heading-md * $cm-heading-scale-ratio;
$heading-xl: $heading-lg * $cm-heading-scale-ratio;

/* for use with non heading typography - e.g. p, ul, table etc */
$cm-text-xs: math.div(math.div($cm-text-base-size, $cm-text-scale-ratio), $cm-text-scale-ratio);
$cm-text-sm: $cm-text-xs * $cm-text-scale-ratio;
$cm-text-md: $cm-text-sm * $cm-text-scale-ratio * $cm-text-scale-ratio;
$cm-text-lg: $cm-text-md * $cm-text-scale-ratio;
$cm-text-xl: $cm-text-lg * $cm-text-scale-ratio;

/* font-weights */
$cm-light-font-weight: 100;
$cm-normal-font-weight: 400;
$cm-heavy-font-weight: 600;
$cm-xheavy-font-weight: 700;

/* line-heights */
$cm-line-height: 1.4;
