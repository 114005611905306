@import './mixin/mixin';
@import './shared/shared';
@import './pages/pages';
@import '../../assets/css/variables/index.scss';
@import '../unity-styles/unity-bootstrap';
@import './themes/ngx-bootstrap-theme.scss';

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    letter-spacing: 0;
    font-style: normal;
    text-rendering: optimizeLegibility;
}

body.giacom {
    @include Gotham-400;
    font-size: 16px;
    word-wrap: break-word;
    overflow-x: hidden;
    color: $giacom-grey2-fixed-for-body-text;
}

body.disableBodyScroll {
    overflow: hidden !important;
}

body:is(.app-customer-page, .invoices-page) {
    background-color: $white;
}

a:hover {
    cursor: pointer;
}

li.active > a:hover {
    cursor: auto;
}

a,
a:hover,
a:visited,
a:active,
a:focus {
    &.cannotClick {
        pointer-events: none;
        cursor: default;
        /*plain arrow*/
        text-decoration: none;
        /*No underline or something*/
        color: #0077CC;
        /*Default link colour*/
    }
}

.margin-bottom-standard {
    margin-bottom: 15px;
}

.margin-top-standard {
    margin-top: 15px;
}

.margin-right-standard {
    margin-right: 15px;
}

.margin-bottom-half {
    margin-bottom: 8px;
}

.dropdown-backdrop {
    display: none !important;
}

.dashboard-alert {
    color: $giacom-pink1;
}

.cm-loading {
    height: 100%;
    min-height: 100px;
    width: 100%;
    position: relative;

    img {
        top: 50%;
        left: 50%;
        height: 50px;
        width: 50px;
        margin-top: -25px;
        margin-left: -25px;
        position: absolute;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: $giacom-grey3;
        box-shadow: 0 0 1px $giacom-grey4;
        -webkit-box-shadow: 0 0 1px $giacom-grey4;
    }
}

.ct-hint {
    .popover {
        max-width: unset !important;
    }
}

.padding-right-15 {
    padding-right: 15px;
}

.margin-bottom-24px {
    margin-bottom: 24px;
}

.control-validation-error {
    margin-top: 5px;
    color: var(--danger);
    font-size: 0.75rem;
}
