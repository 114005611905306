.giacom-edit-profile {
    .giacom-dashboard {
        background-color: transparent;
        margin-bottom: 20px;

        .button-group {
            margin: 0;

            button:first-child {
                margin-right: -1px;
            }

            @media screen and (max-width: 767px) {
                button.giacom-btn-grey {
                    width: 100%;
                }
            }
        }

        @media screen and (min-height: 895px) {
            position: initial;
        }
    }

    &.giacom-popup .modal-dialog.customise {
        width: 700px;
        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }

    .giacom-dropdown-white {
        .dropdown-toggle {
            width: 100%;
        }
    }

    .address-form,
    .contact-form {
        height: 450px;
    }

    .giacom-form.form-horizontal {
        .form-group {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .message {
        @include Gotham-400;
        color: $giacom-grey2;
    }

    .p-autocomplete-items {
        list-style-type: none;
        padding-left: 0;
        border: 1px solid $giacom-border-input;

        li {
            padding: 8px 10px;
            cursor: pointer;

            &:hover {
                background-color: $giacom-grey4;
                color: $giacom-blue1;
            }
        }
    }

    .p-state-highlight {
        color: $giacom-blue1;
        background-color: $giacom-grey4;
    }

    .profile-readonly-label {
        padding-left: 28px;
        padding-top: 10px;
    }

    .profile-helptext {
        padding-top: 10px;
        padding-right: 20px;
    }
}
