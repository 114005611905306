.giacom-user-edit-permission {
    width: 675px;
    margin-top: 20px;

    .giacom-title {
        margin-top: 15px;
        justify-content: initial;
    }

    .giacom-breadcrumb {
        background-color: transparent;
        color: $giacom-blue2;
        margin-bottom: 5px;
    }

    &__header {
        padding-bottom: 15px;
        border-bottom: 1px solid $giacom-grey3;
        margin-bottom: 30px;

        .title {
            font-size: 18px;
            @include Gotham-600;
            color: $giacom-grey2;
        }
    }

    .button-save {
        margin-bottom: 20px;
        float: right;
    }

    &__body {
        .title {
            @include Gotham-600;
            color: $giacom-grey2;
        }

        .button-group {
            text-align: right;
            margin-bottom: 50px;
        }
    }

    &__item {
        padding-bottom: 20px;
        border-bottom: 1px solid $giacom-grey3;
        margin-bottom: 20px;

        ul {
            &.permission-access,
            &.permission-manage {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                flex-direction: column;

                li {
                    margin-bottom: 10px;
                }
            }
        }

        &__customers {
            .header .title {
                font-size: 18px;
            }

            &__access-own-customers {
                margin-top: 15px;
            }

            .access-own-customers {
                margin-top: 15px;
            }
        }

        .giacom-option-customized {
            display: inline-flex;

            .name {
                margin: 0 10px;
                width: calc(100% - 20px);
            }
        }

        &__header-options {
            margin-top: 15px;
            margin-bottom: 15px;
        }

        .permission-access {
            &__item {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                flex-direction: column;
                margin-left: 30px;
            }

            &__list {
                max-height: 400px;
                overflow-x: hidden;
                overflow-y: auto;
            }
        }

        &__assign-roles {
            &__headline {
                margin-bottom: 10px;

                span {
                    &.fa-times {
                        cursor: pointer;
                        color: $giacom-pink1;
                        font-size: 16px;
                    }
                }
            }

            &__option {
                &__group {
                    display: inline-flex;
                    align-items: center;
                }

                .role-explained {
                    margin-left: 15px;

                    a {
                        color: $giacom-blue1;
                        @include Gotham-400;
                    }
                }
            }

            .location-name {
                display: inline-block;
                width: calc(100% - 160px);
            }

            .ip-range {
                display: inline-block;
                width: calc(100% - 42px);
                @include Gotham-600;
            }

            .allow-access {
                display: inline-flex;

                .name {
                    margin: 0 10px;
                }
            }

            button.dropdown-toggle {
                width: 200px;
                text-align: left;
            }

            ul.dropdown-menu {
                width: 200px;
                margin-top: -1px;
            }

            .giacom-dropdown-header {
                border: 1px solid $giacom-border-input;

                button {
                    background-color: $giacom-white;
                    color: $giacom-grey2;

                    &:hover,
                    &:focus {
                        background-color: $giacom-white;
                        color: $giacom-grey2;
                    }
                }

                ul.dropdown-menu {
                    border-left-color: $giacom-border-input;
                    border-bottom-color: $giacom-border-input;
                    border-right-color: $giacom-border-input;

                    li a {
                        color: $giacom-grey2;
                        @include Gotham-300;
                    }
                }
            }
        }
    }
}
