.giacom-manage-access {
    &__body {
        .giacom-title {
            margin-top: 20px;
            margin-bottom: 20px;

            .headline {
                margin-right: auto;
            }

            .information {
                font-size: 18px;
            }
        }

        ul {
            // display: table-row;
            list-style: none;
            padding: 0;
            margin: 0;
            margin-bottom: 5px;
        }

        &__item {
            background-color: $giacom-grey4;
            position: relative;

            span,
            a {
                margin-left: 10px;
            }

            li:not(.dropdown-menu) {
                display: table-cell;
                height: 55px;
            }

            &__icon {
                color: $giacom-blue2;
                vertical-align: middle;
            }

            &__name {
                vertical-align: middle;

                a {
                    vertical-align: middle;
                    color: $giacom-blue1;
                    @include Gotham-600;

                    &:hover,
                    &:focus {
                        color: $giacom-blue1;
                        @include Gotham-600;
                    }

                    &:focus {
                        outline: none;
                        outline-offset: 0;
                    }
                }
            }

            &__role {
                @include Gotham-400;
                vertical-align: middle;
            }

            &__action {
                position: absolute;
                right: 10px;
                top: 9px;

                div.dropdown {
                    width: 220px;
                }

                .giacom-dropdown {
                    button {
                        //background-color: $giacom-blue1;
                        &.dropdown-toggle {
                            width: 100%;

                            span {
                                &.selected-name,
                                &.fas {
                                    margin-left: 0;
                                }
                            }
                        }
                    }

                    ul.dropdown-menu {
                        /*border-left: 1px solid $giacom-blue1;
                        border-right: 1px solid $giacom-blue1;
                        border-bottom: 1px solid $giacom-blue1;*/
                        width: 100%;

                        li {
                            height: auto;
                            width: 206px;
                        }

                        a {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}

.manage-access-details {
    .giacom-breadcrumb {
        background-color: transparent;
        color: $giacom-blue1;
    }
}

@media screen and (max-width: 1023px) {
    .giacom-manage-access__body__item__name {
        max-width: 310px;
        padding-left: 10px;

        a {
            margin-left: 0;
        }
    }
}
