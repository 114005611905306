.assign-license {
    .form-title {
        padding-bottom: 15px;
    }
    .license-name {
        padding-top: 5px;
        margin-bottom: 10px;
        text-align: right;
    }
    .essential {
        border-bottom: 1px solid $giacom-grey3;
        padding: 15px 0 0;
        .license-name {
            font-weight: 600;
        }
    }
    .extra {
        padding-top: 15px;
        border-bottom: 1px solid $giacom-grey3;
        .license-name {
            color: $giacom-grey2;
        }
    }
    .assignments {}
    .btn-assign {
        margin: 20px 0;
        float: right;
    }
    .dropdown-toggle,
    .giacom-dropdown-grid,
    .dropdown-menu {
        width: 100%;
    }
    .customize-assign-license {
        max-height: 250px;
        overflow-x: hidden;
        overflow-y: auto;
        margin-bottom: 15px;
    }
    @media screen and (max-width: 767px) {
        .license-name {
            text-align: left;
        }
        .btn-assign {
            width: 100%;
        }
    }
}
