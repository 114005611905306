@import "service_header";
@import "service_form";
@import "service_options";
@import "service_tenant";
@import "service_text_field";
@import "service_related_links";
@import "service_select_product";
@import "service_complete";
@import "assign-license";
@import "select_service";
@import "email_security";
@import "hosted_exchange/hosted_exchange";
@import "azure";
@import "bitdefender";
@import "hosted_voice";
@import "appinteract";
@import "hosted_voice_hardware";
@import "message-labs";

.giacom-add-service {
  .giacom-add-service-wrapper{
    background-color: $giacom-blue1;
  }
  .content {
    display: inline-block;
    .form-content {
      padding-top: 30px;
      order: 1;
      flex: 1 1;
      flex-direction: column;
      .form-title {
        order: 1;
        width: 100%;
      }
      .form-body {
        order: 2;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  $service-width: 13.813em;
  .giacom-add-service {
    .content {
      flex-direction: column;
    }
  }
}

.toolOver .popover {
  max-width:unset!important
}

 
