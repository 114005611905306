.giacom-support {
    .giacom-support-network {
        height: 130px;
        .giacom-support__panel__body {
            display: flex;
            flex-direction: row;
            align-items: center;
            &__network-status {
                order: 1;
                flex-grow: 2;
                font-size: 25px;
                color: $giacom-grey2;
            }
            &__action {
                order: 2;
                flex-basis: 100px;
            }
        }
        &__display {
            height: 190px;
            text-align: center;
            width: 100%;
            border: 1px solid $giacom-grey3;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            color: $giacom-blue1;
            &__header {
                order: 1;
                font-size: 20px;
                margin-bottom: 10px;
            }
            &__icon {
                order: 2;
                margin-bottom: 10px;
                .fas {
                    font-size: 30px;
                }
            }
            &__content {
                order: 3;
                font-size: 20px;
                margin-bottom: 10px;
            }
            &__link {
                order: 4;
                font-size: 16px;
                margin-bottom: 10px;
            }
        }
        &__display_tiled {
            height: 190px;
            text-align: center;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: normal;
            align-items: center;
            flex-wrap: wrap;
            overflow-y: auto;
            color: #000;
            &__header {
                order: 1;
                font-size: 20px;
                margin-bottom: 10px;
            }
            &__icon {
                order: 2;
                margin-bottom: 10px;
                .fas {
                    font-size: 30px;
                }
            }
            &__content {
                order: 3;
                font-size: 20px;
                margin-bottom: 10px;
            }
            &__link {
                order: 4;
                font-size: 16px;
                margin-bottom: 10px;
            }
        }
    }
}
