/*** ANGULAR MATERIAL COLOURS ***/
$giacom-blue-map: (
    50 : #E3F6F8,
    100 : #B9E9ED,
    200 : #8ADBE1,
    300 : #5BCCD5,
    400 : #37C1CC,
    500 : #14B6C3,
    600 : #12AFBD,
    700 : #0EA6B5,
    800 : #0B9EAE,
    900 : #068EA1,
    A100 : #CCF8FF,
    A200 : #99F1FF,
    A400 : #66EAFF,
    A700 : #4DE7FF,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #FFFFFF,
        800 : #FFFFFF,
        900 : #FFFFFF,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$giacom-pink-map: (
    50 : #FDE0EF,
    100 : #FAB3D6,
    200 : #F680BB,
    300 : #F24D9F,
    400 : #F0268B,
    500 : #ED0076,
    600 : #EB006E,
    700 : #E80063,
    800 : #E50059,
    900 : #E00046,
    A100 : #FFFFFF,
    A200 : #FFD4DF,
    A400 : #FFA1B8,
    A700 : #FF88A5,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #FFFFFF,
        500 : #FFFFFF,
        600 : #FFFFFF,
        700 : #FFFFFF,
        800 : #FFFFFF,
        900 : #FFFFFF,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// GIACOM collection
$giacom-blue1: #00B8C5;
$giacom-blue1-slight: #01B8C5;
$giacom-blue2: #008A94;
$giacom-blue2-slight: #018A94;
$giacom-blue3: #E6F8F9;
$giacom-blue3-slight: #E7F8F9;


$giacom-pink1: #EB1662;
$giacom-pink1-slight: #EB2662;
$giacom-pink2: #D8145E;
$giacom-pink2-slight: #D8245E;
$giacom-pink3: #FDE8F0;
$giacom-pink3-slight: #FEE8F0;

$giacom-yellow1: #FFC100;
$giacom-yellow1-slight: #FFC200;
$giacom-yellow2: #FFF6D9;
$giacom-yellow2-slight: #FFF7D9;

$giacom-grey1: #314049;
$giacom-grey2: #253036;
$giacom-grey2-fixed-for-body-text: #253037;
$giacom-grey3: #E4E4E4;
$giacom-grey4: #F2F2F2;
$giacom-grey5: #999999;
$giacom-grey6: #AAAAAA;
$giacom-grey7: #222;
$giacom-grey8: #757575;
$giacom-grey9: #00000020;
$giacom-grey10: #dcdcdc;


$giacom-white: #FFFFFF;
$giacom-black: #000;
$giacom-border-input: #ccc;
$giacom-brown: #800;
$giacom-green: #DEDC00;


$giacom-purple: #E8DEE9;
$giacom-dark-purple: #351436;
$giacom-dark-purple2: #99338A;

$giacom-gradient-purple: linear-gradient(67.88deg, #67236A 26.94%, #3C173E 68.12%);
