@import 'login';
@import 'dashboard';
@import 'customer';
@import 'cust_user';
@import 'reset_password';
@import 'add-service/add-service';
@import 'customer-services/customer_services';
@import 'market_place';
@import 'promotion';
@import 'quick_links';
@import 'change_password';
@import 'your_account';
@import 'knowledge-base';
@import 'delete-service/_delete_service';
@import 'billing';
@import 'manage-service/_manage_service';
@import 'payment_options';
@import 'credit';
@import 'add-credit';
@import 'user_manage_access';
@import 'user_edit_permission';
@import 'manage_access';
@import 'edit_profile';
@import 'address_box';
@import 'dns';
@import 'distribution_group';
@import 'whitelabelling';
@import 'support/support';
@import 'end_user/end_user';
@import 'add_admin_user/add_admin_user';
@import 'azure_resource_groups';
@import 'error';
@import 'psa-match';
@import 'shared/variables';

.giacom-page {
  min-height: calc(100vh - 120px);
  padding-bottom: 60px;

  .page-border {
    height: 10px;
  }

  &-title {
    font-size: 3.8rem;
    font-weight: 600;
    margin: 3.6rem 0;

    @media screen and (max-width: $giacom-large) {
      font-size: 3.2rem;
      margin-bottom: 2rem;
    }

    @media screen and (max-width: $giacom-small) {
      display: none;
    }
  }
}
