.service-select-product {
    .panel-group .panel-heading + .panel-collapse > .panel-body {
        border-top: none;
    }

    .panel-group .panel {
        border-radius: 0px;
    }

    .panel {
        border: 0;
        box-shadow: none;
        border-radius: 0px;
    }

    .select-product {
        .panel-heading {
            padding: 0.938em 0.938em 0.938em 0;
            border-top: 1px solid $giacom-grey3;
        }

        .panel-title {
            .title-license-amount {
                display: inline-block;
                width: calc(100% - 20px);
            }

            .title {
                color: $giacom-grey2;
                font-size: 1.125em;
                @include Gotham-600;
            }

            .title-alternative {
                color: $giacom-pink1;
                font-size: 1.125em;
                @include Gotham-600;
            }

            .existing-license {
                font-size: 16px;
                color: $giacom-grey2;
                @include Gotham-400;
            }

            .fas {
                padding-left: 0;
                color: $giacom-pink1;
                font-size: 1.313em;
            }
        }

        .panel-body {
            padding: 0;
            margin-top: 20px;
        }

        .common {
            .amount-period {
                margin-bottom: 0;
                display: inline-flex;
                justify-content: flex-end;
                align-items: center;
                align-content: space-between;

                .amount {
                    order: 1;
                    font-size: 16px;
                    @include Gotham-600;
                    padding-right: 0.5em;
                    color: $giacom-grey2;
                }

                .period {
                    order: 2;
                    padding-right: 1.250em;
                    @include Gotham-400;
                    color: $giacom-grey2;
                }

                input {
                    order: 3;
                    text-align: center;
                    width: 6.4em;
                }

                button {
                    order: 4;
                }
            }

            .name {
                @include Gotham-400;
                color: $giacom-grey2;
            }
        }

        .product {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            @extend .common;

            .product-name {
                width: 55%;
                margin-left: 0.938em;
                order: 1;
                color: $giacom-grey2;
            }

            .amount-period {
                width: 45%;
                margin-right: 0.938em;
                order: 2;
            }

            .action {
                order: 3;
                margin-right: 0.938em;

                button {
                    flex-basis: 90px;
                }
            }

            .product-hint {
                order: 3;
                width: 0;

                .hint {
                    margin-left: 0 !important;
                }
            }
        }

        .upgrade-service {
            margin-bottom: 30px;

            a {
                color: $giacom-blue1;
            }
        }

        .add-ons-message {
            font-size: 16px;
            @include Gotham-400;
            color: $giacom-grey5;
            padding-bottom: 10px;
        }

        .add-ons {
            @extend .common;

            .add-ons-name {
                width: 55%;
                margin-left: 0.938em;
            }

            .amount-period {
                width: 45%;
                margin-right: 0.938em;
            }

            .item {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 0.5em;

                &:last-child {
                    padding-bottom: 3em;
                }

                .action {
                    order: 3;
                    margin-right: 0.938em;

                    button {
                        flex-basis: 90px;
                    }
                }

                &.modify-user-cap {
                    .add-ons-name {
                        .name {
                            align-self: flex-start;
                        }

                        .userCap {
                            order: 3;

                            label {
                                margin-top: 10px;
                            }
                        }
                    }

                    .amount-period {
                        align-self: flex-start;
                    }
                }
            }

            .reason {
                padding-left: 0.625em;

                a {
                    color: $giacom-blue1;
                }
            }

            .add-ons-name {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                align-content: center;

                .name {
                    order: 1;
                    width: 100%; //was 80%
                    align-self: center;
                }

                .reason {
                    order: 2;
                    width: 20%;
                }
            }
        }

        .add-ons .add-ons-name,
        .product .product-name {
            .error {
                &.name,
                &.hint {
                    color: $giacom-pink1;
                }

                &.name {
                    font-weight: 400;
                    order: 1;
                    max-width: 400px;
                }

                &.hint {
                    // margin-left: 0;
                    // margin-right: 5px;
                    order: 2;
                }
            }

            .reason {
                order: 3;
                width: 130px;

                .hint {
                    display: inline-block;
                    width: 72px;
                    color: $giacom-blue1;
                }
            }
        }
    }

    .order-total {
        border-top: 5px solid $giacom-grey3;
        padding-bottom: 3.125em;
        height: 6.25em;

        div {
            padding: 15px 0 0 0;
        }

        .name {
            font-size: 16px;
            @include Gotham-600;
        }

        .amount-period {
            margin-bottom: 0;
            display: inline-flex;
            justify-content: flex-end;
            align-items: center;
            align-content: space-between;

            .amount {
                order: 1;
                font-size: 16px;
                @include Gotham-600;
                padding-right: 0.5em;
            }

            .period {
                order: 2;
                padding-right: 0.852em;
                @include Gotham-400;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .service-select-product {
        .select-product {
            .row,
            .item {
                display: flex;
                flex-wrap: wrap;

                div {
                    &:first-child {
                        width: 100%;
                    }

                    &:last-child {
                        width: 100%;
                    }
                }
            }

            .add-ons {
                .add-ons-name {
                    padding-bottom: 10px;
                }
            }

            .product-name {
                padding-bottom: 0.625em;
            }
        }

        .order-total {
            display: inline-flex;
            width: 100%;

            div {
                &:first-child {
                    order: 1;
                    width: 70%;
                }

                &:last-child {
                    order: 2;
                    width: 30%;
                }
            }
        }

        .select-product {
            .add-ons {
                .add-ons-name {
                    justify-content: initial;
                    align-items: initial;
                    align-content: initial;

                    .name {
                        width: initial; // max-width: 767px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .service-select-product .select-product {
        .add-ons .add-ons-name,
        .product .product-name {
            display: flex;

            .name.error {
            }

            .error.hint {
            }
        }
    }
}

@media screen and (max-width: 443px) {
    .select-product {

        .amount-period {
            margin-bottom: 0;
            display: inline-flex;
            justify-content: flex-end;
            align-items: center;
            align-content: space-between;

            .amount {
                order: 1;
                //@include OpenSans-400;
                //font-size: 10px !important;
                //padding-right: 0.5em;
                color: $giacom-grey2;
            }

            .period {
                order: 2;
                //padding-right: 1.250em;
                //@include OpenSans-400;
                //font-size: 10px !important;
                color: $giacom-grey2;
            }

            input {
                order: 3;
                text-align: center;
                width: 1.4em;
            }

            button {
                order: 4;
            }
        }
    }


}






