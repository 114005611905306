.giacom-customer-service-table {
    font-size: 16px;

    .service-item {
        width: 100%;
        display: inline-block;

        .product-name {
            @include Gotham-600;
            word-wrap: break-word;
            white-space: normal;
            display: inline-block;
        }

        .product-name-add {
            @include Gotham-400;
            word-wrap: break-word;
            white-space: normal;
            margin-top: 8px;
        }

        .adds-on {
            word-wrap: break-word;
            white-space: normal;
            margin-left: 5px;
        }

        .no-addOn {
            // margin-top: 10px;
        }
    }

    .pending-activation {
        font-size: 16px;
        @include Gotham-400;
        color: $giacom-pink1;
        text-align: right;

        span {
            &.fa-clock-o {
                margin-right: 5px;
            }
        }

        &.distribution-status {
            text-align: left;
        }

        .pending-activation-status {
            display: inline-flex;

            .status-pending {
                order: 1;
            }

            .status {
                order: 2;
                width: 200px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .giacom-customer-service-table {
        .giacom-dropdown-grid {
            button {
                min-width: 0;
                width: 40px;
                text-align: center;

                span.fas {
                    //margin: 0 3px 0 0;
                }
            }

            &.dropdown-full-width {
                button {
                    width: 100%;
                }
            }
        }
    }
}
