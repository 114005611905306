@import '../scss/shared/_colors.scss';
@import '../scss/shared/_fonts.scss';

popover-container.popover {
    background-color: $giacom-dark-purple;
    border-radius: 4px;
    width: fit-content;
    max-width: 300px;
    @include Gotham-400;

    .popover-arrow.arrow {
        display: none;
    }

    .popover-content {
        padding: 10px;
        white-space: pre-wrap;
        color: $giacom-white;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
    }
}
