@import '../styles/variables/colors';
@import '../../assets/css/variables/colors';

$whitelabel-color-primary-key: map-get($cm-white-label-color-primary-keys, $cm-color-variant-regular);
$whitelabel-color-primary-lighter-key: map-get($cm-white-label-color-primary-keys, $cm-color-variant-lighter);
$whitelabel-color-primary-darker-key: map-get($cm-white-label-color-primary-keys, $cm-color-variant-darker);
$whitelabel-color-primary-near-white-key: map-get($cm-white-label-color-primary-keys, $cm-color-variant-near-white);
$whitelabel-color-1-t-key: map-get($cm-white-label-color-primary-keys, $cm-color-variant-variable-text);
$whitelabel-color-primary-lightest-key: map-get($cm-white-label-color-primary-keys, $cm-color-variant-lightest);
$whitelabel-color-secondary-key: map-get($cm-white-label-color-secondary-keys, $cm-color-variant-regular);
$whitelabel-color-secondary-darker-key: map-get($cm-white-label-color-secondary-keys, $cm-color-variant-darker);

:root {
  @each $varName, $varValue in $cm-white-label-default-colors {
    --#{$varName}: #{map-get($cm-white-label-default-colors, $varName)};
  }
}

.background-color5-1 {
  @include setWhitelabelColorWithFallback('background-color', $whitelabel-color-primary-lighter-key);
}

.background-color5-2 {
  @include setWhitelabelColorWithFallback('background-color', $whitelabel-color-primary-lighter-key);
}

.background-hover-color1-1:hover {
  @include setWhitelabelColorWithFallback('background-color', $whitelabel-color-primary-key);
}

.hover-color1-1:hover {
  @include setWhitelabelColorWithFallback('color', $whitelabel-color-primary-key);
}

.border-color1-1 {
  @include setWhitelabelColorWithFallback('border-color', $whitelabel-color-primary-key);
}

.bottom-border-color1-1 {
  @include setWhitelabelColorWithFallback('border-bottom-color', $whitelabel-color-primary-key);
}

.giacom-btn-secondary,
.button--secondary,
.giacom-panel .information span.fas {
  @include setWhitelabelColorWithFallback('background-color', $whitelabel-color-primary-key);
}

.giacom-dropdown-header ul.dropdown-menu li,
.giacom-dropdown-header ul.dropdown-menu li:hover {
  @include setWhitelabelColorWithFallback('color', $whitelabel-color-primary-key);
}

.giacom-dropdown-grid ul.dropdown-menu {
  border-left: 1px solid map-get($cm-white-label-default-colors, $whitelabel-color-primary-key);
  border-right: 1px solid map-get($cm-white-label-default-colors, $whitelabel-color-primary-key);
  border-bottom: 1px solid map-get($cm-white-label-default-colors, $whitelabel-color-primary-key);
}

.giacom-billing .giacom-stacked-bar-chart .btn-group.month .btn {
  @include setWhitelabelColorWithFallback('border-color', $whitelabel-color-primary-key);
}

.giacom-btn-secondary-alt,
.cross-sell__placeholder-container--traversed {
  @include setWhitelabelColorWithFallback('color', $whitelabel-color-primary-key);
  @include setWhitelabelColorWithFallback('border-color', $whitelabel-color-primary-key);
}

.giacom-select-service .logo img[src$=".png"] {
  @include setWhitelabelColorWithFallback('background-color', $whitelabel-color-primary-key);
}

.color1-2 {
  @include setWhitelabelColorWithFallback('color', $whitelabel-color-primary-darker-key);
}

.giacom-billing .iTable.by-service .col-name {
  @include setWhitelabelColorWithFallback('color', $whitelabel-color-primary-key);
}

.giacom-dashboard .box-shadow1-2,
.giacom-panel-form.panel {
  box-shadow: 3px 3px 5px map-get($cm-white-label-default-colors, $whitelabel-color-primary-darker-key) !important;
  box-shadow: 3px 3px 5px var(--color1-2) !important;
}

.border-color1-2 {
  @include setWhitelabelColorWithFallback('border-color', $whitelabel-color-primary-darker-key);
}

.color1-3 {
  @include setWhitelabelColorWithFallback('color', $whitelabel-color-primary-near-white-key);
}

.background-color1-3,
.hover-background-color1-3:hover {
  @include setWhitelabelColorWithFallback('background-color', $whitelabel-color-primary-near-white-key);
}

.color2-1 {
  @include setWhitelabelColorWithFallback('color', $whitelabel-color-secondary-key);
}

.color2-2 {
  @include setWhitelabelColorWithFallback('color', $whitelabel-color-secondary-darker-key);
}

.color5-1 {
  @include setWhitelabelColorWithFallback('color', $whitelabel-color-primary-lighter-key);
}

.color5-2 {
  @include setWhitelabelColorWithFallback('color', $whitelabel-color-primary-lightest-key);
}

.fill1-1 {
  @include setWhitelabelColorWithFallback('fill', $whitelabel-color-primary-key);
}

.fill2-1 {
  @include setWhitelabelColorWithFallback('fill', $whitelabel-color-secondary-key);
}

.background-color1-1 svg {
  @include setWhitelabelColorWithFallback('fill', $whitelabel-color-1-t-key);
}

.color1-1,
.giacom-billing .paid,
.giacom-breadcrumb.no-background,
.giacom-customer-service-suggestion ul.content .list-activity span a,
.giacom-manage-access__body__item__name a,
.giacom-market-place .detail,
.giacom-btn-clear,
.giacom-popup .popup-title .upload,
.giacom-support .giacom-support-network__display,
.giacom-support-knowledgebase-result__list li a,
.giacom-support-tickets__grid__item__issue__name a,
.giacom-support__panel__body a,
.giacom-your-account .table tbody tr td:first-child a,
.service-related-links ul li a,
ul.giacom-select-service li span.fas,
.stepper-item__label--completed {
  @include setWhitelabelColorWithFallback('color', $whitelabel-color-primary-key);
}

.giacom-add-service .giacom-add-service-wrapper,
.giacom-page-wrapper .giacom-breadcrumb,
.giacom-add-service .giacom-breadcrumb,
.giacom-customer-user,
.giacom-customer-user .giacom-breadcrumb,
.giacom-login.giacom-page,
.giacom-pre-dashboard .carousel-control,
.giacom-pre-dashboard .carousel-indicators .active,
.giacom-pre-dashboard.giacom-page,
.giacom-sub-navigation__breadcrumb,
.giacom-sub-navigation__breadcrumb .giacom-breadcrumb {
  @include setWhitelabelColorWithFallback('background-color', $whitelabel-color-primary-key, false);
}

.stepper-item__circle--selected {
  @include setWhitelabelColorWithFallback('background-color', $whitelabel-color-primary-key, true);
}

.giacom-sub-navigation__breadcrumb .giacom-breadcrumb.no-background {
  background-color: transparent;
}

.giacom-btn-no-background.giacom-btn-secondary.btn,
.giacom-btn-no-background.giacom-btn-secondary.btn:hover {
  @include setWhitelabelColorWithFallback('color', $whitelabel-color-primary-key);
  @include setWhitelabelColorWithFallback('border-color', $whitelabel-color-primary-key);
}

.giacom-header ul.giacom-menu li.active a,
.giacom-header ul.giacom-menu li:focus a,
.giacom-header ul.giacom-menu li:hover a {
  border-bottom: 4px solid map-get($cm-white-label-default-colors, $whitelabel-color-primary-darker-key);
  border-bottom: 4px solid var(--color-1-2);
}

.dark-menu li.selected span,
.dark-menu li:hover span {
  border-bottom: 2px solid map-get($cm-white-label-default-colors, $whitelabel-color-primary-darker-key) !important;
  border-bottom: 2px solid var(--color-1-2) !important;
}

.giacom-btn-secondary:hover,
.button--secondary:hover {
  @include setWhitelabelColorWithFallback('background-color', $whitelabel-color-primary-darker-key);
}

.background-hover-color1-2:hover,
.giacom-sub-navigation .nav-title .giacom-dropdown-grid .giacom-btn {
  @include setWhitelabelColorWithFallback('background-color', $whitelabel-color-primary-darker-key);
}

.giacom-manage-access__body__item__icon {
  @include setWhitelabelColorWithFallback('color', $whitelabel-color-primary-darker-key);
}

.giacom-btn-secondary,
.button--secondary,
.giacom-panel .information span.fas,
{
  @include setWhitelabelColorWithFallback('background-color', $whitelabel-color-primary-key);
}

// COMMENTED FOR NOW, REFACTOR WHILE REFACTORING WHITELABELLING
//.ps__thumb-y {
//  @include setWhitelabelColorWithFallback('background-color', $whitelabel-color-primary-key);
//}
//.ps__thumb-x {
//  @include setWhitelabelColorWithFallback('background-color', $whitelabel-color-primary-key);
//}

.giacom-your-account .table thead tr,
.background-color1-3 {
  @include setWhitelabelColorWithFallback('background-color', $whitelabel-color-primary-near-white-key);
}

.ps__rail-y {
  z-index: 2;
}
.ps__rail-x {
  z-index: 2;
}

.domain .table thead,
.giacom-billing .iTable .iHeader,
.giacom-billing .monthly-invoice thead,
.giacom-support__panel,
.giacom-user-manage-access__panel__body,
.giacom-user-manage-access__panel__heading,
.giacom-your-account .table thead tr {
  @include setWhitelabelColorWithFallback('background-color', $whitelabel-color-primary-near-white-key);
}


.giacom-dropdown-header ul.dropdown-menu li,
.giacom-dropdown-header ul.dropdown-menu li:hover,
.giacom-grid .table {
  @include setWhitelabelColorWithFallback('color', $whitelabel-color-primary-key);
}

.background-color2-1,
.giacom-btn-primary,
.button--primary,
.giacom-search-bar .input-group-addon,
.giacom-service-header,
.giacom-service-header-wrapper {
  @include setWhitelabelColorWithFallback('background-color', $whitelabel-color-secondary-key);
}

.text-decoration-color2-1 {
  @include setWhitelabelColorWithFallback('text-decoration-color', $whitelabel-color-secondary-key);
}

.dashboard-alert,
.giacom-grid .table .icons .fas,
.giacom-service-header .steps ul .step.active {
  @include setWhitelabelColorWithFallback('color', $whitelabel-color-secondary-key);
}

.giacom-btn-primary:hover,
.button--primary:hover,
.giacom-search-bar .input-group-addon:hover {
  @include setWhitelabelColorWithFallback('background-color', $whitelabel-color-secondary-darker-key);
}

.button[disabled] {
  opacity: 0.5;
}

body .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button,
body .p-radiobutton .p-radiobutton-box.p-state-active, {
  @include setWhitelabelColorWithFallback('background-color', $whitelabel-color-primary-key);
  @include setWhitelabelColorWithFallback(' border-color', $whitelabel-color-primary-key);
}


.fulfilment-section__header--error {
  @include setWhitelabelColorWithFallback('background-color', $whitelabel-color-secondary-key);
}

.fulfilment-section__header--error {
  @include setWhitelabelColorWithFallback('background-color', $whitelabel-color-secondary-key);
}

.fulfilment-section__body--error {
  @include setWhitelabelColorWithFallback('border-color', $whitelabel-color-secondary-key);
}

/* forced styles */
.color1-2-important {
  @include setWhitelabelColorWithFallback('color', $whitelabel-color-primary-darker-key);
}


/* prime ng - checkbox*/

body .p-chkbox .p-chkbox-box.p-state-active {
  @include setWhitelabelColorWithFallback('background-color', $whitelabel-color-primary-key);
  @include setWhitelabelColorWithFallback('border-color', $whitelabel-color-primary-key);
}
