ul.service-text-field {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-bottom: 30px;

    li {
        &.title {
            order: 1;
            text-align: right;
            width: 235px;
            font-size: inherit;
            @include Gotham-600;
            color: $giacom-grey2;
            font-size: inherit;
            margin-right: 15px;
        }

        &.input {
            order: 2;
            display: flex;
            justify-content: center;
            align-items: center;

            input {
                color: $giacom-grey2;
                border-radius: 0;
                height: 40px;
                width: 389px;
                @include Gotham-300;
                font-size: 16px;

                &::placeholder {
                    color: $giacom-grey5;
                }

                &:focus {
                    outline: none;
                    border: 1px solid $giacom-grey5;
                    box-shadow: none;
                }

                &.errorInput {
                    border: 1px solid $giacom-pink1;
                }
            }
        }

        &.clue {
            order: 3;
            align-self: center;
        }

        &.addition {
            display: inline-flex;
            justify-content: center;
            align-content: center;

            input {
                width: 248px;
                order: 1;
                text-align: right;
            }

            span.domain {
                align-self: center;
                order: 2;
                font-size: 16px;
                @include Gotham-300;
                color: $giacom-grey2;
                padding-left: 15px;
                min-width: 141px;
            }

            span.hint {
                order: 3;
            }
        }
    }

    &.availability {
        align-items: flex-start;

        .input.addition {
            display: flex;
            flex-direction: column;

            div {
                &.input {
                    display: inline-flex;
                    justify-content: center;
                    align-content: center;
                    align-items: center;

                    input {
                        order: 1;
                    }

                    span.domain {
                        order: 2;
                    }

                    span.hint {
                        order: 3;
                    }
                }

                &.action {
                    margin-top: 20px;
                    width: 100%;
                }
            }
        }

        .clue {
            align-self: flex-start;
        }

        .title,
        .clue {
            margin-top: 10px;
        }

        .action {
            margin-top: 0;

            .success {
                color: $giacom-blue1;
            }

            .error {
                color: $giacom-pink1;
            }

            .fas {
                font-family: FontAwesome;
                font-size: 16px;
                padding-left: 0;

                &.fa-check {
                    color: $giacom-blue1;
                }

                &.fa-exclamation-circle {
                    color: $giacom-pink1;
                }
            }

            .display-effect {
                display: inline-flex;
                justify-content: center;
                align-content: center;

                .uil-spin-css {
                    order: 1;
                    left: -10px;
                    top: -18px;
                }

                button {
                    order: 2;
                    border: none;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 798px) {
    ul.service-text-field {
        li {
            &.title {
                width: initial;
                width: 200px;
                max-width: 237px;
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    ul.service-text-field {
        li {
            &.input {
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    ul.service-text-field {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        &.availability {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .title {
                margin-top: 0;
                width: 100%;
                text-align: left;
                padding-bottom: 10px;
            }

            .input.addition {
                margin-right: auto;
                width: 100%;

                div.input {
                    width: 100%;
                    margin-right: auto;

                    input {
                        width: 75%;
                    }

                    span {
                        &.domain {
                            order: 2;
                        }

                        &.hint {
                            order: 3;
                            margin-top: 7px;
                        }
                    }
                }

                .action {
                    width: 100%;

                    button {
                        width: 100%;
                    }
                }
            }
        }

        li {
            &.title {
                margin-right: 0;
                width: 100%;
                text-align: left;
                padding-bottom: 10px;
            }

            &.input {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: auto;

                &.addition {
                    input {
                        width: 75%;
                    }

                    .domain {
                        min-width: 141px;
                    }
                }

                input {
                    width: 100%;
                }
            }
        }
    }
}
