.theme-giacom .bs-datepicker-head {
    background-color: var(--primary);
    color: #fff;
}

.theme-giacom {
    .selected {
        background-color: var(--primary);
    }
}

.bs-datepicker-current-day {
    border: 1px solid var(--primary);
}
