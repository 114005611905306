.edit-sharepoint {
    max-width: $serivice-form-width;

    .form-content {
        margin-right: 0;
        width: 665px;
    }

    .form-body {
        width: inherit;
        margin-top: 20px;
    }

    .panel-heading {
        width: inherit;
        height: 60px;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-bottom: 1px solid $giacom-grey3;
    }

    .title {
        @include Gotham-600;
        color: $giacom-grey2;
        order: 1;
        margin-right: auto;
    }

    .action {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        margin-left: auto;
    }

    .option {
        margin-bottom: 15px;

        .text-overview {
            text-align: right;
        }
    }

    &.setting {
        .add-ons-message {
            text-align: left;
        }
    }
}
