.azure-usage {
    padding-top: 15px;

    .spend {
        display: inline-block;
        padding-top: 8px;
    }
    .no-padding-left {
        padding-left: 0;
    }
    .giacom-dropdown {
        max-width: 100%;
        .dropdown-toggle, .dropdown-menu {
            max-width: 100%;
        }
    }


    .g-row {
        margin-bottom: 10px;
    }

    .gLabel {
        display: inline-block;
        width: 38%;
        text-align: right;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .gValue {
        width: 58%;
        display: inline-block;
        margin-left: 10px;
        max-width: 300px;
        margin-right: 10px;
        text-align: right;
    }

    .btn-group {
        width: 100%;
        .submit {
            margin-right: 10px;
        }
    }

    .selected-name, i.fa.fa-caret-down {
        vertical-align: middle;
    }


    @media screen and (max-width: 767px) {
        .gLabel, input {
            width: 100%;
            text-align: left;
        }
        .gValue {
            margin-left: 0;
        }

        .btn-group {
            button {
                width: 100%;
            }
            .submit {
                margin-bottom: 10px;
            }
        }

        .show-chart {
            width: 100%;
        }

        .no-padding-left {
            padding-left: 15px;
        }

    }
}
