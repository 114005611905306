
// Screen size
$giacom-xl: 1199px;
$giacom-large: 1023px;
$giacom-medium-tablet: 991px;
$giacom-medium: 768px;
$giacom-small: 767px;
$giacom-extra-small: 600px;

$serivice-form-width: 660px;
