.firefox {
    .giacom-dropdown-header {
        button {
            span {
                &.fas {
                    margin-top: -20px;
                }
            }

            &.right {
                span {
                    margin-right: -13px;
                }
            }
        }

        &.user-name {
            button {

                span {
                    margin-right: 0;

                    &.fas {
                        margin-top: 4px;
                    }
                }
            }
        }
    }

    .office-365 {
        &.step-one {
            ul.service-tenant {
                li {
                    &.term-and-condition {
                        .content {
                            @include Gotham-400;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

.ie {
    .giacom-form {
        .form-group {
            margin-bottom: 30px;
        }
    }

    .office-365 {
        &.step-one {
            ul.service-tenant {
                li {
                    &.term-and-condition {
                        .content {
                            @include Gotham-400;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    .hosted-exchange {
        .select-product {
            .add-ons {
                .add-ons-name {
                    .name {
                        width: 100%;
                    }
                }
            }
        }
    }

    .giacom-customer-service-item {
        .giacom-grid {
            .table {
                tr td.service-item {
                    display: inline-block;

                    &.product-has-addon {
                        height: auto;
                    }
                }

                div {
                    &.no-addOn,
                    &.product-name {
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    .giacom-credit {
        &__headline {
            &.form-content {
                max-width: 1022px;
            }
        }
    }

    .bitdefender__input__title {
        display: block;
        align-items: initial;
        text-align: right;
        margin-top: 10px;
    }

    .giacom-popup {
        &.modal.in {
            .general-popup {
                .spam-setting {
                    .popover {
                        margin-top: 30px;
                    }

                    .popover.left > .arrow {
                        top: 47%;
                    }

                    .hint {
                        .fas {
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
}

.ie,
.firefox {
    .giacom-grid {
        .table {
            div.item-name {
                word-break: break-all;
                width: 44em;
                @media screen and (max-width: 1023px) {
                    width: initial;
                }
            }
        }
    }

    .giacom-user-manage-access {
        &__panel {
            .alias-item {
                max-width: 260px;
                word-wrap: break-word;
            }
        }
    }

    .giacom-popup {
        .edit-alias {
            .table-alias {
                .alias-item {
                    max-width: 260px;
                    word-wrap: break-word;
                }
            }
        }
    }
}

.chrome {
    .office-365 {
        &.step-one {
            ul.service-tenant {
                li {
                    &.term-and-condition {
                        .content {
                            @include Gotham-400;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}
