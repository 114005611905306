.giacom-btn {
    &.btn {
        height: 40px;
        border-radius: 0;
        border: none;
        font-size: 16px;
        @include Gotham-400;
        color: $giacom-white;

        &:hover {
            color: $giacom-white;
        }

        &:focus {
            color: $giacom-white;
            border-color: $giacom-pink1;
        }

        &:active:focus,
        &:focus {
            outline: none;
        }
    }
}

.giacom-btn-confirm {
    margin-right: 10px !important;
}

.giacom-btn-primary {
    background-color: $giacom-pink1;

    &:hover {
        background-color: $giacom-pink2;
    }

    .chevron {
        color: #fff !important;
    }
}

.giacom-btn-hidden {
    visibility: hidden;
}

.giacom-btn-grey {
    background-color: $giacom-grey4;

    &.giacom-btn.btn {
        color: $giacom-grey2;
    }

    &:hover {
        background-color: $giacom-grey4;
    }
}

.giacom-btn-primary-alt.btn {
    background-color: $giacom-white;
    color: $giacom-pink1;
    border: 1px solid $giacom-pink1;

    &:hover {
        border-width: 1px;
        border-style: solid;
    }

    &[disabled]:hover {
        background-color: $giacom-pink1;
    }
}

.giacom-btn-secondary-alt.btn {
    background-color: transparent;
    color: $giacom-blue1;
    border-width: 1px;
    border-style: solid;

    &:hover {
        color: $giacom-blue1;
        background-color: $giacom-grey4;
    }

    &:active,
    &:focus {
        border-color: $giacom-blue1;
        color: $giacom-blue1;
    }

    &[disabled]:hover {
        background-color: $giacom-white;
    }
}

.giacom-btn-clear.btn {
    border: none;
    color: $giacom-blue1;

    &:hover {
        color: $giacom-blue1;
        border: none;
        text-decoration: underline;
    }
}

.giacom-btn-green-background {
    &.giacom-btn-secondary.btn {
        background-color: $giacom-blue3;
        color: $giacom-blue1;
        border: 1px solid $giacom-blue1;

        &:hover {
            @extend .giacom-btn-secondary;
            background-color: $giacom-grey4;
        }
    }
}

.button-next {
    margin-left: 10px;
    margin-right: 5px;
}

.dropdown,
.btn-group {
    span.fas {
        &:before {
            content: "\f0D7";
        }
    }

    &.open {
        span.fas {
            &:before {
                content: "\f00d";
            }
        }
    }
}

.dropdown-full-width {
    span.fas {
        &:before {
            content: "\f00d";
        }
    }
}

.btn-to-dashboard.btn {
    margin-right: 5pt;
}

.close {
    color: $giacom-grey1 !important;
    opacity: 0.6 !important;
    filter: alpha(opacity=60);

    span {
        color: $giacom-grey1 !important;
    }
}

.close:hover, .close:focus {
    opacity: 0.8 !important;
    filter: alpha(opacity=80);
}

.contextual-help-button {
    height: 40px;
    background: transparent;
    display: flex;
    align-items: center;
    border: none;

    &:hover {
        .fas {
            color: $giacom-blue1;
        }
    }

    .fas {
        font-size: 17px;
        color: $giacom-grey6;
    }
}
