@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideOutFromLeft {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}

.giacom-knowledge-base {
    animation: 1s ease-in-out 0s 1 slideInFromRight;
    position: fixed;
    top: 0;
    right: 0;
    float: right;
    width: 67%;
    height: 100%;
    z-index: 1000;
    background-color: $giacom-white;
    left: 33%;
    iframe {
        padding-left: 25px;
        border: none;
        height: inherit;
        width: inherit;
    }
    button {
        position: absolute;
        right: 25px;
        top: 0;
        color: $giacom-grey1;
        font-size: 25px;
    }
}

.hide-giacom-knowledge-base {
    position: fixed;
    z-index: 1001;
    .giacom-knowledge-base {
        animation: 1s ease-out 0s 1 slideOutFromLeft forwards;
    }
}

.giacom-mask {
    background-color: $giacom-black;
    position: fixed;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    z-index: 999;
}

@media screen and (max-width: 767px) {
    .giacom-knowledge-base {
        iframe {
            padding-left: 15px;
        }
        width: 98%;
        left: 2%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .giacom-knowledge-base {
        margin-left: 7px;
    }
}

@media screen and (min-width: 1024px) {
    .giacom-knowledge-base {
        margin-left: 7px;
    }
}
