.giacom-support {
    .giacom-support-tickets {
        .giacom-support__panel {
            &__header {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-bottom: 30px;
                .title {
                    font-weight: 300;
                    order: 1;
                    flex-grow: 2;
                }
                .action {
                    order: 2;
                    flex-basis: 165px;
                }
            }
            &__body__view-archive-tickets {
                margin-top: 20px;
                a {
                    float: right;
                }
            }
        }
        &__list {
            list-style: none;
            padding: 0;
            margin: 0;
            padding-right: 15px;
            &.customized-list {
                height: 415px;
                overflow-x: hidden;
                overflow-y: auto;
            }
            &__item {
                height: 80px;
                &:first-child {
                    border-top: 1px solid $giacom-grey3;
                }
                border-bottom: 1px solid $giacom-grey3;
                .row {
                    padding-top: 15px;
                    padding-bottom: 15px;
                }
                &__title {
                    padding-bottom: 10px;
                }
                &__time {
                    color: $giacom-grey5;
                    font-size: 14px;
                }
                &__notify {
                    font-size: 14px;
                    span {
                        float: right;
                        color: $giacom-pink1;
                    }
                }
            }
        }
    }
}
